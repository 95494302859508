import React, {useEffect, useState} from "react";
import {
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import {useStores} from "../../../../stores";
import {
    CurrencyComponent,
    findAbbreviationOfGroup,
    MILLION_ABBREVIATION,
    toCurrency
} from "../../../../components/currency-component/CurrencyComponent";
import {PartData} from "../../../../services/classes/MaterializedClasses";
import {PageResponseManager} from "../../../../stores/managers/PageResponseManager";
import {TablePaginationActions} from "../../../../components/table-pagination/TablePaginationActions";
import {observer} from "mobx-react-lite";

const PAGE_SIZE = 100;
type PageRespM = PageResponseManager<{ bag: number, supplier: number }, PartData>;

export type SupplierSpecData = {
    supplierId: number
    label: string
    totalSpend: number
}
type Props = {
    data?: SupplierSpecData
}
export const SupplierBreakdown: React.FC<Props> = observer(({data}) => {
    const {bagStore, materializedApi, p} = useStores();
    const supplierName = data?.label;
    const supplier = data?.supplierId;
    const bag = bagStore.bagId;

    const [paginator, setPaginator] = useState<PageRespM | undefined>(undefined);
    useEffect(() => {
        setPaginator(new PageResponseManager<{ bag: number, supplier: number }, PartData>(
            PAGE_SIZE,
            (page, {bag, supplier}) => materializedApi.listPartData(bag, supplier, page, PAGE_SIZE)
        ))
    }, [materializedApi])

    useEffect(() => {
        if (!paginator) return;
        if (!supplier) {
            paginator.reset();
        } else {
            paginator.init({bag, supplier})
        }
    }, [paginator, bag, supplier])

    const isLoading = !paginator || paginator.isLoading;
    const tableClass = 'mithra-table x-design parts-table' + (isLoading ? ' loading' : '');
    const colSpan = 4;
    const abbreviation = p.p.hardcodedKPIAbbreviation
        ? p.p.hardcodedKPIAbbreviation
        : paginator?.data ? findAbbreviationOfGroup(paginator.data.map(d => d.p_spend)) : MILLION_ABBREVIATION;
    return <section className="supplier-breakdown-visualization">
        <Grid container alignItems="baseline" justifyContent="flex-start">
            <Grid item>
                <Typography className="supplier-header" variant="h4">
                    {supplierName}
                </Typography>
            </Grid>
            <Grid item style={{marginLeft: '1em'}}>
                <Typography variant="subtitle1">
                    {data && `Total ${toCurrency(p.currencySymbol, data.totalSpend)} spend`}
                </Typography>
            </Grid>
        </Grid>

        <TableContainer className={tableClass}>
            <Table cellPadding={0}>
                <TableHead>
                    <TableRow>
                        <TableCell className="col-p_name">
                            {p.p.p_name_col_name}
                        </TableCell>
                        <TableCell className="col-p_context_1">
                            {p.p.p_context_1_col_name}
                        </TableCell>
                        <TableCell className="col-p_context_2">
                            {p.p.p_context_2_col_name}
                        </TableCell>
                        <TableCell className="col-p_spend" align="right">
                            Amount
                        </TableCell>
                    </TableRow>
                    <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
                        <td colSpan={colSpan}>
                            {isLoading && <LinearProgress variant="indeterminate"/>}
                        </td>
                    </tr>
                </TableHead>
                <TableBody>
                    {paginator?.data ? paginator.data.map(d =>
                        <TableRow key={d.p_id}>
                            <TableCell className="col-p_name">
                                {d.p_name}
                            </TableCell>
                            <TableCell className="col-p_context_1">
                                {d.p_context_1}
                            </TableCell>
                            <TableCell className="col-p_context_2">
                                {d.p_context_2}
                            </TableCell>
                            <TableCell className="col-p_spend" align="right">
                                <CurrencyComponent v={d.p_spend} abbreviation={abbreviation}/>
                            </TableCell>
                        </TableRow>
                    ) : <TableRow className="no-data-yet"/>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            colSpan={colSpan}
                            count={paginator?.count || 0}
                            rowsPerPage={paginator?.pageSize || 0}
                            page={(paginator?.page || 1) - 1}
                            onPageChange={(e, page) => {
                                paginator?.changePage(page + 1)
                            }}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    </section>
})
