import React from "react";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";

export const DASHBOARD_CONSTANTS = {
    SUBTITLE_LOADING: '',
    VALUE_LOADING: '-',
    OPPORTUNITY_THRESHOLD: 1000,
    OPPORTUNITY_THRESHOLD_PARETO: 80,
}

export function drawOpportunityValue(opp: number | undefined) {
    if (opp === undefined) {
        return DASHBOARD_CONSTANTS.VALUE_LOADING
    }
    if (opp === 0) {
        return <CurrencyComponent v={0} nDigits={3}/>
    }
    return <CurrencyComponent v={opp} nDigits={3}/>
}
