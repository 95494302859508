import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {makeAutoObservable} from "mobx";
import {BusinessUnit} from "../../services/classes/MaterializedClasses";
import {AxoisRequestManager} from "./RequestManager";
import {from} from "rxjs";
import {Bag} from "../../services/classes/Bag";

type NoBusinessUnit = Pick<BusinessUnit, 'bu_name' | 'id'>;

export class BusinessUnitManager {

    static readonly ALL_BU: NoBusinessUnit = {id: -2, bu_name: 'Show everything'};
    static readonly NO_BU: NoBusinessUnit = {id: -1, bu_name: 'No entity'};

    readonly _buRequestManager = new AxoisRequestManager<number, BusinessUnit[]>(bagId => from(this.api.listBusinessUnitData(bagId)))

    selectedBusinessUnitOption: BusinessUnit | NoBusinessUnit = BusinessUnitManager.ALL_BU;

    constructor(private api: MithraMaterializedApi) {
        makeAutoObservable(this);
    }

    init(bag: Bag) {
        this.selectedBusinessUnitOption = BusinessUnitManager.ALL_BU;
        this._buRequestManager.request(bag.id);
    }

    get selectedBusinessUnitId(): number | null | undefined {
        switch (this.selectedBusinessUnitOption.id) {
            case -1:
                return null;
            case -2:
                return undefined;
            default:
                return this.selectedBusinessUnitOption.id;
        }
    }

    setNoneSelected() {
        this.selectedBusinessUnitOption = BusinessUnitManager.ALL_BU;
    }

    setSelectedBusinessUnitId(bu: number) {
        this.selectedBusinessUnitOption = this.businessUnitsMap?.get(bu) || BusinessUnitManager.ALL_BU;
    }

    get businessUnitOptions(): Array<BusinessUnit | NoBusinessUnit> | undefined {
        const bus = this.realBusinessUnitOptions;
        if(!bus) return undefined;
        if(bus.length === 0) return undefined; //When there are no business units, do not show the filter options
        return [
            BusinessUnitManager.ALL_BU,
            ...bus,
            BusinessUnitManager.NO_BU,
        ]
    }

    get realBusinessUnitOptions(): Array<BusinessUnit | NoBusinessUnit> | undefined {
        const bus = this._buRequestManager.result;
        if (!bus) return undefined;
        if (bus.length === 0) return undefined; //When there are no business units, do not show the filter options

        // TODO: LG There is missing data so show the bu_id when the name is missing
        bus.forEach(bu => {
            if (bu.bu_name === '') {
                bu.bu_name = bu.bu_id.split('.')[0];
            }
        })

        return [
            ...bus,
        ]
    }

    get businessUnitsMap(): Map<number, BusinessUnit> | undefined {
        const bus = this._buRequestManager.result;
        if(!bus) return undefined;
        const result = new Map()
        result.set(BusinessUnitManager.NO_BU.id, BusinessUnitManager.NO_BU);
        result.set(BusinessUnitManager.ALL_BU.id, BusinessUnitManager.ALL_BU);
        bus.forEach(b => result.set(b.id, b));
        return result;
    }
}
