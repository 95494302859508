import React from "react";
import {SingleHeader} from "../../pages/default-menus/SingleHeader";
import {MultiHeader} from "../../pages/multi-dashboard/menu/MultiHeader";
import {MainComponentProp} from "../../components/main/MainComponent";

export const HeaderWrapper: React.FC<MainComponentProp> = ({state}) => {
    // console.debug('Re-rendering HeaderWrapper', state?.dashEnv)
    switch (state?.dashEnv) {
        case 'simple':
            return <SingleHeader/>;
        case 'dual':
            return <SingleHeader/>;
        case 'multi':
            return <MultiHeader/>;
    }
    return <></>;
}
