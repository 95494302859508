import './AiCategorizationPage.scss'
import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../stores";
import {useHistory} from "react-router";
import {Alert, Button, CircularProgress, Grid, LinearProgress} from "@mui/material";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {AiModelSelection} from "./model-selection-page/AiModelSelection";
import {AiModelBusy} from "./model-busy-page/AiModelBusy";
import {AiCategorizationStepper} from "./AiCategorizationStepper";
import {CategorizationReview} from "./review/CategorizationReview";
import {CategorizationReviewResult} from "./result/CategorizationReviewResult";
import {useRouteMatch} from "react-router-dom";
import {generateHomePath} from "../../routing/routing.utils";

type Props = {}
export const AiCategorizationPage: React.FC<Props> = observer(() => {
    const {bagStore, categorizationStore, categorizationReviewStore, authStore} = useStores();
    const routeMatch = useRouteMatch();
    const history = useHistory();

    return <div className="custom-page ai-categorization-page">
        <Grid container alignItems="center" className="header">
            <Grid item xs={2} className="text-center align-with-stepper">
                {categorizationStore.page === 'model_selection' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => history.push(generateHomePath(routeMatch, {id: bagStore.bagId}))}>
                        Back
                    </Button>
                }
                {categorizationStore.page === 'model_busy' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            categorizationStore.navigateToPage('model_selection')
                        }}>
                        Back
                    </Button>
                }
                {categorizationStore.page === 'review' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            if (categorizationStore.data.selectedLevel === 0) {
                                categorizationStore.navigateToPage('model_selection')
                            } else {
                                categorizationStore.data.selectOneCategoryUp()
                            }
                        }}>
                        Back
                    </Button>
                }
                {categorizationStore.page === 'result' &&
                    <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ChevronLeft/>}
                        onClick={() => {
                            categorizationStore.navigateToPage('review')
                        }}>
                        Back
                    </Button>
                }
            </Grid>

            <Grid item xs={8}>
                <AiCategorizationStepper/>
            </Grid>

            <Grid item xs={2} className="align-with-stepper">
                {categorizationStore.page === 'review' && <>
                    <>
                        <Button
                            id="save-categorization-btn"
                            color="primary"
                            variant="outlined"
                            disabled={categorizationReviewStore.anyStoreRequestBusy}
                            endIcon={<ChevronRight/>}
                            onClick={() => {
                                categorizationStore.data.unsetSelectedCat();
                                categorizationStore.navigateToPage('result');
                            }}>
                            {authStore.viewOnly ? 'Next' : 'Save'}
                        </Button>
                        <div className="loading-element">
                            {!authStore.viewOnly && <>
                                <LinearProgress
                                    variant="determinate"
                                    value={1 / (categorizationReviewStore.numberOfBusyRequests + 1) * 100}
                                />
                            </>}
                        </div>
                    </>
                </>}

                {categorizationStore.page === 'review' && <>
                    {categorizationReviewStore.categories.taxonomy_categories === null &&
                        <Grid container style={{marginTop: '2em'}}>
                            <Grid item>
                                <Alert severity="warning">Missing taxonomy</Alert>
                            </Grid>
                        </Grid>
                    }
                    {categorizationReviewStore.categories.taxonomy_categories === undefined && !categorizationReviewStore.categories.error &&
                        <Grid container style={{marginTop: '2em'}}>
                            <Grid item>
                                <Alert severity="info">
                                    Loading review, please wait
                                    <span className="ml-2 mr-1">
                                            <CircularProgress size={12}/>
                                        </span>
                                </Alert>
                            </Grid>
                        </Grid>
                    }
                    {categorizationReviewStore.categories.error &&
                        <Grid container style={{marginTop: '2em'}}>
                            <Grid item>
                                <Alert severity="error">{categorizationReviewStore.categories.error}</Alert>
                            </Grid>
                        </Grid>}
                </>}

            </Grid>
        </Grid>
        <div className="categorization-page-content">
            {categorizationStore.page === 'model_selection' && <AiModelSelection/>}
            {categorizationStore.page === 'model_busy' && <AiModelBusy/>}
            {categorizationStore.page === 'review' &&
                <CategorizationReview/>
            }
            {(categorizationStore.page === 'result') &&
                <CategorizationReviewResult/>
            }
        </div>
    </div>
});
