import React from "react";
import {observer} from "mobx-react-lite";
import {Stack, TableRow} from "@mui/material";
import {CurrencyComponent} from "../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {getSpend, ParentSupplierMatch, SupplierReviewRowState} from "../SupplierNormalization.classes";
import {SUPPLIER_COLUMNS} from "./columns";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../../constants";
import {ParentSupplierChooser} from "../components/ParentSupplierChooser";
import {MithraTableCell} from "./TableHeadColumns";
import {useStores} from "../../../../stores";

type Props = {
    subRow: SupplierReviewRowState
};
export const ParentSupplierReviewSubRow: React.FC<Props> = observer(({subRow}) => {
    const {supplierNormalizationStore} = useStores()
    const [value, setValue] = React.useState<ParentSupplierMatch | null>(subRow.parent_match);
    const changeValue = (newValue: ParentSupplierMatch | null) => {
        supplierNormalizationStore.review.sendSubRowUpdate(subRow, newValue);
        setValue(newValue);
    }
    return <TableRow className="sub-row">
        <MithraTableCell c={SUPPLIER_COLUMNS[0]}>
            <Stack direction="row" className="supplier-parent-chooser-wrapper">
                <ParentSupplierChooser value={value} setValue={changeValue} label="Parent Supplier"/>
            </Stack>
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[1]}>
            {subRow.s_name}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[2]}>
            {/*{environment.isTest && <>p_id={part.p_id}<br/></>}*/}
            <CurrencyAbbreviationContext.Consumer>{abbreviation =>
                <CurrencyComponent v={getSpend(subRow)} abbreviation={abbreviation}/>
            }</CurrencyAbbreviationContext.Consumer>
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[3]}>
            {subRow.s_city || subRow.s_country}
        </MithraTableCell>
        <MithraTableCell c={SUPPLIER_COLUMNS[4]}>
            {subRow.s_total_l1s.map(l => l === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : l).join(', ')}
        </MithraTableCell>
        {/*<MithraTableCell c={SUPPLIER_COLUMNS[4]}>*/}
        {/*    {Math.round(subRow.s_suggestion_score)}*/}
        {/*</MithraTableCell>*/}
    </TableRow>
})
