import {D3GSelection} from "../../../utils/global";
import * as d3 from "d3";
import {ScaleBand, ScaleLinear} from "d3";
import Margin from "../../../utils/margin";

export namespace single_bar_chart {
    type DataPoint = {
        label: string,
        value: number,
        dataCls: string,
    };

    export interface Data {
        mainLabel: string
        values: DataPoint[]
    }

    export interface Options {
        width: number
        height: number
        margin: Margin
        dataMax: number
        labelHover: boolean
        hideValueLabels: boolean
        barPadding: number
        alwaysVisibleLabels: boolean
    }

    type BarSelection = D3GSelection<DataPoint>;

    /**
     * Builder taken from: SankeyTreeBuilder
     * Visualization taken from: BarChart
     */
    export class Builder {
        private catAxis: ScaleBand<string>;
        private valAxis: ScaleLinear<number, number>;
        private barsGroup: BarSelection;
        private readonly barSpacingPercentage = 0;  // 0 - 1
        private readonly labelSpacingPx = 10;

        constructor(
            private svg: D3GSelection<any>,
            private input: Data,
            private graphWidth: number,
            private graphHeight: number,
            private options: Options,
        ) {
            const verticalRange = [0, graphHeight];
            const horizontalRange = [0, graphWidth];
            this.catAxis = d3.scaleBand()
                .domain(input.values.map(v => v.label))
                .range(verticalRange)
                .paddingInner(this.options.barPadding)
            // console.log('valAxis', horizontalRange, '->', [0, options.dataMax])
            this.valAxis = d3.scaleLinear()
                .domain([0, options.dataMax])
                .range(horizontalRange)
            this.barsGroup = svg.append('g')
                .attr('class', 'bars')
                .selectAll('g')
            // .data(input.values)
            // .join('g')
            // .classed('bar-wrapper', true)

            this.createBars()
        }

        private get spacingPx() {
            return this.barSpacingPercentage / 2 * this.catAxis.bandwidth()
        }

        private createBars() {
            const data = this.input.values;
            const spacingPx = this.spacingPx;

            const update: BarSelection = this.barsGroup.data(data, d => d.label)
            const enter = update.enter()
                .append('g')
                .attr('class', d => {
                    let c = 'bar-wrapper'
                    if (d.dataCls) {
                        c += ' ' + d.dataCls
                    }
                    return c
                })

            if (this.options.labelHover) {
                enter.append('rect')
                    .classed('hover-overlay', true)
                    // .attr('x', 0)
                    .attr('y', d => this.catAxis(d.label) as number)
                    .attr('width', this.graphWidth)
                    .attr('height', this.catAxis.bandwidth())
            }
            if (!this.options.hideValueLabels) {
                const valueLabels = enter.append('text')
                    .classed('value-label', true)
                    .text(d => d.label)
                const yOffset = 1;

                valueLabels
                    .attr('dominant-baseline', 'middle')
                    .attr("x", d => this.valAxis(d.value) + this.labelSpacingPx)
                    .attr("y", d => (this.catAxis(d.label) as number) + this.catAxis.bandwidth() / 2 + yOffset)
                // .call(text => {
                //         text.filter(d => valRange[1] - valueAxis(d.value) < MAX_WORD_SIZE)
                //             .attr('fill', 'white')
                //             .attr("text-anchor", 'end')
                //             .attr("x", d => this.valAxis(d.value) - spacingPx)
                //     }
                // )
            }

            const bar = enter.append('rect')
                .classed('bar', true)
            // .attr('fill', d => getColorByCat(d.category))

            bar.attr("y", d => (this.catAxis(d.label) as number) + spacingPx)
                .attr("width", d => this.valAxis(d.value))
                .attr("height", this.catAxis.bandwidth() * (1 - this.barSpacingPercentage))

            // const nodesData = this.graph.nodes;
            // const update: NodeSelection = this.nodesGroup.data(nodesData, d => d.nodeId)
            // const enter: NodeSelection = update.enter()
            //     .append('g')
            //     .classed('node', true)
            //     .classed('clickable', d => d.height > 0)
            //     .classed('is-leaf', d => d.height === 0)
            //     .classed('is-root', d => d.depth === 0)
            // const onClickNode = this.onClickNode.bind(this);
            // enter.on('click', function () {
            //     onClickNode(this);
            // })
            // enter.append('rect')
            //     .attr('x', d => d.x0 || 0)
            //     .attr('y', d => d.y0 || 0)
            //     .attr('height', d => (d.y1 || 0) - (d.y0 || 0))
            //     .attr('width', d => (d.x1 || 0) - (d.x0 || 0))
            // enter.append('text')
            //     .attr('x', d => (d.x0 || 0) - 6)
            //     // .attr('x', d => (d.x0 || 0) + 15)
            //     // .attr('fill', 'white')
            //     .attr('y', d => ((d.y1 || 0) + (d.y0 || 0)) / 2)
            //     .attr('dy', '0.35em')
            //     .attr('text-anchor', 'end')
            //     .classed('overflow-label', (d: _ExtendedNode) =>
            //         d.value ? d.value <= this.options.minValueForLabel : true
            //     )
            //     .text((d) => {
            //         if (showDebugValues)
            //             return `${d.name} (h=${d.height} d=${d.depth})`
            //
            //         const d2 = d as _ExtendedNode
            //         if (!d) return '';
            //         if (!d2.name) return 'uncategorized';
            //         if (d2.value === undefined) return d2.name
            //
            //         return d2.name;
            //         // return (d.name as string).trim().toLowerCase() == ('<uncategorized>' as string) ? d.name : '';
            //     })
            // enter.append('title')
            //     .text(d => `${d.name}\n${CURRENCY_SYMBOL} ${(d as any).value}`)
            return [enter, update]
        }

        private updateBars() {
            const [enter, update] = this.createBars()
            update.exit().remove()
            const next = update.merge(enter)
            const spacingPx = this.spacingPx;
            next.select('rect')
                .attr("y", d => (this.catAxis(d.label) as number) + spacingPx)
                .attr("width", d => this.valAxis(d.value))
                .attr("height", this.catAxis.bandwidth() * (1 - this.barSpacingPercentage))
        }

    }

}
