import {makeAutoObservable} from "mobx";

export type DashboardEnvironment =
    'simple'
    | 'dual'
    | 'multi'
    | 'nestle'
export type DashboardType = 'single' | 'synergy';
export type DatasetType = 'default' | 'master' | 'combined' | 'no_data';

/**
 * Props that are passed to the route wrapper component
 */
export type AppState = {
    fluid: boolean
    /**
     * The versioning of the dashboard
     */
    dashEnv?: DashboardEnvironment
    /**
     * The type of dataset that is loaded
     */
    dataType?: DatasetType
    /**
     * The type of styling that is used in the page
     */
    dashType?: DashboardType
}
export const DEFAULT_APP_STATE: AppState = {
    fluid: true,
}


export class RootAppStore {
    get app(): AppState {
        return this.routeApp || this.defaultApp;
    }

    routeApp: AppState | undefined;
    defaultApp: AppState;

    constructor() {
        makeAutoObservable(this);
        this.defaultApp = DEFAULT_APP_STATE;
    }

    setApp(app: AppState) {
        this.routeApp = app;
    }
}
