import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {makeAutoObservable, reaction} from "mobx";
import {PageResponseManager} from "../managers/PageResponseManager";
import {environment} from "../../env";
import {
    matGetFilterType,
    MatPartReviewRowState,
    MatSearch,
    MatSupplierFilter,
    MatSupplierFilterType,
    SomeMatSupplierReviewRow
} from "../../services/classes/MatReviewClasses";
import {CategorizationSupplierPageManager, SummaryKeyValues} from "../CategorizationStore";
import {EMPTY, from, Subscription} from "rxjs";
import {catsToDict} from "../../services/ApiHelpers";
import {CategorizationReviewFilterDelegate} from "./CategorizationReviewFilterDelegate";
import {
    MatPartReviewRow,
    MatReviewLevelStatisticsTreeSerializer,
    MatReviewStatisticsSerializer
} from "../../services/classes/MaterializedClasses";
import {AxoisRequestManager} from "../managers/RequestManager";
import {UNCATEGORIZED_VALUE} from "../../constants";
import {calcCombinedState, selectMatReviewLevelStatistics} from "../../services/classes/MatReviewHelpers";
import {single_bar_chart} from "../../components/visualization/single-barchart/SingleBarChartBuilder";
import {
    AiChangeChart,
    convertSingleToChart,
    convertToChart,
    convertToChartOnlyNew,
    findUnclassified
} from "../../services/classes/CategorizationHelpers";
import {CurrencyAbbreviation, findAbbreviationOfGroup} from "../../components/currency-component/CurrencyComponent";
import AuthStore from "../AuthStore";
import ProfileStore from "../ProfileStore";

export class CategorizationReviewDataDelegate {
    /**
     * TODO: Currently only search resides here, move all search related stuff here
     */
    readonly filter = new CategorizationReviewFilterDelegate()

    filteredApproval?: number;
    requestedBagId: undefined | number;
    requestedBusinessUnitId: number | null | undefined;

    // TODO[integration]: Move max level to profile store
    maxTaxonomySize = 3; // Do not allow to go deeper than L3 initially, update when bag is retrieved

    selectedCategory: string[] = [];

    shouldHaveReviewLevelStatistics = false;

    /**
     * Keep track of the suppliers table by a single request group
     */
    readonly supplierPages: CategorizationSupplierPageManager = new PageResponseManager(
        environment.categorizationReviewPageSize,
        (page, f) =>
            this.matApi.listSupplierReview(f, page, environment.categorizationReviewPageSize),
        // Set part related attributes when they are done downloading
        (data: SomeMatSupplierReviewRow, request) => {
            const filterType = matGetFilterType(request);
            return {...data as any, filter: filterType};
        }
    )
    public _isLoadingParts = false
    private partSub?: Subscription

    readonly _reviewStatistics = new AxoisRequestManager<{ bagId: number }, MatReviewStatisticsSerializer[]>(
        ({bagId}) => from(this.matApi.listReviewStatistics(bagId)),
    );
    readonly _reviewLevelStatistics = new AxoisRequestManager<{ bagId: number, businessUnitId: undefined | null | number }, [MatReviewLevelStatisticsTreeSerializer]>(
        ({bagId, businessUnitId}) => from(this.matApi.listReviewLevelStatistics(bagId, businessUnitId))
    );

    constructor(
        private matApi: MithraMaterializedApi,
        private auth: AuthStore,
        private profile: ProfileStore,
    ) {
        makeAutoObservable(this)

        // TODO: bad practice to put these reactions here!
        reaction(() => [this.supplierPages.data] as const, ([suppliers]) => {
            // console.log('Supplier page data changed')
            // Every time the supplier page is updated, the parts should be fetched again
            this._isLoadingParts = true;
            // TODO: Add loading waiter
            // console.log('Triggering this.supplierPages.data', suppliers?.map(s => s.id))
            if (suppliers) {
                if (this.partSub) this.partSub.unsubscribe();
                const filterType = this.selectedFilterType;
                if (filterType === null) return;
                const supplierIds = suppliers.map(s => s.id)
                console.time('listPartsInReview')
                console.log('listPartsInReview', {supplierIds: supplierIds.length})
                if (supplierIds.length === 0) {
                    this.partSub = undefined;
                    console.timeEnd('listPartsInReview')
                } else {
                    this.partSub = from(this.matApi.listPartsInReview(filterType, this.filteredApproval, supplierIds)).subscribe({
                        next: resp => this.setPartData(resp.data, filterType),
                        complete: () => console.timeEnd('listPartsInReview')
                    })
                }
            }
        })

        reaction(() => [this.requestedBagId, this.requestedBusinessUnitId] as const, ([bagId, businessUnitId]) => {
            console.log('CategorizationStore this.requestedBagId=', this.requestedBagId);
            if (bagId) {
                this._reviewLevelStatistics.request(({bagId, businessUnitId}));
                this.requestSupplierPages(); // TODO-integration: this will always retrieve all suppliers; move to appropriate stores
            }
        })
    }


    reInitialize(bagId: number, taxonomySize: number) {
        console.log('CategorizationStore.CategorizationReviewDataDelegate.reInitialize', {bagId, taxonomySize});
        // TODO: This needs a more stable interface
        this.filteredApproval = undefined;
        this.maxTaxonomySize = taxonomySize;
        this._reviewStatistics.cleanup();
        this._reviewLevelStatistics.cleanup();
        this.supplierPages.reset()
        this.shouldHaveReviewLevelStatistics = true;

        this._reviewStatistics.request({bagId})
        // Should _reviewLevelStatistics not be called here?
        this.selectedCategory = []
    }

    reInitializeV2(bagId: number, taxonomySize: number) {
        console.log('CategorizationStore.CategorizationReviewDataDelegate.reInitializeV2', {bagId, taxonomySize});
        // TODO: This needs a more stable interface
        this.filteredApproval = undefined;
        this.maxTaxonomySize = taxonomySize;
        this._reviewStatistics.cleanup();
        this._reviewLevelStatistics.cleanup();
        this.supplierPages.reset()
        this.shouldHaveReviewLevelStatistics = true;
        this.selectedCategory = []

        // TODO: This is very hacky...
        this.setRequestBagId(bagId);
        const businessUnitId = this.requestedBusinessUnitId;
        this._reviewStatistics.request({bagId})
        this._reviewLevelStatistics.request(({bagId, businessUnitId}))

        return EMPTY;
    }

    reInitializeV3(approval: number, bagId: number, taxonomySize: number) {
        console.log('CategorizationStore reInitializeV3', {approval, bagId, taxonomySize});
        // TODO: This needs a more stable interface
        this.filteredApproval = approval;
        this.maxTaxonomySize = taxonomySize;
        this._reviewStatistics.cleanup()
        this._reviewLevelStatistics.cleanup();
        this.shouldHaveReviewLevelStatistics = false;
        this.supplierPages.reset()
        this.selectedCategory = []

        this.setRequestBagId(bagId);

        // TODO: It is impossible to calculate the review level statistics from an approval view at the moment
    }

    get reviewStatistics(): MatReviewStatisticsSerializer | undefined | null {
        if (!this._reviewStatistics.result) return undefined;
        if (this._reviewStatistics.result.length !== 1) return null;
        return this._reviewStatistics.result.at(0);
    }

    get isLoading(): boolean {
        return this.shouldHaveReviewLevelStatistics && (
            this._reviewStatistics.busy || this._reviewLevelStatistics.busy
        );
    }

    get hasNoCategorizationResult() {
        return this.reviewStatistics === null;
    }

    get isLoadingParts(): boolean {
        console.log('isLoadingParts', this.supplierPages.isLoading, this._isLoadingParts);
        return this.supplierPages.isLoading || this._isLoadingParts;
    }

    get selectedFilterType() {
        return matGetFilterType(this.selectedFilter)
    }

    requestSupplierPages() {
        this.supplierPages.init(this.selectedFilter);
    }

    get reviewLevelStatistics(): MatReviewLevelStatisticsTreeSerializer | undefined {
        return this._reviewLevelStatistics.result?.at(0);
    }

    setRequestBagId(bagId: number | undefined) {
        this.requestedBagId = bagId
    }

    setRequestBusinessUnitId(bagId: number | undefined, businessUnitId: number | null | undefined) {
        this.requestedBagId = bagId;
        this.requestedBusinessUnitId = businessUnitId

        if (bagId) {
            this._reviewLevelStatistics.request(({bagId, businessUnitId}));
            this.requestSupplierPages(); // TODO[integration]: test this
        }
    }

    setSelectedCategory(selectedCategory: string[]) {
        console.log('setSelectedCategory', {old: `${this.selectedCategory}`, new: `${selectedCategory}`});
        this.selectedCategory = selectedCategory;
        this.requestSupplierPages();
    }

    unsetSelectedCat() {
        this.selectedCategory = [];
        this.supplierPages.reset();
    }

    selectOneCategoryUp() {
        this.selectedCategory = this.selectedCategory.slice(0, -1);
    }

    canClickParentChart(index: number) {
        return index !== this.selectedLevel - 1;
    }

    clickParentChart(index: number) {
        if (index === this.selectedLevel - 1) {
            // The lowest element is clicked, which is already active
            console.warn('Categorization clicked lowest element for navigation which is ignored',
                `selectedCategory=${this.selectedCategory}`)
            return;
        }
        if (this.selectedCategory.length < 1) {
            console.warn('Unexpected click of navigation')
            return;
        }
        this.setSelectedCategory(this.selectedCategory.slice(0, index + 1))
    }

    get selectedLevel(): number {
        return this.selectedCategory.length;
    }

    get hasRemainingSelectionLevels(): boolean {
        if (this.selectedLevel >= this.maxTaxonomySize) {
            return false
        }
        // noinspection RedundantIfStatementJS
        if (this.selectedLevel >= 1 && !this.selectedCategoryLabel) {
            // Do not allow to go deeper in uncategorized
            return false;
        }
        return true;
    }

    get canSelectLevelDeeper(): boolean {
        if (!this.hasRemainingSelectionLevels) {
            return false;
        }
        // console.log('canSelectLevelDeeper', {
        //     selectedLevel: this.selectedLevel,
        //     selectedCategoryLabel: this.selectedCategoryLabel,
        //     currentSelectionStats_length: this.currentSelectionStats?.length,
        // })
        if (this.selectedLevel >= 1) {
            if (this.currentSelectionStats?.length === 1 && this.currentSelectionStats[0].label === UNCATEGORIZED_VALUE) {
                return false;
            }
        }
        return true;
    }

    get selectedFilter(): MatSupplierFilter {
        const lCats = catsToDict(this.selectedCategory, this.selectedLevel);

        let search: MatSearch | undefined = undefined;
        if (this.filter.activeSearchString) {
            search = {
                supplier: this.filter.activeSearchString,
            }
        }
        if (!this.requestedBagId) {
            console.warn('CategorizationReviewDataDelegate: requestedBagId is not properly refactored', this.requestedBagId)
        }

        return {
            databag: this.requestedBagId || -1,
            business_unit: this.requestedBusinessUnitId,
            search,
            level: this.selectedLevel,
            ...lCats,
            approval: this.filteredApproval,
        }
    }

    get selectedCategoryLabel(): string | undefined {
        if (this.selectedCategory.length > 0) {
            return this.selectedCategory[this.selectedCategory.length - 1]
        }
        return undefined;
    }

    get selectedL1Category(): string | undefined {
        if (this.selectedCategory.length > 0) {
            return this.selectedCategory[0]
        }
        return undefined;
    }

    selectNextCategoryDown(selectedCategory: string) {
        this.setSelectedCategory([...this.selectedCategory, selectedCategory])
    }

    navigateToLevel(level: number) {
        this.setSelectedCategory(this.selectedCategory.slice(0, level));
    }

    setPartData(partData: MatPartReviewRow[], filterType: MatSupplierFilterType) {
        console.time('setPartData()');
        // Collect the part data grouped by supplier
        const indices = new Map(this.supplierPages.data?.map((d, i) => ([d.id, i])));
        const partDatas = new Map<number, MatPartReviewRowState[]>();
        for (const part of partData) {
            // interpretPartData
            const review_mine = part.review_user_id === this.auth.userId;
            const feedback_mine = part.feedback_user_id === this.auth.userId;
            const partI: MatPartReviewRowState = {
                ...part,
                review_mine,
                feedback_mine,
                parent_supplier_row: undefined as any,  // Defined below
            }

            const supplierLookupField = MithraMaterializedApi.getMatPartReviewLookupField(filterType)
            const supplierRowId = partI[supplierLookupField]
            const ds = partDatas.get(supplierRowId)
            if (ds) {
                ds.push(partI)
            } else {
                partDatas.set(supplierRowId, [partI])
            }
        }

        // Apply to the view
        partDatas.forEach((parts, key) => {
            const i = indices.get(key);
            if (i === undefined) return;
            const data = this.supplierPages.data;
            if (!data) return;
            const supplierData = data[i];
            if (!supplierData) return

            parts.forEach(p => p.parent_supplier_row = supplierData) // Double link it for easy updating
            supplierData.parts = parts;
            supplierData.combined_state = calcCombinedState(parts, this.maxTaxonomySize)
        })
        console.log(`setPartData() of ${partData.length} parts`);
        console.timeEnd('setPartData()');

        this._isLoadingParts = false;
    }

    get _selectedStats() {
        if (!this.reviewLevelStatistics) {
            return undefined
        }
        return selectMatReviewLevelStatistics(this.reviewLevelStatistics, this.selectedCategory)
    }

    get parentCharts(): {
        charts: { category: string, data: single_bar_chart.Data }[],
        max: number,
    } | undefined {
        if (!this._selectedStats) return undefined;
        const parentStats = this._selectedStats.slice(1);
        if (parentStats.length === 0) return undefined;
        const charts = parentStats.map((stat, i) => {
            const aiChangeChart = convertSingleToChart(stat, this.profile.currencySymbol, true);
            const data: single_bar_chart.Data = {
                mainLabel: `L${i + 1}: ${aiChangeChart.label}`,
                values: aiChangeChart.values,
            }
            return {category: aiChangeChart.category, data};
        })
        const COL_WIDTH = 6 / 9 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...charts.map(c => Math.max(...c.data.values.map(v => v.value)))) / COL_WIDTH;
        return {charts, max}
    }

    get currentSelectionStats(): undefined | MatReviewLevelStatisticsTreeSerializer[] {
        if (!this.hasRemainingSelectionLevels) return undefined;
        if (!this._selectedStats) return undefined;
        return this._selectedStats[this._selectedStats.length - 1].children;
    }

    get selectionCharts(): {
        data: AiChangeChart[],
        max: number,
    } | undefined {
        // From vion:
        // if (!this.hasRemainingSelectionLevels) return undefined;
        // if (!this._selectedStats) return undefined;
        // let d: MatReviewLevelStatisticsTreeSerializer[] = this._selectedStats[this._selectedStats.length - 1].children;

        if (!this.canSelectLevelDeeper) return undefined;
        let d = this.currentSelectionStats;
        if (!d) return undefined;

        const data = convertToChart(d, this.profile.currencySymbol)
        const COL_WIDTH = 6 / 9 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...data.map(D => Math.max(...D.values.map(v => v.value)))) / COL_WIDTH;
        return {data, max}
    }

    get onlyNewSelectionCharts(): {
        data: AiChangeChart[],
        max: number,
    } | undefined {
        if (!this.hasRemainingSelectionLevels) return undefined;
        if (!this._selectedStats) return undefined;
        let d: MatReviewLevelStatisticsTreeSerializer[] = this._selectedStats[this._selectedStats.length - 1].children;

        const data = convertToChartOnlyNew(d, this.profile.currencySymbol)
        const COL_WIDTH = 6 / 9 // Show as width col=6, while the whole viz goes to col=9
        const max = Math.max(...data.map(D => Math.max(...D.values.map(v => v.value)))) / COL_WIDTH;
        return {data, max}
    }

    get summaryResultKeyValues(): SummaryKeyValues | undefined {
        if (this.unclassifiedStats === undefined) {
            return undefined;
        }
        if (!this.reviewStatistics) return undefined;
        const unclassified_spend = this.unclassifiedStats?.values.post_spend || 0
        const reclassified_spend = this.reviewStatistics.cat_recat_spend || 0
        const classified_spend = this.reviewStatistics.total_spend - unclassified_spend
        const after: SummaryKeyValues["after"] = {classified_spend, reclassified_spend, unclassified_spend}

        // These values should not have hardcoded abbreviations
        const abbreviation: CurrencyAbbreviation = findAbbreviationOfGroup(Object.values(after));

        return {abbreviation, after}
    }

    get unclassifiedStats(): MatReviewLevelStatisticsTreeSerializer | undefined | null {
        if (!this.reviewLevelStatistics) return undefined;
        return findUnclassified(this.reviewLevelStatistics)
    }

    reloadView() {
        this.supplierPages.reloadView()
    }

    clearSearch() {
        if (this.filter._clearSearch()) {
            this.requestSupplierPages()
        }
    }

    doSearch() {
        this.filter._doSearch()
        this.requestSupplierPages()
    }
}
