import {generatePath, match} from "react-router";
import {routes} from "./routes";

export type AppEnvironment = 'synergy_v1'
    | 'taxonomy_merge'
    | 'default'
    | 'single_cleansing' | 'single_analytics' | 'synergy_v3' | 'synergy_merge'
    | 'nestle_env'

/**
 * TODO: Deprecate this function in favor of DashboardEnvironment
 */
export function getAppEnvironment(pageUrl: string): AppEnvironment {
    switch (pageUrl) {
        case routes.nestle_dashboard:
        case routes.nestle_supplier_normalization:
        case routes.nestle_supplier_master_normalization:
        case routes.nestle_common_suppliers:
        case routes.nestle_common_categories_review:
        case routes.nestle_common_categories:
        case routes.nestle_taxonomy_builder:
        case routes.nestle_taxonomy_builder_id:
        case routes.nestle_categorization_review:
        case routes.nestle_categorization_review_result:
            return 'nestle_env';
        case routes.single_data_home2:
        case routes.single_data_home:
        case routes.single_data_manage:
        case routes.single_cleansing_dashboard:
        case routes.single_cleansing_sup_nor:
        case routes.single_cleansing_cat_welcome:
        case routes.single_cleansing_cat_busy:
        case routes.single_cleansing_cat_review:
        case routes.single_cleansing_cat_result:
        case routes.single_cleansing_taxonomy_builder:
        case routes.single_cleansing_taxonomy_builder_id:
            return 'single_cleansing';
        case routes.single_analytics_dashboard:
        case routes.single_analytics_koi_ss:
        case routes.single_analytics_koi_kdf:
        case routes.single_analytics_koi_ppv:
        case routes.single_analytics_koi_sc:
        case routes.single_data_opportunities:    // dubious
        case routes.single_data_opportunities_id: // dubious
        case routes.single_data_report:           // dubious
            return 'single_analytics';
        case routes.synergy_v3:
        case routes.synergy_v3_data:
        case routes.synergy_v3_dashboard:
        case routes.synergy_v3_suppliers:
        case routes.synergy_v3_categories:
        case routes.synergy_v3_spend:
        case routes.synergy_v3_opportunities:    // dubious
        case routes.synergy_v3_opportunities_id: // dubious
        case routes.synergy_v3_report:           // dubious
            return 'synergy_v3';
        case routes.synergy_merge_dashboard:
        case routes.synergy_merge_taxonomy_viewer:
        case routes.synergy_merge_taxonomy_viewer_id:
        case routes.synergy_merge_taxonomy_builder:
        case routes.synergy_merge_taxonomy_builder_id:
        case routes.synergy_merge_taxonomy_mapper:
        case routes.synergy_merge_taxonomy_mapper_review:
        case routes.synergy_merge_review:
        case routes.synergy_merge_review_result:
            return 'synergy_merge';
        case routes.job_synergy_v1_0:
        case routes.job_synergy_v1_1:
        case routes.job_synergy_v1_2:
        case routes.job_synergy_v1_dashboard:
        case routes.job_synergy_v1_suppliers:
        case routes.job_synergy_v1_categories:
        case routes.job_synergy_v1_opportunities:
            return 'synergy_v1';
        case routes.synergy_v2:
        case routes.synergy_v2_data:
        case routes.synergy_v2_dashboard:
        case routes.synergy_v2_taxonomy_mapper:
        case routes.synergy_v2_taxonomy_review:
        case routes.synergy_v2_taxonomy_builder_id:
        case routes.synergy_v2_taxonomy_viewer:
        case routes.synergy_v2_taxonomy_viewer_id:
        case routes.synergy_v2_review:
        case routes.synergy_v2_review_result:
        case routes.synergy_v2_opportunities:
            return 'taxonomy_merge';
        default:
            return 'default';
    }
}

export function lookupHomePath(pageUrl: string): string {
    switch (pageUrl) {
        // The routes that cannot go to the dashboard have different behavior
        case routes.job_synergy_v1_0:
            return routes.job_synergy_v1_0
        case routes.job_synergy_v1_1:
            return routes.job_synergy_v1_1
        case routes.synergy_v2:
            return routes.synergy_v2
        case routes.approval:
            // Environment where there is no bag present
            return '/'
        case routes.approval_cat_detail:
        case routes.approval_tax_detail:
            // Special case
            return routes.approval;
        default:
            // The rest goes to the dashboard
            switch (getAppEnvironment(pageUrl)) {
                case "single_cleansing":
                    return routes.single_cleansing_dashboard;
                case "single_analytics":
                    return routes.single_analytics_dashboard;
                case "synergy_v3":
                    return routes.synergy_v3_dashboard;
                case "synergy_merge":
                    return routes.synergy_merge_dashboard;
                case "synergy_v1":
                    return routes.job_synergy_v1_dashboard;
                case "taxonomy_merge":
                    return routes.synergy_v2_dashboard;
                case "nestle_env":
                    return routes.nestle_dashboard;
                case "default":
                    return routes.job_dashboard;
            }
            return '/';
    }
}

export function generateHomePath(routeMatch: match<any>, params?: any) {
    return generatePath(lookupHomePath(routeMatch.path), params ? params : routeMatch.params);
}

export function generateGotoDashboardPath(routeMatch: match<any>, params?: any) {
    // The rest goes to the dashboard
    let url;
    switch (getAppEnvironment(routeMatch.path)) {
        case "synergy_v1":
            url = routes.job_synergy_v1_dashboard;
            break;
        case "taxonomy_merge":
            url = routes.synergy_v2_dashboard;
            break;
        case "default":
            url = routes.job_dashboard;
            break;
        case "single_cleansing":
        case "single_analytics":
        case "synergy_v3":
        case "synergy_merge":
            return generateHomePath(routeMatch, params);
        default:
            url = '/';
            break;
    }
    console.log('generateGotoDashboardPath', url);
    return generatePath(url, params ? params : routeMatch.params);
}
