import './TaxonomyMapperReview.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {useRouteMatch} from "react-router-dom";
import {JobRouteMatch, routes} from "../../routing/routes";
import {useStores} from "../../stores";
import {Button, Grid} from "@mui/material";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {generatePath, useHistory} from "react-router";
import Typography from "@mui/material/Typography";
import {TaxonomyMapperReviewTable} from "./table/TaxonomyMapperReviewTable";
import {ChevronRight} from "@mui/icons-material";
import {TaxonomyMapperUnmappedReviewTable} from "./table/TaxonomyMapperUnmappedReviewTable";

export const TaxonomyMapperReviewPage: React.FC = observer(() => {
    const {rootAppStore, taxonomyMapperStore} = useStores();
    const routeMatch = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    // const theme = useTheme();
    // const mediaTablet = useMediaQuery(theme.breakpoints.down('md'));

    return <KoiFacade
        title="Taxonomy Mapper Review"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={
            rootAppStore.app.dashEnv === 'nestle'
                ? generatePath(routes.nestle_common_categories, routeMatch.params)
                : generatePath(routes.synergy_v2_taxonomy_mapper, routeMatch.params)
        }
        next={{
            type: 'component',
            node: <Button
                color="primary"
                variant="outlined"
                endIcon={<ChevronRight/>}
                onClick={() => {
                    if (rootAppStore.app.dashEnv === 'nestle') {
                        history.push(generatePath(routes.nestle_dashboard))
                    } else if (rootAppStore.app.dashEnv === 'multi') {
                        history.push(generatePath(routes.synergy_merge_review, routeMatch.params))
                    } else {
                        history.push(generatePath(routes.synergy_v2_review, routeMatch.params))
                    }
                }}>
                {rootAppStore.app.dashEnv === 'nestle' ? 'Dashboard' : 'Submit'}
            </Button>
        }}
        className="taxonomy-mapper-review-page">

        <section className="unmatched">
            <header>
                <Typography variant="h2" className="title">
                    Not matched categories
                </Typography>
                <Typography variant="body1" className="explanation">
                    The categories below are not matched to an existing category.
                    You can still continue with the review process.
                    However, the entries associated with these categories will either be assigned as Uncategorized
                    or receive an Ai suggested category.
                </Typography>
            </header>

            <TaxonomyMapperUnmappedReviewTable/>

        </section>

        <section className="matched">
            <header>
                <Typography variant="h2" className="title">
                    Matched categories
                </Typography>
                <Grid container justifyContent="end" className="taxonomy-titles">
                    <Grid item sm={6}>
                        <Typography variant="h3" className="taxonomy-title base-title">
                            {taxonomyMapperStore.baseTaxonomyName}
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="h3" className="taxonomy-title target-title">
                            {taxonomyMapperStore.targetTaxonomyName}
                        </Typography>
                    </Grid>
                </Grid>
            </header>

            <TaxonomyMapperReviewTable/>
        </section>
    </KoiFacade>
});
