import React, {useEffect, useMemo, useRef} from "react";
import './CmpBreakdownSingleVisualization.scss';
import Margin from "../../../utils/margin";
import * as d3 from "d3";

export type Data = {
    top: DataRow
    bottom: DataRow
}
export type DataRow = {
    id?: string
    total: number
    value: number
    valueTitle?: string
    valueTitleHover: string
}

/**
 * Element that shows a location on the grid
 * y__
 * x0- |-----| -x1
 */
type CurveDataPoint = {
    y: number
    x0: number
    x1: number
}
type CurveData = CurveDataPoint & {
    hoverUp: boolean
}
/**
 * Element that shows the highlighted bar
 */
type VizDataRow = CurveData & {
    dataRow: DataRow
    isSmall: boolean
}

const TITLE_FONT_SIZE = 26;
const HOVER_OFFSET = 10;

const FLOW_HIDDEN_OVERLAP = 0.1;

const curve = d3.curveLinear;
const FLOW_CONTROL_POINT = 0.02;

// const curve = d3.curveBasis;
// const FLOW_CONTROL_POINT = .3;

export type Options = {
    width: number
    height: number
    margin: Margin
    alignRight: boolean
    fixedWidth: boolean
    barPortion: number
    flowStrokeWidth: number
    hoverVisible: boolean
    defaultWideMargin: boolean
}
const DEFAULT_MARGIN = {
    left: 1,
    right: 1,
    top: 1 + HOVER_OFFSET + TITLE_FONT_SIZE,
    bottom: 1 + HOVER_OFFSET + TITLE_FONT_SIZE,
};
const WIDE_MARGIN_RATIO = 1.5;

type Props = {
    data: Data
    options?: Partial<Options>
}
export const CmpBreakdownSingleVisualization: React.FC<Props> = ({data, options}) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const o = useMemo<Options>(() => ({
        width: 1800,
        height: 400,
        margin: options?.defaultWideMargin ? {
            left: DEFAULT_MARGIN.left * WIDE_MARGIN_RATIO,
            right: DEFAULT_MARGIN.right * WIDE_MARGIN_RATIO,
            top: DEFAULT_MARGIN.top * WIDE_MARGIN_RATIO,
            bottom: DEFAULT_MARGIN.bottom * WIDE_MARGIN_RATIO,
        } : DEFAULT_MARGIN,
        alignRight: false,
        fixedWidth: false,
        barPortion: 0.45,
        flowStrokeWidth: 1,
        hoverVisible: false,
        defaultWideMargin: false,
        ...options,
    }), [options]);

    useEffect(() => {
        const graphWidth = o.width - o.margin.left - o.margin.right;
        const graphHeight = o.height - o.margin.top - o.margin.bottom;
        const svg = d3.select(svgRef.current as SVGElement)
            .classed('left', o.alignRight)
            .classed('right', !o.alignRight)
        svg.html(''); // clear

        // // DEBUG: show margins
        // svg.append('rect')
        //     .attr('x', o.margin.left)
        //     .attr('y', o.margin.top)
        //     .attr('width', graphWidth)
        //     .attr('height', graphHeight);

        const root = svg
            .append("g")
            .attr("transform", "translate(" + o.margin.left + "," + o.margin.top + ")");

        const dataRows: DataRow[] = [data.top, data.bottom];

        const barChartHeight = dataRows.length * graphHeight /
            (o.barPortion * dataRows.length + (1 - o.barPortion) * (dataRows.length - 1));
        const yAxis = d3.scaleBand()
            .domain(dataRows.map((_, i) => String(i)))
            .range([0, barChartHeight]);
        const xAxes = dataRows.map(row =>
            d3.scaleLinear(
                [0, row.total],
                [0, graphWidth],
            )
        )

        const barHeight = yAxis.bandwidth() * o.barPortion;
        const overlap = barHeight * FLOW_HIDDEN_OVERLAP;
        const flowOffset = barHeight * FLOW_CONTROL_POINT;
        const smallLimit = graphWidth * 0.15;

        const vizDataRows = dataRows.map<VizDataRow>((dataRow, i) => {
            const x0 = o.alignRight ? (xAxes[i](dataRow.total - dataRow.value) as number) : 0;
            const width = xAxes[i](dataRow.value) as number;
            return ({
                dataRow,
                x0,
                x1: x0 + width,
                y: yAxis(`${i}`) as number,
                hoverUp: i === 0,
                isSmall: width < smallLimit,
            });
        })
        const flowData: CurveDataPoint[][] = vizDataRows.slice(1).map((bottom, i) => {
            const top = vizDataRows[i];
            // Return 4 data points:
            //        _1
            // |    | _2
            //  \   | _3
            //   |  | _4
            //
            const stroke2 = o.flowStrokeWidth / 2;
            const topX0 = o.alignRight ? top.x1 - stroke2 : stroke2;
            const topX1 = o.alignRight ? top.x0 + stroke2 : top.x1 - stroke2;
            const bottomX0 = o.alignRight ? top.x1 - stroke2 : stroke2;
            const bottomX1 = o.alignRight ? bottom.x0 + stroke2 : bottom.x1 - stroke2;
            return [
                {y: top.y + barHeight - overlap, x0: topX0, x1: topX1},
                {y: top.y + barHeight, x0: topX0, x1: topX1},
                {y: top.y + barHeight + flowOffset, x0: topX0, x1: topX1},
                {y: bottom.y - flowOffset, x0: bottomX0, x1: bottomX1},
                {y: bottom.y, x0: bottomX0, x1: bottomX1},
                {y: bottom.y + overlap, x0: bottomX0, x1: bottomX1},
            ];
        })
        const flowArea = d3.area<CurveDataPoint>()
            .curve(curve)
            .x0(d => d.x0)
            .x1(d => d.x1)
            .y(d => d.y)


        const flowGroup = root
            .append('g')
            .classed('flows', true)
        const flows = flowGroup.selectAll('g.flow')
            .data(flowData)
            .join('g')
            .classed('flow', true)
        flows.append('path')
            .attr('d', d => flowArea(d) as string)
            .attr('stroke-width', o.flowStrokeWidth)

        const barGroup = root
            .append('g')
            .classed('data-bars', true)

        const barGroups = barGroup
            .selectAll('g.normal.bar-wrapper')
            .data(vizDataRows)
            .join('g')
            .classed('bar-wrapper', true)
            .classed('normal', true)

        // Common
        barGroups.append('rect')
            .classed('value-highlighted', true)
            .classed('first', (_, i) => i === 0)
            .classed('last', (_, i) => i === 1)
            .attr('x', d => d.x0)
            .attr('y', d => d.y)
            .attr('width', d => d.x1 - d.x0)
            .attr('height', barHeight)

        // Rest
        barGroups.append('rect')
            .classed('value-normal', true)
            .classed('first', (_, i) => i === 0)
            .classed('last', (_, i) => i === 1)
            .attr('x', d => o.alignRight ? 0 : d.x1)
            .attr('y', d => d.y)
            .attr('width', d => o.alignRight ? d.x0 : o.width - d.x1)
            .attr('height', barHeight)

        const labelGroup = root
            .append('g')
            .classed('labels', true)
        const normalLabelGroups = labelGroup
            .selectAll('g.normal.label')
            .data(vizDataRows)
            .join('g')
            .classed('label normal', true)

        normalLabelGroups.append('text')
            .classed('value title', true)
            .classed('small', d => d.isSmall)
            .attr('dominant-baseline', 'middle')
            .attr("text-anchor", 'middle')
            .attr('font-size', TITLE_FONT_SIZE)
            .attr('x', d => (d.x1 + d.x0) / 2)
            .attr('y', d => d.y + barHeight / 2 + 1)
            .text(d => d.dataRow.valueTitle || '')

        normalLabelGroups.append('text')
            .classed('value hover-title', true)
            .classed('hover', !o.hoverVisible)
            .attr('dominant-baseline', d => d.hoverUp ? 'text-top' : 'hanging')
            .attr('font-size', TITLE_FONT_SIZE)
            .attr("text-anchor", 'middle')
            .attr('x', d => (d.x1 + d.x0) / 2)
            .attr('y', d => d.y + (d.hoverUp ? -HOVER_OFFSET : barHeight + HOVER_OFFSET))
            .text(d => d.dataRow.valueTitleHover || '')
    }, [data, o])

    const cssWidth = o.fixedWidth ? `${o.width}px` : '100%';
    return <svg
        className="cmp-breakdown-single-viz"
        ref={svgRef}
        viewBox={`0 0 ${o.width} ${o.height}`}
        style={{width: cssWidth, height: 'auto'}}
    />;
}

