import React from "react";
import {useStores} from "../../../stores";
import {generatePath} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {CmpDataBagRouteMatch, routes, TaxonomyRouteMatch} from "../../../routing/routes";
import {lookupHomePath} from "../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../components/main/MenuItems";

export const DefaultMenu: React.FC = () => {
    const {p} = useStores();
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();
    const hasId1 = m.params.id !== undefined;
    const homeLink = lookupHomePath(m.path);

    // const existsHome = homeLink !== null;
    const isHome = m.path === homeLink;

    const menu: (MenuItem | undefined)[] = []

    if (p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('data'))
        menu.push({
            label: 'Data',
            link: hasId1 ? generatePath(routes.job_manage, m.params) : routes.home,
            active: m.path === routes.home || m.path === routes.job_manage,
            className: '',
        })
    if (p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('synergies'))
        menu.push({
            label: 'Synergies',
            link: generatePath(hasId1 ? routes.synergy_v2_dashboard : routes.synergy_v2, m.params),
            active: m.path === routes.synergy_v2_dashboard,
            className: '',
        })
    if (p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('opportunities'))
        menu.push({
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.job_opportunities, m.params) : routes.home,
            active: !isHome && m.path === routes.job_opportunities,
            isTrial: p.p.tease_main_menu
                || (p.p.tease_menu_components !== undefined && p.p.tease_menu_components.includes('opportunities')),
            className: 'menu-item-2nd-last',
        })
    if (
        p.p.main_menu_components !== undefined && p.p.main_menu_components.includes('report')
        // Reporting should only appear when there is a data source selected
        && hasId1
    )
        menu.push({
            label: 'Report',
            link: hasId1 ? generatePath(routes.job_report, m.params) : routes.home,
            active: !isHome && m.path === routes.job_report,
            isTrial: p.p.tease_main_menu
                || (p.p.tease_menu_components !== undefined && p.p.tease_menu_components.includes('report')),
            className: 'menu-item-last',
        })

    return <MenuItems items={menu}/>
}
