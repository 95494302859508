import React from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../stores";
import {Table, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow} from "@mui/material";
import {TablePaginationActions} from "../../../../components/table-pagination/TablePaginationActions";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {ParentSupplierReviewMainRow} from "./ParentSupplierReviewMainRow";
import {TableHeadColumns} from "./TableHeadColumns";
import {PARENT_SUPPLIER_COLUMNS} from "./columns";
import {getAbbreviation} from "../SupplierNormalization.classes";

export const ParentSupplierReviewMainTable: React.FC = observer(() => {
    const {supplierNormalizationStore} = useStores()
    const isLoading = supplierNormalizationStore.review.isLoadingRows;
    const dataPages = supplierNormalizationStore.review._parentSupplierRowsRequest;
    // const dataPages = undefined;
    const data = supplierNormalizationStore.review._parentSupplierRowsRequest.data;
    const abbreviation = getAbbreviation(data);
    const tableClass = 'mithra-table x-design parent-supplier-review-table' + (isLoading ? ' loading' : '');
    return <TableContainer className={tableClass}>
        <Table cellPadding="0">
            <TableHeadColumns columns={PARENT_SUPPLIER_COLUMNS} enableLoading isLoading={isLoading}/>
            <TableBody>
                {data ? <>
                        {data.map(row => <React.Fragment key={row.id}>
                            <CurrencyAbbreviationContext.Provider value={abbreviation}>
                                <ParentSupplierReviewMainRow row={row}/>
                            </CurrencyAbbreviationContext.Provider>
                        </React.Fragment>)}
                        {data.length === 0 &&
                            <TableRow className="no-data">
                                <TableCell colSpan={PARENT_SUPPLIER_COLUMNS.length}>
                                    No data
                                </TableCell>
                            </TableRow>
                        }
                    </>
                    : <TableRow className="no-data-yet"/>
                }
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        //
                        rowsPerPageOptions={[]}
                        colSpan={PARENT_SUPPLIER_COLUMNS.length}
                        count={dataPages.count}
                        rowsPerPage={dataPages.pageSize}
                        page={dataPages.page - 1}
                        // SelectProps={{
                        //     inputProps: {'aria-label': 'rows per page'},
                        //     native: true,
                        // }}
                        // labelRowsPerPage={extraNarrow ? 'Rows:' : undefined}
                        onPageChange={(e, page) => dataPages.changePage(page + 1)}
                        // onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
})
