import React from "react";
import {observer} from "mobx-react-lite";
import './AtkDashBoard.scss'
import {useStores} from "../../stores";
import {Alert, AlertTitle, Button, Grid, Typography} from "@mui/material";
import {generatePath, useHistory} from "react-router";
import {routes} from "../../routing/routes";
import {Bag} from "../../services/classes/Bag";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {ClassificationIcon} from "../../components/icons/ClassificationIcon";
import {DashBoardCard} from "../dashboard/DashBoardCard";
import {TaxonomyMapperIcon} from "../../components/icons/TaxonomyMapperIcon";
import {useRouteMatch} from "react-router-dom";

const SUBTITLE_LOADING = ''
const VALUE_LOADING = '-'
const OPPORTUNITY_THRESHOLD = 1000;

function drawOpportunityValue(opp: number | undefined) {
    if (opp === undefined) {
        return VALUE_LOADING
    }
    if (opp === 0) {
        return <CurrencyComponent v={0} nDigits={3}/>
    }
    return <CurrencyComponent v={opp} nDigits={3}/>
}

export const AtkDashBoard: React.FC = observer(() => {
    const {bagStore, categorizationStore, taxonomyMapperStore, taxonomyManagerStore} = useStores();
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const bag = bagStore.bag as Bag;

    const newDatasetId = bag.latest_version !== bag.id ? bag.latest_version : undefined;

    const unclassifiedSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;
    return <>
        {
            newDatasetId && bagStore.allBags !== undefined && <div className="dashboard-grid-alert">
                <Grid container justifyContent="center">
                    <Grid item xs={4}>
                        <Alert variant="standard" severity="warning" action={
                            <Button
                                color="inherit" size="small"
                                onClick={() => bagStore.changeBag(newDatasetId, history, routeMatch)}>
                                Open the new dataset
                            </Button>
                        }>
                            <AlertTitle>This is an old version</AlertTitle>
                            This dataset is reviewed and all the changes are applied in a newer version of the dataset
                        </Alert>
                    </Grid>
                </Grid>
            </div>
        }
        <Grid container
              className="dashboard atk-dashboard-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashBoardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Viewer"
                    subtitle={taxonomyManagerStore.taxonomy?.name}
                    link={generatePath(routes.synergy_v2_taxonomy_viewer, {id: bag.id})}
                />
            </DashBoardRow>
            <DashBoardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common
                    ? <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/>
                    : SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="taxonomy-mapper"
                    avatar={<TaxonomyMapperIcon/>}
                    title="Taxonomy Mapper"
                    value={taxonomyMapperStore.mapper.unMatchedCategories?.length || SUBTITLE_LOADING}
                    subtitle="unmapped categories"
                    link={generatePath(routes.synergy_v2_taxonomy_mapper, {id: bag.id})}
                    badge="Ai"
                />
            </DashBoardRow>
            <DashBoardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common ? String(bagStore.kpi.common.n_suppliers) : SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Harmonize Categories"
                    value={drawOpportunityValue(unclassifiedSpend)}
                    valueColor={unclassifiedSpend
                        ? (unclassifiedSpend >= OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.synergy_v2_review, {id: bag.id})}
                    badge="Ai"
                />
            </DashBoardRow>
        </Grid>
    </>
});

type RowProps = {
    className: string
    title: string
    subtitle: React.ReactNode
}
const DashBoardRow: React.FC<RowProps> = ({className, title, subtitle, children}) =>
    <Grid item lg={3} xs={4} className={`dashboard-row ${className}`}>
        <Grid container direction="column" alignItems="center">
            <Grid item className="dashboard-row-title">
                <Typography variant="h2" component="h2">
                    {title}
                </Typography>
            </Grid>
            <Grid item className="dashboard-row-subtitle">
                <Typography variant="subtitle2" component="h2">
                    {subtitle}
                </Typography>
            </Grid>
        </Grid>
        <Grid container justifyContent="center">
            <Grid item sm={11} xs={12} className="dashboard-row-content">
                {children}
            </Grid>
        </Grid>
    </Grid>
