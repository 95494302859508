import './SynergyCategoriesPage.scss';
import React, {useEffect, useMemo, useState} from "react";
import {Button, Grid, useMediaQuery, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import {CmpDataBagRouteMatch} from "../../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";
import {useStores} from "../../../stores";
import {
    MatchCategoriesTreeVisualization,
    Options
} from "../../../components/visualization/match-categories-tree/MatchCategoriesTreeVisualization";
import {
    CmpBreakdownSingleVisualization
} from "../../../components/visualization/cmp-breakdown-single/CmpBreakdownSingleVisualization";
import {AnnotatedValueComponent} from "../../../components/annotated-value-component/AnnotatedValueComponent";
import {observer} from "mobx-react-lite";
import {
    cmpVizOptionsLeft,
    cmpVizOptionsRight,
    cmpVizTabletOptionsLeft,
    cmpVizTabletOptionsRight
} from "../synergyTypes";
import {SynergyCategoryDetailTable} from "./SynergyCategoryDetailTable";
import {Add, Check, Link, LinkOff} from "@mui/icons-material";
import {SynergyCategoryOpportunityModal} from "./SynergyCategoryOpportunityModal";
import {runInAction} from "mobx";
import {generateHomePath} from "../../../routing/routing.utils";

export const SynergyCategoriesPage: React.FC = observer(() => {
    const routeMatch = useRouteMatch<CmpDataBagRouteMatch>();
    const {cmpStore} = useStores();
    const theme = useTheme();
    const mediaTablet = useMediaQuery(theme.breakpoints.down('md'));

    const categoryCompareData = cmpStore.cmpCat.categoryCompareData;
    const categoryCompareDataBreakdown = cmpStore.cmpCat.categoryCompareDataBreakdown;

    const optionsLeft = mediaTablet ? cmpVizTabletOptionsLeft : cmpVizOptionsLeft;
    const optionsRight = mediaTablet ? cmpVizTabletOptionsRight : cmpVizOptionsRight;

    useEffect(() => {
        const s = cmpStore.cmpCat.downloadHardcodedData();
        return () => s.unsubscribe();
    }, [cmpStore])

    return <KoiFacade
        title="Compare Categories"
        // helpContent={<PurchasePriceVarianceHelp/>}
        intro={<></>}
        back={generateHomePath(routeMatch)}
        className="synergy-categories-page">
        <section className="page-item">

            <Grid container spacing={0} justifyContent="space-evenly" alignItems="center" className="page-bag-titles">
                <Grid item lg={4} md={5} sm={6}>
                    <Typography variant="h3" className="bag-title">{cmpStore.getMasterBagName()}</Typography>
                </Grid>
                <Grid item lg={3} md={2} sm="auto">
                </Grid>
                <Grid item lg={4} md={5} sm={6}>
                    <Typography variant="h3" className="bag-title">{cmpStore.getCmpBagName()}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} justifyContent="space-evenly" alignItems="stretch"
                  className="page-breakdown-viz">
                <Grid item lg={4} md={4}>
                    {categoryCompareDataBreakdown &&
                        <CmpBreakdownSingleVisualization
                            data={categoryCompareDataBreakdown.masterData}
                            options={optionsLeft}
                        />
                    }
                </Grid>
                <Grid item lg={3} md={3}
                      container direction="column" justifyContent="space-between" className="breakdown-values">
                    <Grid item className="top-breakdown">
                        <Typography variant="h4" className="title">
                            {cmpStore.cmpCat.currentCompareCategory
                                ? 'Similar ' + cmpStore.cmpCat.currentCompareCategory
                                : 'Total similar categories'
                            }
                        </Typography>
                        <Typography variant="h4" className="value">
                            {categoryCompareData &&
                                <AnnotatedValueComponent v={categoryCompareData.category_n_overlap}/>
                            }
                        </Typography>
                    </Grid>
                    <Grid item className="bottom-breakdown">
                        <Typography variant="h4" className="title">
                            Combined spend
                        </Typography>
                        <Typography variant="h4" className="value">
                            {categoryCompareData &&
                                <CurrencyComponent
                                    v={categoryCompareData.master_spend_overlap + categoryCompareData.compare_spend_overlap}
                                    nDigits={3}
                                />
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item lg={4} md={4}>
                    {categoryCompareDataBreakdown &&
                        <CmpBreakdownSingleVisualization
                            data={categoryCompareDataBreakdown.cmpData}
                            options={optionsRight}
                        />
                    }
                </Grid>
            </Grid>

            <SynergyMatchCategoriesVisualization/>
        </section>
    </KoiFacade>
});

const SynergyMatchCategoriesVisualization: React.FC = observer(() => {
    const {cmpStore, opportunityStore} = useStores();
    const data = cmpStore.cmpCat.matchTreeData;

    const height = 5 + Math.max(data?.left.descendants().length || 0, data?.right.descendants().length || 0) * 16.5 || 300;
    const options = useMemo<Partial<Options>>(() => ({
        width: 700,
        height,
        onClickData: d => cmpStore.cmpCat.onClickData(d),
    }), [cmpStore, height])

    const [isOpportunityModelOpen, setOpportunityModalOpen] = useState(false);
    const opportunityCreated = opportunityStore.hasOpportunity(cmpStore.cmpCat.selectedOpportunityId)
    const canCreateOpportunity = !opportunityCreated && cmpStore.cmpCat.selectedOpportunityId;

    return <div>
        <SynergyCategoryOpportunityModal isOpen={isOpportunityModelOpen} setOpen={setOpportunityModalOpen}/>
        {/*<Grid container justifyContent="center" className="page-item">*/}
        {/*    <Grid item xs={12}>*/}
        {/*        {cmpStore.cmpCat.connectionData?.connections.map((c, i) =>*/}
        {/*            <div key={i}>*/}
        {/*                {c.leftId + ' <-> ' + c.rightId}*/}
        {/*            </div>*/}
        {/*        )}*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={6}>*/}
        {/*        {cmpStore.cmpCat.leftSelection?.label}*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={6}>*/}
        {/*        {cmpStore.cmpCat.rightSelection?.label}*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={12}>*/}
        {/*        conn=*/}
        {/*        {cmpStore.cmpCat.selectedCreatedConnection?.leftId}*/}
        {/*        _*/}
        {/*        {cmpStore.cmpCat.selectedCreatedConnection?.rightId}*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}

        <Grid container justifyContent="center" className="match-categories-toolbar">
            <Grid item>
                <Button
                    variant="outlined"
                    startIcon={cmpStore.cmpCat.isConnected ? <LinkOff/> : <Link/>}
                    disabled={!cmpStore.cmpCat.canConnect}
                    onClick={() => cmpStore.cmpCat.onClickCreateConnection()}>
                    {cmpStore.cmpCat.isConnected ? 'Disconnect' : 'Connect'}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="outlined"
                    startIcon={opportunityCreated ? <Check/> : <Add/>}
                    disabled={!canCreateOpportunity}
                    onClick={() => runInAction(() => {
                        opportunityStore.isEdit = false;
                        setOpportunityModalOpen(true);
                    })}>
                    Add opportunity
                </Button>
            </Grid>
        </Grid>
        <Grid container justifyContent="space-evenly">
            <Grid item lg={3} md={3}>
                {cmpStore.cmpCat.commonMasterSupplierData &&
                    <SynergyCategoryDetailTable
                        is_master={true}
                        data={cmpStore.cmpCat.commonMasterSupplierData}
                    />
                }
            </Grid>
            <Grid item lg={5} md={6}>
                <MatchCategoriesTreeVisualization
                    data={data}
                    options={options}
                    updateData={cmpStore.cmpCat.connectionData}
                />
            </Grid>
            <Grid item lg={3} md={3}>
                {cmpStore.cmpCat.commonCmpSupplierData &&
                    <SynergyCategoryDetailTable
                        is_master={false}
                        data={cmpStore.cmpCat.commonCmpSupplierData}
                    />
                }
            </Grid>
        </Grid>
    </div>
})
