import {TreeValuesResponse} from "../ApiTypes";
import {L1To8} from "../ApiHelpers";

type Decimal = number // Although we receive a string from the API

// Backend classes

export enum ReviewChoice {
    ACCEPT,
    REJECT,
}

declare type AbsMatSupplierReviewRow = {
    id: number
    // databag_id: number
    // supplier_id: number
    s_id: number
    s_name: string
    s_city: string
    s_country: string
    s_region: string
    s_total_spend: Decimal
    s_total_parts: number
    s_total_l1s: string[]
    s_total_bus: string[]
}
export type MatSupplierReviewRow = AbsMatSupplierReviewRow;
export type MatSupplierL1ReviewRow = AbsMatSupplierReviewRow & {
    l1: string
    s_l1_spend: Decimal
    s_l1_parts: number
}
export type MatSupplierL2ReviewRow = AbsMatSupplierReviewRow & {
    l1: string
    l2: string
    s_l2_spend: Decimal
    s_l2_parts: number
}
export type MatSupplierL3ReviewRow = AbsMatSupplierReviewRow & {
    l1: string
    l2: string
    l3: string
    s_l3_spend: Decimal
    s_l3_parts: number
}
export type MatSupplierL4ReviewRow = AbsMatSupplierReviewRow & {
    l1: string
    l2: string
    l3: string
    l4: string
    s_l4_spend: Decimal
    s_l4_parts: number
}
export type MatSupplierL5ReviewRow = AbsMatSupplierReviewRow & {
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    s_l5_spend: Decimal
    s_l5_parts: number
}
export type MatSupplierL6ReviewRow = AbsMatSupplierReviewRow & {
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    s_l6_spend: Decimal
    s_l6_parts: number
}
export type MatSupplierL7ReviewRow = AbsMatSupplierReviewRow & {
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    s_l7_spend: Decimal
    s_l7_parts: number
}
export type MatSupplierL8ReviewRow = AbsMatSupplierReviewRow & {
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    l8: string
    s_l8_spend: Decimal
    s_l8_parts: number
}
export type MatSupplierBuReviewRow = AbsMatSupplierReviewRow & {
    business_unit_id: number | null
    s_bu_spend: Decimal
    s_bu_parts: number
};
export type MatSupplierBuL1ReviewRow = AbsMatSupplierReviewRow & {
    business_unit_id: number | null
    l1: string
    s_bu_l1_spend: Decimal
    s_bu_l1_parts: number
}
export type MatSupplierBuL2ReviewRow = AbsMatSupplierReviewRow & {
    business_unit_id: number | null
    l1: string
    l2: string
    s_bu_l2_spend: Decimal
    s_bu_l2_parts: number
}
export type MatSupplierBuL3ReviewRow = AbsMatSupplierReviewRow & {
    business_unit_id: number | null
    l1: string
    l2: string
    l3: string
    s_bu_l3_spend: Decimal
    s_bu_l3_parts: number
}
export type MatSupplierBuL4ReviewRow = AbsMatSupplierReviewRow & {
    business_unit_id: number | null
    l1: string
    l2: string
    l3: string
    l4: string
    s_bu_l4_spend: Decimal
    s_bu_l4_parts: number
}
export type MatSupplierBuL5ReviewRow = AbsMatSupplierReviewRow & {
    business_unit_id: number | null
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    s_bu_l5_spend: Decimal
    s_bu_l5_parts: number
}
export type MatSupplierBuL6ReviewRow = AbsMatSupplierReviewRow & {
    business_unit_id: number | null
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    s_bu_l6_spend: Decimal
    s_bu_l6_parts: number
}
export type MatSupplierBuL7ReviewRow = AbsMatSupplierReviewRow & {
    business_unit_id: number | null
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    s_bu_l7_spend: Decimal
    s_bu_l7_parts: number
}
export type MatSupplierBuL8ReviewRow = AbsMatSupplierReviewRow & {
    business_unit_id: number | null
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    l8: string
    s_bu_l8_spend: Decimal
    s_bu_l8_parts: number
}
export type MatPartReviewRow = {
    id: number
    databag_id: number
    part_id: number

    mat_supplier_review_row: number
    mat_supplier_l1_review_row: number
    mat_supplier_l2_review_row: number
    mat_supplier_l3_review_row: number
    mat_supplier_l4_review_row: number
    mat_supplier_l5_review_row: number
    mat_supplier_l6_review_row: number
    mat_supplier_l7_review_row: number
    mat_supplier_l8_review_row: number
    mat_supplier_bu_review_row: number
    mat_supplier_bu_l1_review_row: number
    mat_supplier_bu_l2_review_row: number
    mat_supplier_bu_l3_review_row: number
    mat_supplier_bu_l4_review_row: number
    mat_supplier_bu_l5_review_row: number
    mat_supplier_bu_l6_review_row: number
    mat_supplier_bu_l7_review_row: number
    mat_supplier_bu_l8_review_row: number

    p_id: number

    p_name: string
    p_description: string

    p_spend: Decimal
    p_context_1: string
    p_context_2: string
    p_context_3: string
    p_prev_cat_l1: string
    p_prev_cat_l2: string
    p_prev_cat_l3: string
    p_prev_cat_l4: string
    p_prev_cat_l5: string
    p_prev_cat_l6: string
    p_prev_cat_l7: string
    p_prev_cat_l8: string
    p_suggestion_score: number
    p_suggested_cat_l1: string
    p_suggested_cat_l2: string
    p_suggested_cat_l3: string
    p_suggested_cat_l4: string
    p_suggested_cat_l5: string
    p_suggested_cat_l6: string
    p_suggested_cat_l7: string
    p_suggested_cat_l8: string
    review_choice: ReviewChoice | null
    review_user_id: number | null
    review_notes: string
    p_review_cat_l1: string
    p_review_cat_l2: string
    p_review_cat_l3: string
    p_review_cat_l4: string
    p_review_cat_l5: string
    p_review_cat_l6: string
    p_review_cat_l7: string
    p_review_cat_l8: string
    approval: number | null
    approval_applied: boolean
    feedback_choice: ReviewChoice | null
    feedback_user_id: number | null
    feedback_notes: string

    /**
     * @deprecated Feedback is not allowed to change the categories anymore
     */
    p_feedback_cat_l1: string
    p_feedback_cat_l2: string
    p_feedback_cat_l3: string
    p_feedback_cat_l4: string
    p_feedback_cat_l5: string
    p_feedback_cat_l6: string
    p_feedback_cat_l7: string
    p_feedback_cat_l8: string
}

export type MatPartReviewRowWithContext = MatPartReviewRow & {
    context: { [l in L1To8]: string }
}

export type MatReviewStatisticsSerializer = {
    databag_id: number
    total_spend: Decimal
    total_parts: number
    cat_new_spend: Decimal
    cat_new_parts: number
    cat_same_spend: Decimal
    cat_same_parts: number
    cat_recat_spend: Decimal
    cat_recat_parts: number
}
export type MatReviewLevelStatisticsTreeSerializer = TreeValuesResponse<{
    pre_spend: number,
    post_spend: number,
    pre_parts: number,
    post_parts: number,
    pre_suppliers: number,
    post_suppliers: number
}>


export type MatConcentrationStatistics = {
    databag: number
    total_parts: number
    total_spend: Decimal
    total_suppliers: number
    top_n_percentage: number
    top_n_spend: Decimal
    top_n_spend_percentage: Decimal
}

export type MatCategoryConcentrationStatistics = {
    id: number
    level: number
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    l8: string
    databag: number

    total_spend: Decimal
    distinct_suppliers: number
    top_n_percentage: number
    top_n_spend: Decimal
    top_n_spend_percentage: number
}

export type MatSupplierCategoryConcentrationStatistics = {
    id: number
    level: number
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    l8: string
    databag: number

    supplier_id: number
    s_id: string
    s_name: string

    s_c_spend: Decimal
    s_c_cumulative_spend: number
    s_c_cumulative_spend_percentage: number
    s_total_spend: Decimal
    s_total_cumulative_spend: number
    s_total_cumulative_spend_percentage: number
}

export type PartData = {
    p_id: string
    p_name: string
    p_description: string
    p_spend: Decimal
    p_context_1: string
    p_context_2: string
    p_context_3: string
}

export type BusinessUnit = {
    id: number
    databag_id: number
    bu_id: string
    bu_name: string
}

export type ApplyTaxonomyMappingRequestData = {
    src_mat_taxonomy: number
    dst_mat_taxonomy: number
    map_src_classification_databag: number
    map_dst_classification_databag: number
}

export type MaterializeRequest = {
    databag: number
    clear: boolean
}


export type PpvGroup = {
    id: number
    databag: number
    group_key: string
    group_size: number
    unit_cost_min: number
    unit_cost_max: number
    unit_cost_avg: number
    quantity_sum: number
    spend_sum: number
    n_supplier_ids: number
    ppv_delta: number
    ppv: number
    ppv_opportunity: number
}
export type PpvStatistic = {
    id: number
    databag: number
    level: number
    l1: string
    l2: string
    l3: string
    l4: string
    l5: string
    l6: string
    l7: string
    l8: string

    total_ppv_opportunity: number
    // groups: PpvGroup[]
}
