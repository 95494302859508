import React from "react";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../constants";
import {Chip} from "@mui/material";

export const CategoryChip: React.FC<{ labels: string[] }> = ({labels}) => <>
    {labels.map((c, i) => {
        let categoryLabel = c === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : c;
        return <React.Fragment key={i}>
            <Chip
                // variant={categorizationStore.data.selectedCategory === c ? "default" : "outlined"}
                variant="outlined"
                // color={categorizationStore.data.selectedCategory === c ? "primary" : "default"}
                size="small"
                label={categoryLabel}/>
            {(i < labels.length - 1) ? ' > ' : ''}
        </React.Fragment>
    })}
</>
