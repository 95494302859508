import './LoginPage.scss';
import React, {useEffect} from "react";
import {FullPageCard} from "../components/full-page-card/FullPageCard";
import {useStores} from "../stores";
import {Alert, Button, Typography} from "@mui/material";
import {useGoogleLogin} from "@react-oauth/google";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";

// Taken from https://dev.to/sivaneshs/add-google-login-to-your-react-apps-in-10-mins-4del
// https://betterprogramming.pub/building-basic-react-authentication-e20a574d5e71

// type AlertMsg = undefined | { msg: string, severity: AlertColor };
// const LOGOUT_MSG: AlertMsg = {msg: 'Your are now logged out', severity: 'success'};
// const EXPIRED_MSG: AlertMsg = {msg: 'Session is expired, login again', severity: 'info'};

export const LoginPage: React.FC = observer(() => {
    const {authStore, materializedApi} = useStores();

    const [pingSuccess, setPingSuccess] = React.useState<boolean | undefined>();

    useEffect(() => {
        // Periodically ping the backend to keep the session alive
        const performPing = () => {
            materializedApi.getServerPing().then(() => {
                setPingSuccess(true)
                console.log('Ping successful');
            }).catch(() => {
                setPingSuccess(false)
                console.log('Ping failed');
            });
        }
        performPing();
        const interval = setInterval(performPing, 1000 * 3);
        return () => clearInterval(interval);
    }, [materializedApi])

    return <FullPageCard
        className="login-component"
        minHeight="12em"
        content={<>
            <Typography component="p">
                {/*You have been given access to the Mithra-Ai.<br/>*/}
                Please sign in with the email address you provided.
            </Typography>
            {pingSuccess === false &&
                <Alert severity="error">
                    Could not reach the server
                </Alert>
            }
            {authStore.authentication.alert &&
                <Alert severity={authStore.authentication.alert.severity}>
                    {authStore.authentication.alert.msg}
                </Alert>
            }
        </>}
        actions={
            authStore.authentication.isLoggingIn
                ? <Typography component="p">Logging in...</Typography>
                : <GoogleLoginButton/>
        }
    />;
});

const GoogleLoginButton: React.FC = observer(() => {
    const {authStore} = useStores();
    const history = useHistory();
    const location = useLocation();
    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        ux_mode: 'popup',
        onSuccess: codeResponse => authStore.authentication.onGoogleAuthorizeSuccess(codeResponse, location, history)
    })
    return <Button
        variant="contained"
        onClick={() => authStore.authentication.startGoogleLogin(googleLogin)}>
        Login
    </Button>
});
