import {makeAutoObservable, reaction, runInAction} from "mobx";
import {forkJoin, from, mergeMap, Subscription} from "rxjs";
import {Categories} from "../services/classes/AiClasses";
import {BagStore} from "./BagStore";
import {ApprovalStore} from "./ApprovalStore";
import {PipeManager} from "./managers/PipeManager";
import AuthStore from "./AuthStore";
import {setOnObj} from "../services/ApiHelpers";
import {ReviewChoice} from "../services/classes/MaterializedClasses";
import MithraMaterializedApi from "../services/MithraMaterializedApi";
import {CategorizationStore} from "./CategorizationStore";
import {TaxonomyCategoryProvider} from "./TaxonomyCategoryProvider";
import {
    MatPartReviewRowState,
    StorePartReviewManySerializer,
    StorePartReviewSerializer
} from "../services/classes/MatReviewClasses";
import {ApprovalCategorization, calcCombinedState} from "../services/classes/MatReviewHelpers";

export type CombinedReviewState = {
    btn: 'open'
        | 'accept'
        | 'hidden'
    extra: 'waiting_approval'
        | 'all_approved_accepted'
        | 'all_approval_prev_rejected'
        | 'all_approval_rejected'
        | 'mixed_states'
        | ''
    editAllowed: boolean
    reviewedByOther: boolean
    cat: ApprovalCategorization
};

export type SingleReviewState = {
    btn: 'open'
        | 'accepted'
        // Not used: 'rejected'
        | 'hidden'
    extra: 'waiting_approval'
        | 'approved_accepted'
        | 'approval_rejected'
        | 'approval_prev_rejected'
        | ''
    editAllowed: boolean
    reviewedByOther: boolean
    leftCat: ApprovalCategorization
    rightCat: ApprovalCategorization
};

export type TaxonomyCategory = {
    values: string[]
    label: string
    search: string
}

type StorePartReviewPipe = { data: StorePartReviewSerializer, partId: number };

type ReviewState = 'approved'
    | 'not_approved_review_open'
    | 'not_approved_review_accept'
    | 'waiting_approval'
    | 'open'
    | 'review_accepted';

export class CategorizationReviewStore {
    isSendForApprovalDialog = false;
    isBusySendForApproval = false;
    isSendForApprovalError: boolean | string = false;
    approvalNotes = ''

    readonly accRejPipe = new PipeManager<StorePartReviewPipe>(
        ({data, partId}) => from(this.api.storePartReview(partId, data))
    )
    readonly accRejMultiPipe = new PipeManager<StorePartReviewManySerializer>(
        data => from(this.api.storePartReviewMany(data))
    )

    readonly categories = new TaxonomyCategoryProvider(this.api);

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private approvalStore: ApprovalStore,
        private categorizationStore: CategorizationStore,
        private authStore: AuthStore,
    ) {
        makeAutoObservable(this)
        reaction(() => [this.categorizationStore.page, this.anyStoreRequestBusy] as const, ([page, b,]) => {
            // As soon as the communication with the BE starts, go away from the finish screen
            if(page === 'result' && b) {
                this.categorizationStore.navigateToPage('review');
            }
        })
    }

    get anyStoreRequestBusy(): boolean {
        return this.accRejPipe.hasInPipe
            || this.accRejMultiPipe.hasInPipe
    }

    get numberOfBusyRequests(): number {
        return this.accRejPipe.nInPipe
            + this.accRejMultiPipe.nInPipe
    }

    static calcCombinedReviewState(parts?: MatPartReviewRowState[]): CombinedReviewState {
        if (parts === undefined || parts.length === 0)
            return {btn: 'hidden', extra: '', editAllowed: false, reviewedByOther: false, cat: 'none'};

        // Collect the states
        const part1 = parts[0];

        let state = CategorizationReviewStore.getReviewState(part1);
        let allState: ReviewState | 'mix' = state;
        let hasWaiting = state === 'waiting_approval';  // extraneous due to simplification
        let reviewedByOther = part1.review_user_id !== null && !part1.review_mine;
        for (const part of parts.slice(1)) {
            state = CategorizationReviewStore.getReviewState(part);
            if (state !== allState) allState = 'mix';
            hasWaiting = hasWaiting || state === 'waiting_approval';
            reviewedByOther = reviewedByOther || (part.review_user_id !== null && !part.review_mine);
        }

        let btn: CombinedReviewState['btn'];
        let extra: CombinedReviewState['extra'] = '';
        let cat: CombinedReviewState['cat'] = 'review|ai';
        let editAllowed = true;
        switch (allState) {
            case "approved":
                btn = 'hidden';
                extra = 'all_approved_accepted';
                editAllowed = false;
                break;
            case "not_approved_review_open":
                btn = 'open';
                extra = 'all_approval_rejected'
                break;
            case "not_approved_review_accept":
                btn = 'accept';
                extra = 'all_approval_prev_rejected'
                break;
            case "waiting_approval":
                btn = 'hidden';
                extra = 'waiting_approval';
                editAllowed = false;
                break;
            case "open":
                btn = 'open'
                break;
            case "review_accepted":
                btn = 'accept'
                break;
            case "mix":
                btn = 'hidden';
                extra = 'mixed_states';
                editAllowed = false; // Just to be sure...
                break;
            default:
                throw new Error(`Unknown state ${allState}`)
        }
        return {btn, extra, editAllowed, reviewedByOther, cat};
    }

    private static getReviewState(part: MatPartReviewRowState): ReviewState {
        if (part.review_choice === ReviewChoice.REJECT) {
            console.warn('Review state out of specification', {partRowId: part.id})
        }
        if (part.approval_applied) {
            // If the part is approved, it could be open for review or not
            if (part.feedback_choice === ReviewChoice.ACCEPT) {
                return 'approved';
            } else {
                // Not accepted, means it's still open for review
                if (part.review_choice === ReviewChoice.ACCEPT) {
                    return 'not_approved_review_accept';
                }
                return 'not_approved_review_open';
            }
        }
        if (part.approval !== null) {
            // The part is send for approval
            return 'waiting_approval';
        }
        if (part.review_choice === ReviewChoice.ACCEPT) {
            return 'review_accepted';
        }
        return 'open'
    }

    static calcSingleState(part: MatPartReviewRowState): SingleReviewState {
        let btn: SingleReviewState['btn'];
        let extra: SingleReviewState['extra'] = '';
        let leftCat: CombinedReviewState['cat'] = 'input';
        let rightCat: CombinedReviewState['cat'] = 'review|ai';
        let editAllowed = true;
        let reviewedByOther = part.review_user_id !== null && !part.review_mine;

        const state = CategorizationReviewStore.getReviewState(part);
        switch (state) {
            case "approved":
                btn = 'hidden';
                extra = 'approved_accepted';
                editAllowed = false;
                break;
            case "not_approved_review_open":
                btn = 'open';
                extra = 'approval_rejected';
                break;
            case "not_approved_review_accept":
                btn = 'accepted';
                extra = 'approval_prev_rejected';
                break;
            case "waiting_approval":
                btn = 'hidden';
                extra = 'waiting_approval';
                editAllowed = false;
                break;
            case "open":
                btn = 'open';
                break;
            case "review_accepted":
                btn = 'accepted';
                break;
        }
        return {btn, extra, editAllowed, reviewedByOther, leftCat, rightCat}
    }

    clickAcceptReject(part: MatPartReviewRowState, review_choice: ReviewChoice) {
        // Update the view
        part.review_choice = review_choice;
        part.review_mine = true;

        // TODO: A bit inefficient, but it works for now
        const siblings = part.parent_supplier_row.parts || [];
        part.parent_supplier_row.combined_state = calcCombinedState(siblings, this.bagStore.taxonomy_size);

        // Update the API
        const key = part.id
        const data: StorePartReviewPipe = {
            data: {
                review_choice,
            },
            partId: part.id,
        }
        this.accRejPipe.process(key, data);
    }

    clickRecatPart(part: MatPartReviewRowState, category_choice: Categories) {
        const review_choice = ReviewChoice.ACCEPT;
        // Update the view
        part.review_choice = review_choice;
        part.review_mine = true;
        setOnObj(part, 'p_review_cat', category_choice);

        // TODO: A bit inefficient, but it works for now
        const siblings = part.parent_supplier_row.parts || [];
        part.parent_supplier_row.combined_state = calcCombinedState(siblings, this.bagStore.taxonomy_size);

        // Update the API
        const key = part.id
        const data: StorePartReviewPipe = {
            data: {
                review_choice,
            },
            partId: part.id,
        }
        setOnObj(data.data as any, 'p_review_cat', category_choice);
        this.accRejPipe.process(key, data);
    }

    clickAcceptRejectParts(parts: MatPartReviewRowState[], review_choice: ReviewChoice): void {
        // Update the view
        parts.forEach(p => {
            p.review_choice = review_choice;
            p.review_mine = true;
        });
        // Update the API
        const key = Number(parts[0].id)
        const data: StorePartReviewManySerializer = {
            parts: parts.map(p => p.id),
            review_choice: review_choice,
        }
        this.accRejMultiPipe.process(key, data);
    }

    clickReCatParts(parts: MatPartReviewRowState[], category_choice: Categories) {
        console.assert(parts.length > 0)

        const review_choice = ReviewChoice.ACCEPT;
        // Update the view
        parts.forEach(p => {
            p.review_choice = review_choice;
            p.review_mine = true;
            setOnObj(p, 'p_review_cat', category_choice);
        });

        // TODO: A bit inefficient, but it works for now
        const parent = parts[0].parent_supplier_row;
        const siblings = parent.parts || [];
        parent.combined_state = calcCombinedState(siblings, this.bagStore.taxonomy_size);

        const key = Number(parts[0].id);
        const data: StorePartReviewManySerializer = {
            parts: parts.map(p => p.id),
            review_choice: review_choice,
        }
        setOnObj(data as any, 'p_review_cat', category_choice);
        this.accRejMultiPipe.process(key, data)
    }

    private approvalSubscription?: Subscription;

    sendForApproval() {
        if (!this.bagStore.bag) return
        const bagId = this.bagStore.bag.id
        this.isBusySendForApproval = true;
        this.isSendForApprovalError = '';

        const approvalNotes = this.approvalNotes;
        this.approvalSubscription = from(this.api.createCategorizationApprovalRequestForAll(approvalNotes, bagId)).pipe(mergeMap(() =>
            forkJoin([
                // TODO re-trigger download of results
                // from(this.api.getMTaxonomy(taxonomyId)).pipe(
                //     tap(r => runInAction(() => {
                //         this.notification_type = 'success';
                //         this._setTaxonomy(r.data);
                //         this.isSendForApprovalDialog = false;
                //     }))
                // ),
                from(this.approvalStore.fetchAll())
            ])
        )).subscribe({
            next: () => runInAction(() => {
                this.isBusySendForApproval = false;
                this.isSendForApprovalDialog = false;
                this.isSendForApprovalError = false;

                this.approvalNotes = ''; // If successfully applied removed empty the notes field

                // Reload the page when the approval is submitted
                this.categorizationStore.data.reloadView();
            }),
            error: err => runInAction(() => {
                this.isBusySendForApproval = false;
                const bagError: string[] | undefined = err.response?.data['databag'];
                if (bagError && bagError.includes('no_parts_in_scope')) {
                    this.isSendForApprovalError = 'No parts have been reviewed ...'
                } else {
                    this.isSendForApprovalError = true;
                }
            })
        })
    }

    setSendForApprovalDialog(open: boolean) {
        this.isSendForApprovalDialog = open;
        this.isSendForApprovalError = false;
    }

    setApprovalNotes(s: string) {
        this.approvalNotes = s;
    }
}
