import './SingleBarChart.scss';
import React, {useEffect, useMemo, useRef} from "react";
import * as d3 from "d3";
import {single_bar_chart} from "./SingleBarChartBuilder";
import {Grid, Typography} from "@mui/material";

type Props = {
    data: single_bar_chart.Data,
    className?: string,
    options?: Partial<single_bar_chart.Options>
};
export const SingleBarChart: React.FC<Props> = ({data, className, options}) => {
    const svgRef = useRef<SVGSVGElement>(null)

    const dataMax = Math.max(1, ...data.values.map(v => v.value))
    const o = useMemo<single_bar_chart.Options>(() => ({
            // All default values
            width: 800,
            height: 50,
            margin: {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
            },
            dataMax: dataMax,
            labelHover: false,
            hideValueLabels: false,
            barPadding: 0,
            alwaysVisibleLabels: false,
            ...options,
        }
    ), [options, dataMax])

    useEffect(() => {
        if (!data || !svgRef.current) {
            return;
        }

        const svg = d3.select(svgRef.current as SVGElement);
        svg.html(''); // clear

        const root = svg
            .append("g")
            .attr("transform", `translate(${o.margin.left}, ${o.margin.top})`)

        const graphWidth = o.width - o.margin.left - o.margin.right
        const graphHeight = o.height - o.margin.top - o.margin.bottom

        // // DEBUG: show margins
        // svg.append('rect')
        //     .attr('x', o.margin.left)
        //     .attr('y', o.margin.top)
        //     .attr('width', graphWidth)
        //     .attr('height', graphHeight)

        new single_bar_chart.Builder(root, data, graphWidth, graphHeight, o)
    }, [data, o])

    return <div className={"single-bar-chart" + (className ? (' ' + className) : '') + (!o.alwaysVisibleLabels ? ' hover-labels' : ' visible-labels')}>
        <div className="left-overflow-area">
            <Grid className="h-100" container alignItems="center" justifyContent="flex-end">
                <Grid item>
                    <Typography variant="caption" component="p">
                        {data.mainLabel}
                    </Typography>
                </Grid>
            </Grid>
        </div>

        <svg ref={svgRef} viewBox={`0 0 ${o.width} ${o.height}`}/>
    </div>;
};
