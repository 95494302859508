import {TransitionProps} from "@mui/material/transitions/transition";
import {ParentSupplierReviewRowState} from "./SupplierNormalization.classes";

export function getCollapseAnimationTiming(elements: undefined | any[] | null): TransitionProps['timeout'] | number | 'auto' {
    if (elements) {
        if (elements.length > 100) {
            // Performance issue
            return 0
        } else if (elements.length > 30) {
            // Does not fit on the screen anymore
            return {
                enter: 350,
                exit: 100,
            }
        }
    }
    return 'auto'
}

export function getWebSearchQuery(row: ParentSupplierReviewRowState): string {
    const query = row.sp_name;
    // const firstSubRow: SomeMatSupplierChildReviewRowState | undefined =
    //     row.subRows && row.subRows.length > 0 ? row.subRows[0] : undefined;
    // if (firstSubRow) {
    //     // Add a link to the review page that lets the user automatically Google for the supplier name
    //     const query = row.s_name + ' ' + firstSubRow.p_context_1
    // }
    if (query) return `https://www.google.com/search?q=${encodeURIComponent(query)}`;
    return '';
}
