import React from "react";
import {observer} from "mobx-react-lite";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {
    PartReCategorizationControllerStore
} from "../../../../components/categorization/PartReCategorizationControllerStore";
import {CategorizationReviewSubRow} from "./CategorizationReviewSubRow";
import {SomeMatSupplierReviewRowState} from "../../../../services/classes/MatReviewClasses";
import {
    CurrencyAbbreviation,
    findAbbreviationOfGroup,
    NO_ABBREVIATION
} from "../../../../components/currency-component/CurrencyComponent";
import {CurrencyAbbreviationContext} from "../../../../components/currency-component/CurrencyAbbreviationContext";
import {useStores} from "../../../../stores";

type ColSpec = { txt: string; cls: string; align?: 'right' | 'left' };

type Props = {
    data: SomeMatSupplierReviewRowState
    controller: PartReCategorizationControllerStore // TODO: Move to context
};
export const CategorizationReviewSubTable: React.FC<Props> = observer(({data, controller}) => {
    const {p} = useStores();

    // TODO: Move to company specific settings
    const COLUMNS = [
        {cls: 'col-p_name', txt: p.p.p_name_col_name},
        {cls: 'col-p_spend', txt: 'Amount'},
        {cls: 'col-p_description', txt: 'Description'},
        {cls: 'col-p_context_1', txt: 'Product Code'},
        // {cls: 'col-p_context_2', txt: 'Invoice'},
        // {cls: 'col-p_context_l', txt: 'Customer\'s category'},
        // {cls: 'col-p_input_l', txt: ''},
        {cls: 'col-p_suggested_l', txt: 'Suggested category'},
        // {cls: 'col-p_suggested_conf', txt: 'Score'},
        {cls: 'col-p_YN', txt: '', align: 'right'},
    ].filter(v => v) as ColSpec[];

    let abbreviation: CurrencyAbbreviation = NO_ABBREVIATION;
    if (data.parts) {
        const spends = data.parts.map(d => d.p_spend)
        if (spends.length > 0)
            abbreviation = findAbbreviationOfGroup(spends)
    }

    return <Table
        className="sub-table"
        size="small">
        <TableHead>
            <TableRow>
                {COLUMNS.map((c, i) =>
                    <TableCell key={i} className={c.cls} align={c.align}>{c.txt}</TableCell>
                )}
            </TableRow>
        </TableHead>
        <TableBody>
            {data.parts?.map(part =>
                <React.Fragment key={part.id}>
                    <CurrencyAbbreviationContext.Provider value={abbreviation}>
                        <CategorizationReviewSubRow part={part} controller={controller}/>
                    </CurrencyAbbreviationContext.Provider>
                </React.Fragment>
            )}
        </TableBody>
    </Table>
})
