import './ParentSuppliersComparePage.scss';
import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid} from "@mui/material";
import {useStores} from "../../../../stores";
import {ParentSupplierReviewMainTable} from "./ParentSupplierReviewMainTable";
import {ParentSupplierToolbar} from "../parent-suppliers/ParentSupplierToolbar";

export const ParentSuppliersCompare: React.FC = observer(() => {
    const {supplierNormalizationStore} = useStores();
    return <Grid component="section"
                 className="page-item step parent-suppliers-step"
                 container justifyContent="center">
        <Grid item xl={8} lg={10} xs={12}>
            <ParentSupplierToolbar isParent={false}/>

            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    {/*{keyValues?.abbreviation*/}
                    {/*    && <CurrencyAbbreviationContext.Provider value={keyValues.abbreviation}>*/}
                    {/*        <ParentSupplierReviewMainTable/>*/}
                    {/*    </CurrencyAbbreviationContext.Provider>*/}
                    {/*}*/}
                    <ParentSupplierReviewMainTable/>
                </Grid>
            </Grid>

            <Grid container justifyContent="end">
                <Grid item>
                    <Button
                        variant="outlined"
                        className="mt-2"
                        onClick={() => supplierNormalizationStore.goToState('apply')}
                        disabled={supplierNormalizationStore.review.isProcessing}>
                        Next Page
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
})
