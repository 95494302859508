import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../stores";
import {KoiFacade} from "../../../components/koi-facade/KoiFacade";
import './KoiSupplierHierarchy.scss';
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import {Button, Grid} from "@mui/material";
import {DataColumn, DataTable} from "../../../components/data-table/DataTable";
import {environment} from "../../../env";
import {RESULT_DATA} from "./DemoData";
import {Bag} from "../../../services/classes/Bag";
import {useRouteMatch} from "react-router-dom";
import {generateHomePath} from "../../../routing/routing.utils";

const HARDCODED_AI_INTEGRATION = {
    SUPPLIER_HIERARCHY_FINDER_TIMEOUT_MS: 6000,
}
const FAKE_AI_INTEGRATION: boolean =
    // false
    true
;


type KeyValues = {
    total_suppliers: number;
    parent_suppliers: number;
    child_suppliers: number;
    stand_alone: number;
}

const RESULT_COLUMNS: DataColumn[] = [
    {
        dataIndex: 'supplier_name',
        headerText: 'Supplier name',
        ltr: true,
    },
    {
        dataIndex: 'parent_supplier',
        headerText: 'Identified parent supplier',
        ltr: true,
    },
];

function pseudoHardcodeFraction(liveValue, referenceTotal, referenceTarget) {
    // TODO: Remove hardcoded
    const fraction = referenceTarget / referenceTotal;
    const pseudo = liveValue * fraction;
    return Math.round(pseudo);
}

// const PROCESSING_DURATION_HACK_MINUTES = 7;

export const KoiSupplierHierarchy: React.FC = observer(() => {
    // const theme = useTheme();

    const {bagStore} = useStores();
    const routeMatch = useRouteMatch();
    const bag = bagStore.bag as Bag;
    if (!bag) throw new Error();

    const [activeStep, setActiveStep] = React.useState<number>(0);

    const [keyValues, setKeyValues] = React.useState<KeyValues | undefined>(undefined);
    // useEffect(() => {
    //     // TODO: Replace stats and keyValues with bagStore.kpi.common
    //     const sub = forkJoin([
    //         from(jobStore.getJobStats()),
    //         // from(jobStore.getJobKoiCumSuppliersL1Summary()),
    //         // from(jobStore.getJobKoiCumSuppliers(1)),
    //     ])
    //         .pipe(map(([
    //                        stats,
    //                        // l1Resp,
    //                        // topResp
    //                    ]) => ({
    //             // total_suppliers: totalSuppliers,
    //             total_suppliers: stats.data.n_suppliers,
    //             parent_suppliers: pseudoHardcodeFraction(stats.data.n_suppliers, 127, 8),
    //             child_suppliers: pseudoHardcodeFraction(stats.data.n_suppliers, 127, 26),
    //             stand_alone: pseudoHardcodeFraction(stats.data.n_suppliers, 127, 101),
    //         })))
    //         .subscribe(keyValues => {
    //             setKeyValues(keyValues);
    //         });
    //     return () => sub.unsubscribe();
    // }, [jobStore])

    useEffect(() => {
        if (activeStep === 1) {
            setTimeout(() => {
                setActiveStep(2);
            }, HARDCODED_AI_INTEGRATION.SUPPLIER_HIERARCHY_FINDER_TIMEOUT_MS);
        }
    }, [activeStep]);

    const step0 = <div className="page-item step-0">

        <div style={{minHeight: '13em'}}>
            <Grid container justifyContent="space-evenly">
                <Grid item xs={5}
                      container justifyContent="space-around" style={{alignContent: 'space-around'}}
                      alignItems="center">
                    <Grid className="key-value-container text-center font-bigger"
                          item xs={10}
                          container spacing={4}>
                        {/* Row 1 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-between"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.total_suppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Parent
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `?`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Child
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `?`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Standalone
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `?`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>

        <Grid container justifyContent="space-evenly">
            <Grid item xs={3} className="text-center">
                <Button variant="contained" color="primary" onClick={() => setActiveStep(1)}>
                    Find supplier hierarchy
                </Button>
            </Grid>
        </Grid>
    </div>;

    const step1 = <div className="page-item step-1">
        {/*/!* Header *!/*/}
        {/*<Grid container justifyContent="space-evenly">*/}
        {/*    <Grid item xs={5}>*/}
        {/*        <Typography variant="h6" align="center">Determining the parent and child suppliers ...</Typography>*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={4}>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}

        <Grid container justifyContent="space-evenly">
            <Grid item xs={4}
                  container
                  justifyContent="space-around" style={{alignContent: 'space-around'}} alignItems="center">
                <Grid item
                      container direction="column"
                      justifyContent="space-around" style={{alignContent: 'space-around'}} alignItems="center"
                      className="text-center">
                    <Grid item className="font-bigger">
                        <Typography variant="caption">
                            Estimated completion time:
                            {/*<br/>suppliers*/}
                        </Typography>
                        <Typography variant="h5" className="font-weight-bold key-value">
                            {/*{showEstimation(PROCESSING_DURATION_HACK_MINUTES)}*/}
                            in a few seconds
                        </Typography>
                    </Grid>
                    <Grid item>
                        {/*Hidden button*/}
                        <div style={{width: '50px', height: '50px'}} onClick={() => setActiveStep(2)}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>;

    const step2 = <div className="page-item step-2">
        <div style={{minHeight: '11em'}}>
            <Grid container justifyContent="space-evenly">
                <Grid item xs={7}
                      container justifyContent="space-around" style={{alignContent: 'space-around'}}
                      alignItems="center">
                    <Grid className="key-value-container text-center font-bigger"
                          item xs={11}
                          container spacing={4}>
                        {/* Row 1 */}
                        <Grid item
                              container
                              style={{justifyContent: "space-between"}}>
                            <Grid item>
                                <Typography variant="caption">
                                    Total
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.total_suppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Parent
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.parent_suppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Child
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.child_suppliers}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    Standalone
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? `${keyValues.stand_alone}`
                                        : <>&nbsp;</>}
                                </Typography>
                            </Grid>

                            <Grid item>
                                <a className="button" href={environment.demoFiles.supplierHierarchy.url}>
                                    <Button variant="contained" color="primary" style={{margin: '2em'}}>
                                        Download results
                                    </Button>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>

        <Grid container justifyContent="space-evenly">
            {/*<Grid item xs={8}>*/}
            {/*    <Typography variant="h6">Result</Typography>*/}
            {/*</Grid>*/}
            <Grid item xs={7} style={{minHeight: '10em'}}>
                <DataTable
                    className="supplier-hierarchy-table alt-rows"
                    tableColumns={RESULT_COLUMNS}
                    data={RESULT_DATA.map(([supplier_name, parent_supplier]) =>
                        ({supplier_name, parent_supplier,})
                    )}
                    emptyCaption="Loading"
                    pageSize={12}
                />
            </Grid>
        </Grid>


    </div>;


    return <KoiFacade
        title="Supplier Hierarchy"
        intro={<></>}
        back={generateHomePath(routeMatch, {id: bag.id})}
        className="koi-sh-page">

        <Grid container justifyContent="space-evenly">
            <Grid item xs={8}>
                <Stepper orientation={"horizontal"} activeStep={activeStep}>
                    <Step>
                        <StepLabel>Capture all supplier profiles</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Determine the parent and child suppliers</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Download results</StepLabel>
                    </Step>
                </Stepper>
            </Grid>
        </Grid>

        {activeStep === 0 && step0}
        {activeStep === 1 && step1}
        {activeStep === 2 && step2}

    </KoiFacade>
});
