import './ApprovalPage.scss';
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {KoiFacade} from "../../components/koi-facade/KoiFacade";
import {useStores} from "../../stores";
import {MithraTable, MithraTableHeader} from "../../components/data-table/MithraTable";
import {Button, Grid, IconButton, TableCell, TableRow, Typography} from "@mui/material";
import moment from "moment";
import {Check, Clear, Error, HourglassBottom, Pending, PlayCircleOutline, Refresh} from "@mui/icons-material";
import {generatePath, useHistory} from "react-router";
import {ApprovalRouteMatch} from "../../routing/routes";
import {ApprovalStore} from "../../stores/ApprovalStore";
import {ApprovalRequest, ApprovalStatusEnum} from "../../services/classes/AiClasses";
import {ButtonProps} from "@mui/material/Button/Button";
import {useRouteMatch} from "react-router-dom";

const HEADERS: MithraTableHeader[] = [
    {headerText: 'Type'},
    {headerText: 'Requester'},
    {headerText: 'Submitted'},
    {headerText: ''},
]

function getBtnParams(a: ApprovalRequest) {
    let btnText = 'Review';
    let btnIcon: ButtonProps['startIcon'] = undefined;
    let btnVariant: ButtonProps['variant'] = undefined;
    const fromNow = moment.min(moment(a.current_status.timestamp), moment()).fromNow();
    switch (a.current_status.status) {
        case ApprovalStatusEnum.PENDING:
            // Default
            btnIcon = <PlayCircleOutline/>;
            btnVariant = 'outlined';
            break;
        case ApprovalStatusEnum.APPROVED:
            btnText = `Approved ${fromNow}`;
            btnIcon = <Check/>;
            break;
        case ApprovalStatusEnum.REJECTED:
            btnText = `Rejected ${fromNow}`;
            btnIcon = <Clear/>;
            break;
        case ApprovalStatusEnum.BUSY:
            btnText = 'Loading'
            btnIcon = <Pending/>;
            break;
        case ApprovalStatusEnum.HALTED:
            btnText = `Mithra is informed`
            btnIcon = <HourglassBottom/>;
            break;
        case ApprovalStatusEnum.ERROR:
            btnText = `Error`
            btnIcon = <Error/>;
            break;
    }
    return {btnText, btnVariant, btnIcon};
}

export const ApprovalListPage: React.FC = observer(() => {
    const {bagStore, approvalStore} = useStores();
    const history = useHistory();
    const routeMatch = useRouteMatch();

    useEffect(() => {
        // Reload approvals in the background every time

        // noinspection JSIgnoredPromiseFromCall
        approvalStore.fetchAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <KoiFacade
        title="Awaiting approvals"
        simpleAction={<IconButton
            size="small"
            aria-label="refresh"
            onClick={() => approvalStore.fetchAll()}
            disabled={approvalStore.allRequestsLoading}
            className="ml-2">
            <Refresh/>
        </IconButton>}
        back={false}
        className="approval-page"
    >
        <Grid container justifyContent="center">
            <Grid item md={8} xs={12}>
                <MithraTable headers={HEADERS}>
                    {approvalStore.allRequests?.map(a => {
                            const created = moment(a.created_timestamp);
                            const linkParams: ApprovalRouteMatch = {approvalId: String(a.id)};
                            const link = generatePath(ApprovalStore.getBaseRoute(a.request_type), linkParams)
                            const {btnText, btnVariant, btnIcon} = getBtnParams(a);
                            const createdStr = created.format('L LT');
                            return <TableRow key={a.id}>
                                <TableCell>
                                    <Typography>{ApprovalStore.showRequestType(a.request_type)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{
                                        a.created_by.first_name
                                        + ' '
                                        + a.created_by.last_name
                                    }</Typography>
                                </TableCell>
                                <TableCell title={createdStr}>
                                    <Typography>{created.fromNow()}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Button className="detail-button"
                                            href={link}
                                            onClick={e => {
                                                e.preventDefault();
                                                history.push(link);
                                            }}
                                            variant={btnVariant}
                                            startIcon={btnIcon}>
                                        {btnText}
                                    </Button>
                                    {a.resulting_bag &&
                                        <Button
                                            onClick={() => bagStore.changeBag(a.resulting_bag?.id, history, routeMatch)}>
                                            See dataset
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>;
                        }
                    )}
                </MithraTable>
            </Grid>
        </Grid>
        {approvalStore.allRequests?.length === 0 &&
            <Grid container justifyContent="center" className="mt-3">
                <Grid item>
                    <Typography variant="subtitle1">There are no approvals</Typography>
                </Grid>
            </Grid>
        }
        {approvalStore.allRequests === undefined && <Grid container justifyContent="center" className="mt-3">
            <Grid item>
                <Button onClick={() => history.go(0)}>No approvals found... click here to try again</Button>
            </Grid>
        </Grid>}

    </KoiFacade>
})
