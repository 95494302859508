import React from "react";
import {useStores} from "../../../stores";
import {generatePath} from "react-router";
import {useRouteMatch} from "react-router-dom";
import {CmpDataBagRouteMatch, routes, TaxonomyRouteMatch} from "../../../routing/routes";
import {lookupHomePath} from "../../../routing/routing.utils";
import {MenuItem, MenuItems} from "../../../components/main/MenuItems";

export const FullMainMenu: React.FC = () => {
    const {p} = useStores();
    const m = useRouteMatch<CmpDataBagRouteMatch | TaxonomyRouteMatch>();
    const hasId1 = m.params.id !== undefined;
    const hasId2 = m.params['id2'] !== undefined;
    const homeLink = lookupHomePath(m.path);

    // const existsHome = homeLink !== null;
    const isHome = m.path === homeLink;

    const menu: MenuItem[] = [
        {
            label: 'Data',
            link: hasId1 ? generatePath(routes.job_manage, m.params) : routes.home,
            active: m.path === routes.home || m.path === routes.job_manage,
            className: '',
        },
        {
            label: 'Merge',
            link: generatePath(hasId1 ? routes.synergy_v2_dashboard : routes.synergy_v2, m.params),
            active: (
                m.path === routes.synergy_v2
                || m.path === routes.synergy_v2_data
                || m.path === routes.synergy_v2_dashboard
                || m.path === routes.synergy_v2_taxonomy_mapper
                || m.path === routes.synergy_v2_taxonomy_review
                || m.path === routes.synergy_v2_taxonomy_builder_id
                || m.path === routes.synergy_v2_taxonomy_viewer
                || m.path === routes.synergy_v2_taxonomy_viewer_id
                || m.path === routes.synergy_v2_review
                || m.path === routes.synergy_v2_review_result
                || m.path === routes.synergy_v2_opportunities
            ),
            className: '',
        },
        {
            label: 'Synergies',
            link: generatePath(
                hasId2 && hasId1
                    ? (
                        routes.job_synergy_v1_2
                    )
                    : hasId1
                        ? routes.job_synergy_v1_1
                        : routes.job_synergy_v1_0,
                m.params
            ),
            active: (
                m.path === routes.job_synergy_v1_0
                || m.path === routes.job_synergy_v1_1
                || m.path === routes.job_synergy_v1_2
                || m.path === routes.job_synergy_v1_dashboard
                || m.path === routes.job_synergy_v1_suppliers
                || m.path === routes.job_synergy_v1_categories
                || m.path === routes.job_synergy_v1_opportunities
            ),
            className: '',
        },
        {
            label: 'Opportunities',
            link: hasId1 ? generatePath(routes.job_opportunities, m.params) : routes.home,
            active: !isHome && m.path === routes.job_opportunities,
            isTrial: p.p.tease_main_menu
                || (p.p.tease_menu_components !== undefined && p.p.tease_menu_components.includes('opportunities')),
            className: 'menu-item-2nd-last',
        },
        {
            label: 'Report',
            link: hasId1 ? generatePath(routes.job_report, m.params) : routes.home,
            active: !isHome && m.path === routes.job_report,
            isTrial: p.p.tease_main_menu
                || (p.p.tease_menu_components !== undefined && p.p.tease_menu_components.includes('report')),
            className: 'menu-item-last',
        },
    ]
    return <MenuItems items={menu}/>
}
