import React from "react";
import {Alert, Button, CircularProgress, Grid, Typography} from "@mui/material";
import {Check, Close} from "@mui/icons-material";

// TODO: Move this to a more generic component and set the ID field properly
export const EditorModalComponent: React.FC<{
    titleId: string
    className?: string
    saveLabel?: string
    onSave: () => void
    canSave: boolean
    isSaving?: boolean,
    title: string
    onClose: () => void
    isError?: boolean | string,
}> = ({titleId, className, saveLabel, onSave, canSave, isSaving, onClose, title, isError, children}) =>
    <Grid container className={"edit-model-component" + (className ? ' ' + className : '')}>
        <Grid item xs={8}
              container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={3}>
            <Grid item>
                <Typography id={titleId} variant="h5">
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                {children}
            </Grid>
        </Grid>
        <Grid item xs={2}/>
        <Grid item xs={2} alignItems="stretch"
              container direction="column" justifyContent="space-between">
            <Grid item>
                <Button
                    fullWidth
                    className="btn-red"
                    startIcon={<Close/>}
                    onClick={onClose}
                    variant="outlined"
                >
                    Cancel
                </Button>
            </Grid>
            {isError && <>
                <Grid container>
                    <Grid item>
                        <Alert severity="error">
                            {typeof isError === 'string'
                                ? isError
                                : 'Error'}
                        </Alert>
                    </Grid>
                </Grid>
            </>}
            <Grid item>
                <Button
                    disabled={!canSave || isSaving}
                    fullWidth
                    startIcon={isSaving ? <CircularProgress size={12}/> : <Check/>}
                    onClick={onSave}
                    variant="outlined"
                    // tabIndex="2"
                >
                    {saveLabel || 'Save'}
                </Button>
            </Grid>
        </Grid>
    </Grid>

