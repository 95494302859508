import React, {CSSProperties} from "react";
import {LinearProgress, TableCell, TableHead, TableRow} from "@mui/material";
import {TableCellProps} from "@mui/material/TableCell/TableCell";

export type ColSpec = {
    txt: string
    cls: string
    align?: 'right' | 'center'
    minWidth?: number
    width?: CSSProperties['width']
};

type Props = {
    columns: ColSpec[]
    enableLoading?: boolean
    isLoading?: boolean
};
export const TableHeadColumns: React.FC<Props> = ({columns, enableLoading, isLoading}) =>
    <TableHead>
        <TableRow>
            {columns.map((c, i) =>
                <TableCell
                    key={i}
                    className={c.cls}
                    align={c.align}
                    style={{width: c.width, minWidth: c.minWidth}}>
                    {c.txt}
                </TableCell>
            )}
        </TableRow>
        {enableLoading && <tr className={'progress-row' + (isLoading ? ' loading' : '')}>
            <td colSpan={columns.length}>
                {isLoading && <LinearProgress variant="indeterminate"/>}
            </td>
        </tr>}
    </TableHead>

type CellProps = { c: ColSpec } & TableCellProps;
export const MithraTableCell: React.FC<CellProps> = ({c, children, ...tableCellProps}) =>
    <TableCell className={c.cls} align={c.align} style={{width: c.width, minWidth: c.minWidth}} {...tableCellProps}>
        {children}
    </TableCell>
