import {makeAutoObservable} from "mobx";
import {environment} from "../env";
import {DashboardCard} from "../pages/dashboard/dashboard-types";
import {Bag} from "../services/classes/Bag";
import moment from "moment";
import {HierarchicalSankeyOptions} from "../components/visualization/sankey/HierarchicalSankeyChart";
import {CurrencyAbbreviation, MILLION_ABBREVIATION} from "../components/currency-component/CurrencyComponent";
import {MatKpiTreeValues} from "../services/ApiTypes";

const P_CONTEXTS = ['1', '2', '3'] as const;
type ContextFieldName = `p_context_${typeof P_CONTEXTS[number]}`;
type ContextColName = `${ContextFieldName}_col_name`;

export type CompanyProfile = {
    [key in ContextColName]?: string;
} & {
    p_name_col_name: string
    p_description_col_name: string | false

    hide_main_menu?: boolean
    main_menu_components?: string[]
    tease_menu_components?: string[]

    show_synergies_main_menu?: boolean
    tease_main_menu?: boolean

    taxonomy_builder_only_n_cats?: boolean

    only_categorization?: boolean

    /**
     * The nth-smallest number that should be visible, in the domain [0, 1]
     * i.e. for graph sizes [0, 10, 20] the 66% visibility means: number 10 should be at least visible (20px high)
     * Higher values lead to smaller diagrams
     */
    sankeyVisibilityFraction?: number

    sankeyMinHeight?: number
    sankeyMaxHeight?: number

    /**
     * Note: This is BROKEN since move to materialized backend
     */
    allowReviewDownloadExcel?: boolean

    hide_direct_results?: boolean

    locale: string
    currency: string

    /**
     * We are not able to link a single taxonomy to multiple bags.
     * For this reason we hardcode the taxonomy in the frontend. CAT-353
     */
    hardcodeTaxonomyId?: number

    hardcodedKPIAbbreviation?: CurrencyAbbreviation

    /**
     * Allow the user to send for approval of a taxonomy which is not filled completely to it's deepest level
     */
    allowIncompleteTaxonomy?: boolean

    /**
     * A specific master bag to be used
     */
    hardcodeMasterBagId?: number
    hardcodedBagId?: number
    hardcodedCategorizationBagId?: number
    hardcodedCombinedBagId?: number
    hardcodedBaseTaxonomyId?: number

    /**
     * A specific master taxonomy to be used to merge datasets
     */
    masterTaxonomyId?: number

    /**
     * Use the modified ATK synergy dashboard
     * @deprecated TODO: Replace this with different deployment configuration
     */
    new_synergy_dashboard?: boolean

    /**
     * view only option for the taxonomy builder (for ATK)
     */
    taxonomyBuilderViewOnly?: boolean

    /**
     * By default, this is disabled
     */
    enableSankey?: boolean

    /**
     * Show the experimental opportunity tracker
     */
    enableOpportunityTracker?: boolean

    disableBuFilter?: boolean

    hideBuInCategorizationTable?: boolean

    fullMenu?: true;

    ppvDemo?: true;

    disableRunningOfModels?: boolean;
    masterCompanyName?: string;
    compareCompanyName?: string;
    showResultPills?: boolean;
}

export const DEFAULT_SANKEY_VISIBILITY_FRACTION = .67;

function getCompanyProfile(): CompanyProfile {
    // TODO: Make this not be included in every production build
    switch (environment.customerName) {
        case "franke":
            return {
                p_name_col_name: 'Mat ID',
                p_description_col_name: 'Mat desc',
                p_context_1_col_name: 'Mat group desc',
                taxonomy_builder_only_n_cats: true,
                sankeyMinHeight: 200, // Not verified
                sankeyMaxHeight: 600, // Not verified, TODO test for: http://localhost:3008/job/151/categories
                locale: 'fr-ch',
                currency: 'CHF',
                hardcodedKPIAbbreviation: MILLION_ABBREVIATION,

                hide_main_menu: false,
                main_menu_components: ['data', 'report'],
            }
        case "demo_dpw":
            return {
                p_name_col_name: 'Name',
                p_description_col_name: 'Description',
                locale: 'en-gb',
                currency: 'GBP',
            }
        case "ds_smith":
            return {
                p_name_col_name: 'Name',
                p_description_col_name: false,
                sankeyMinHeight: 200,
                sankeyMaxHeight: 500,
                sankeyVisibilityFraction: .1,
                locale: 'nl-nl',
                currency: 'EUR',
            }
        case "vion":
            return {
                p_name_col_name: 'Invoice No.',
                p_description_col_name: false,
                p_context_1_col_name: 'Vendor Location',
                p_context_2_col_name: 'Entity',
                p_context_3_col_name: 'GL name',

                // Main menu
                hide_main_menu: false,
                main_menu_components: ['data', 'report'],
                tease_menu_components: [],

                taxonomy_builder_only_n_cats: true,
                sankeyMinHeight: 200, // Not verified
                sankeyMaxHeight: 600, // Not verified
                allowReviewDownloadExcel: !environment.production, // Only allowed locally, not to be delivered to the customer!
                locale: 'nl-nl',
                currency: 'EUR',
                // hardcodedKPIAbbreviation: MILLION_ABBREVIATION,

                disableBuFilter: true,
                hideBuInCategorizationTable: true,
            }
        case "atk":
            return {
                p_name_col_name: 'Name',
                p_description_col_name: false,
                masterTaxonomyId: 400,
                locale: 'en-us',
                currency: 'USD',
                new_synergy_dashboard: true,
                taxonomy_builder_only_n_cats: true,
                taxonomyBuilderViewOnly: true,
                enableOpportunityTracker: true,
            }
        case "demo4":
            return {
                p_name_col_name: 'Name',
                p_description_col_name: 'Description',
                masterTaxonomyId: 400,
                sankeyMinHeight: 250,
                sankeyMaxHeight: 500, // What fits on screen
                locale: 'en-gb',
                currency: 'GBP',
                new_synergy_dashboard: true,
                taxonomy_builder_only_n_cats: false,
                taxonomyBuilderViewOnly: false,
                allowIncompleteTaxonomy: true,
                enableOpportunityTracker: true,

                // Main menu
                fullMenu: true,

                ppvDemo: true,
                hardcodeMasterBagId: 803,
            }
        case "nestle":
            return {
                p_name_col_name: 'PO Number',
                p_description_col_name: 'Description',
                sankeyMinHeight: 250,
                sankeyMaxHeight: 500, // What fits on screen
                locale: 'fr-ch',
                currency: 'CHF',
                hide_main_menu: false,
                hardcodeMasterBagId: 900,
                hardcodedBagId: 921,
                hardcodedCategorizationBagId: 903,
                hardcodedCombinedBagId: 902,
                masterTaxonomyId: 3,
                hardcodedBaseTaxonomyId: 2,
                disableRunningOfModels: true,
                masterCompanyName: 'Nestlé',
                compareCompanyName: 'Nespresso',
                showResultPills: true,
            }
        case "liberty-global":
            return {
                p_name_col_name: 'Name',
                p_description_col_name: 'Description',
                sankeyMinHeight: 250,
                sankeyMaxHeight: 500, // What fits on screen
                locale: 'en-us',
                currency: 'USD',
                hide_main_menu: false,
                hardcodeMasterBagId: 900,
                hardcodedBagId: 901,
                hardcodedCombinedBagId: 902,
                hardcodedCategorizationBagId: 902,
                masterTaxonomyId: 3,
                hardcodedBaseTaxonomyId: 2,
                disableRunningOfModels: true,
                masterCompanyName: 'Master',
                compareCompanyName: 'Other',
            }
        default:
            return {
                p_name_col_name: 'Name',
                p_description_col_name: 'Description',
                sankeyMinHeight: 250,
                sankeyMaxHeight: 500, // What fits on screen
                locale: 'nl-nl',
                currency: 'EUR',
                hide_main_menu: false,
                // enableSankey: true,
                // enableOpportunityTracker: true,
            }
    }
}

export type ColSpec = { txt: string; cls: string; align?: 'right' };
/**
 * TODO: Retrieve this from the BE or compile it to hide it from other customers
 * - We want this to be available from the start to render the right page.
 * - We want to hide it between customers
 */
export default class ProfileStore {
    public p: CompanyProfile = getCompanyProfile()

    constructor() {
        makeAutoObservable(this)

        // const locale = (window.navigator.userLanguage || window.navigator.language) || 'en-GB';
        // moment.locale(locale);
        // moment.locale('en-GB');

        // import 'moment/locale/nl';
        // import 'moment/locale/fa';

        moment.locale(this.p.locale);
        // console.log('moment.locales()', moment.locales())
    }

    hasCard(card: DashboardCard, bag?: Bag): boolean {
        // Vion has it's own dashboard now
        if (environment.customerName === 'vion') return true;

        if (this.p.hide_direct_results) {
            if (this.isDirectBag(bag)) {
                switch (card) {
                    case "improve-cats":
                        console.warn('Direct results are hidden for Franke')
                        return false;
                }
            }
        }
        if (this.p.only_categorization) {
            switch (card) {
                case "explore-cats":
                case "improve-cats":
                case "taxonomy-builder":
                    return true;
                default:
                    return false;
            }
        }
        return true;
    }

    get partContextColNames(): [string, string][] {
        const result: [string, string][] = []
        for (let C of P_CONTEXTS) {
            const field = `p_context_${C}`
            const c: ContextColName = `p_context_${C}_col_name`
            const name = this.p[c];
            if (name === undefined) {
                return result;
            } else {
                result.push([name, field])
            }
        }
        return result;
    }

    getColumnHeader(field: string): string {
        switch (field) {
            case 'p__name':
                return this.p.p_name_col_name
            case 'p__description':
                return this.p.p_name_col_name
            case 'p__mat_group_raw':
                // TODO: p__mat_group_raw should not be used anymore in the FE
                return this.p.p_context_1_col_name || 'Description'
        }
        console.error('Unknown field', field)
        return '?'
    }

    /**
     * @deprecated TODO: Phase this out to the materialized view
     */
    getOldApiBag(bagId: number): number {
        // if (environment.customerName === 'vion' && !environment.production) {
        //     if (bagId === 158) {
        //         console.error('Ugly code reached')
        //         return 156
        //     }
        // }
        if (environment.customerName === 'franke' && environment.environmentName === 'production') {
            if (bagId === 31) {
                console.warn('[HACK] Use bag 18 for Indirect dataset PVV view')
                return 18;
            }
            if (bagId === 32) {
                console.warn('[HACK] Use bag 23 for Direct dataset PVV view')
                return 23;
            }
        }
        if (environment.customerName === 'franke' && environment.environmentName === 'local') {
            if (bagId === 64) {
                console.warn('[HACK] Locally use bag 139 for PVV view')
                return 139;
            }
        }
        if (environment.customerName === 'demo4') {
            return 6;
        }
        return bagId;
    }

    filterLastName(userLastName: string) {
        if (userLastName.toLowerCase() === environment.customerName.toLowerCase()) {
            // Filter last names with the same name as the company
            return ''
        }
        return userLastName;
    }

    /**
     * A Bag is considered direct if it contains the word 'Direct' in the name
     */
    private isDirectBag(bag: Bag | undefined) {
        if (!bag) return false;
        return bag.name.toLowerCase().match(/\bdirect\b/)
    }

    get currencyFormat(): Intl.NumberFormat {
        return new Intl.NumberFormat(this.p.locale, {style: 'currency', currency: this.p.currency});
    }

    get currencySymbol(): string {
        return (0).toLocaleString(this.p.locale, {
            style: 'currency',
            currency: this.p.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).replace(/\d/g, '').trim()
    }

    getCategoryViewConfig(selectedKpi: MatKpiTreeValues, nLinks: number, topLinkValue: number): Partial<HierarchicalSankeyOptions> {
        if (nLinks === 0) {
            return {};
        }
        const options: Partial<HierarchicalSankeyOptions> = {
            minValueForLabel: 0,
        }
        if (environment.customerName === 'vion') {
            // const zoom = 1.8
            // options.width = 1920 / zoom
            // options.height = 880 / zoom
            // options.margin.top = 50
            // options.margin.bottom = 10
            // options.margin.left = 100 + options.width / 6
            // options.margin.right = 100 + options.width / 6
            if (selectedKpi === 'parts' && topLinkValue >= 95255 / 2) {
                options.minValueForLabel = 4300;
            }
            if (selectedKpi === 'spend' && topLinkValue >= 715617252 / 2) {
                options.minValueForLabel = 4300;
            }
        }
        if (environment.customerName === 'franke') {
            options.margin = {
                left: 180,
                right: 80,
                top: 5,
                bottom: 5,
            }
            if (selectedKpi === 'parts') {
                options.minValueForLabel = 800;
            }
            if (selectedKpi === 'spend') {
                options.minValueForLabel = 6000000;
            }
        }

        return options;
    }

    get reviewTableColumnsMain(): ColSpec[] {
        // TODO: align for Franke
        const cols: (ColSpec | undefined)[] = [
            {cls: 'col-dropdown', txt: ''},
            {cls: 'col-s_name', txt: 'Vendor name'},
            {cls: 'col-s_spend', txt: 'Total spend'},
            {cls: 'col-l1s', txt: 'L1(s) of vendor'},
            this.p.hideBuInCategorizationTable ? undefined : {cls: 'col-bus', txt: 'Entity'}, // Removed for vion
            {cls: 'col-s_cats', txt: ''},
            {cls: 'col-s_YN', txt: 'Approve', align: 'right' as const},
        ]
        return cols.filter(c => c) as ColSpec[];
    }
}
