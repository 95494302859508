import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import {useHistory} from "react-router";

export type MenuItem = {
    label: string
    link: string
    active: boolean
    isTrial?: boolean
    className?: string
}

type Props = {
    items: (MenuItem | undefined)[]
}
export const MenuItems: React.FC<Props> = ({items}) => {
    const history = useHistory();
    const _items = items.filter(m => m !== undefined) as MenuItem[];
    return <Grid container className="main-menu-buttons">
        {_items.map(({label, link, active, isTrial, className}, i) =>
            <Grid item key={i} className={className}>
                <Button
                    variant="text"
                    color="inherit"
                    onClick={() => history.push(link)}
                    className={'main-menu-button' + (active ? ' active' : '')}
                    disabled={Boolean(isTrial)}
                >
                    <Typography variant="h5" className="button-text">
                        {label}
                    </Typography>
                </Button>
            </Grid>
        )}
    </Grid>
}
