import './LookerPreviewPage.scss';
import React from "react";

export const LookerPreviewPage: React.FC = () => {
    return <iframe
        title="looker-preview-page"
        className="looker-preview-page"
        src="https://lookerstudio.google.com/embed/reporting/a8ddc821-2b28-4790-98d0-fbc256ec4a69/page/bSiID"
        width="1860px"
        height="820px"
    />
};
