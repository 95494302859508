/* eslint-disable react-hooks/exhaustive-deps */
// const Main: React.FC<WithStyles<typeof styles>> = ({classes}) => {
import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {useHistory, useLocation} from "react-router";
import {JobRouteMatch, MithraHistoryState} from "../../routing/routes";
import {useRouteMatch} from "react-router-dom";
import {useStores} from "../../stores";
import {NotFound} from "../../routing/NotFound";
import {LoadingBarComponent} from "../../components/loading-bar/LoadingBarComponent";
import {MainComponent} from "../../components/main/MainComponent";
import {LoadingSpinnerPie} from "../../components/loading-pie/LoadingSpinnerPie";
import {RouteWrapperProps} from "./RouteWrapperProps";
import {HeaderWrapper} from "./HeaderWrapper";

export const SimpleEnvWrapper: React.FC<RouteWrapperProps> = observer(({children, state}) => {
    const {rootAppStore, bagStore, bagLoadingStore} = useStores();
    const location = useLocation<MithraHistoryState>();
    const match = useRouteMatch<JobRouteMatch>();
    const history = useHistory();
    const isNoData = state.dataType === 'no_data';

    // TODO: See if this can be optimized, probably by the usage of react Context
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => rootAppStore.setApp(state), [])

    // The magic that loads the job
    useEffect(() => {
        if (!isNoData)
            bagLoadingStore.initBag(location, history, match)
    }, [isNoData])

    return <MainComponent state={state} header={<HeaderWrapper state={state}/>}>
        {/*bagLoadingStore.bagNotFound = {'' + bagLoadingStore.bagNotFound}<br/>*/}
        {/*bagLoadingStore.isInSync = {'' + bagLoadingStore.isInSync}<br/>*/}
        {/*bagStore.bag = {'' + bagStore.bag?.id}<br/>*/}
        <LoadingBarComponent/>
        {
            isNoData
                ? (children)
                : (
                    bagLoadingStore.bagNotFound
                        ? <NotFound msg={"Could not find data on the server anymore"}/>
                        : bagStore.bag
                            ? children
                            : <div className="loading-spinner">
                                <LoadingSpinnerPie/>
                            </div>
                )
        }
    </MainComponent>;
});
