import {observer} from "mobx-react-lite";
import {NavigateNext} from "@mui/icons-material";
import {Breadcrumbs, Link, Typography} from "@mui/material";
import React, {MouseEventHandler} from "react";
import {useStores} from "../../../stores";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";


export const ReviewBreadcrumbs = observer(() => {
    const {bagStore, categorizationStore, p} = useStores();

    const onClickBu: MouseEventHandler = e => {
        e.preventDefault();
        categorizationStore.navigateToStart();
    }

    // const buEl = bagStore.bu.selectedBusinessUnit && (
    //     categorizationStore.data.selectedLevel > 0 ?
    //         <Link onClick={onClickBu} href="">
    //             {bagStore.bu.selectedBusinessUnit.bu_name}
    //         </Link>
    //         : <Typography color="textPrimary" className="">
    //             {bagStore.bu.selectedBusinessUnit.bu_name}
    //         </Typography>
    // )
    const buEl = !p.p.disableBuFilter && bagStore.bu.selectedBusinessUnitOption && (
        <Link onClick={onClickBu} href="">
            {bagStore.bu.selectedBusinessUnitOption.bu_name}
        </Link>
    )

    return <Breadcrumbs separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">
        {buEl}
        {categorizationStore.data.selectedCategory.map((category, index) => {
            const level = index + 1;
            const categoryLabel = category === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : category;
            // const isLast = index === array.length - 1;
            // if (isLast) {
            //     return <Typography key={category} color="textPrimary" className="">{categoryLabel}</Typography>
            // }
            const onClick: MouseEventHandler = e => {
                e.preventDefault();
                categorizationStore.data.navigateToLevel(level - 1);
            }
            return <Link onClick={onClick} key={category} href="">{categoryLabel}</Link>
        })}
        {categorizationStore.data.hasRemainingSelectionLevels &&
            <Typography color="textPrimary" className="">Select a
                L{categorizationStore.data.selectedLevel + 1}</Typography>
        }
        {/*<Link color="inherit" href="/" onClick={handleClick}>*/}
        {/*    Material-UI*/}
        {/*</Link>*/}
        {/*<Link color="inherit" href="/getting-started/installation/" onClick={handleClick}>*/}
        {/*    Core*/}
        {/*</Link>*/}
        {/*<Typography color="textPrimary">Breadcrumb</Typography>*/}
    </Breadcrumbs>
})
