import MithraMaterializedApi from "../../../services/MithraMaterializedApi";
import {BagStore} from "../../../stores/BagStore";
import {makeAutoObservable} from "mobx";
import {RequestManager} from "../../../stores/managers/RequestManager";
import {forkJoin, from} from "rxjs";
import {
    MatCategoryConcentrationStatistics,
    MatConcentrationStatistics,
    MatSupplierCategoryConcentrationStatistics
} from "../../../services/classes/MaterializedClasses";
import {AxiosResponse} from "axios";
import {BarDataPoint} from "../../../components/visualization/BarChart";
import {toCurrency} from "../../../components/currency-component/CurrencyComponent";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import ProfileStore from "../../../stores/ProfileStore";
import {ParetoProcessedDataType} from "../../../components/visualization/pereto/AnimatedCombinedParetoChart";
import {SupplierSpecData} from "./viz/SupplierBreakdown";
import {MatSearch, MatSupplierFilter_V2} from "../../../services/classes/MatReviewClasses";
import {catsToDict} from "../../../services/ApiHelpers";

export class SpendConcentrationStore {

    public readonly search = new SearchDelegate(this);

    maxTaxonomySize = 2; // Do not allow to go deeper than L2 initially

    selectedCategory: string[] = [];

    selectedSupplierSpec: SupplierSpecData | undefined = undefined;

    _spendConcentrationSupplierRequestManager = new RequestManager<MatSupplierFilter_V2, AxiosResponse<MatSupplierCategoryConcentrationStatistics[]>>(
        filter => from(this.api.listSpendConcentrationSupplier(filter))
    );
    _spendConcentrationRequestManager = new RequestManager<{ databag: number, taxonomySize: number },
        [
                MatConcentrationStatistics | null,
            AxiosResponse<MatCategoryConcentrationStatistics[]>,
        ]>(
        ({databag, taxonomySize}) => forkJoin([
            from(this.api.listSpendConcentration(databag)),
            from(this.api.listSpendConcentrationCategory(databag, taxonomySize)),
        ] as const)
    )

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)
    }

    init(databag: number, taxonomySize: number) {
        this.maxTaxonomySize = taxonomySize;
        this.selectedCategory = [];
        this.selectedSupplierSpec = undefined;
        this.search.activeSearchString = '';

        this._spendConcentrationRequestManager.request({databag, taxonomySize});
        if (this.supplierRequestFilter) {
            this._spendConcentrationSupplierRequestManager.request(this.supplierRequestFilter);
        } else {
            this._spendConcentrationSupplierRequestManager.cleanup();
        }
    }

    setSelectedCategory(selectedCategory: string[]) {
        console.log('SpendConcentrationStore.setSelectedCategory', {
            old: `${this.selectedCategory}`,
            new: `${selectedCategory}`
        });
        this.selectedCategory = selectedCategory;
        this.search.activeSearchString = '';
        if (this.supplierRequestFilter) {
            this._spendConcentrationSupplierRequestManager.request(this.supplierRequestFilter);
        } else {
            this._spendConcentrationSupplierRequestManager.cleanup();
        }
    }

    /**
     * When something is selected, filter on one level deeper
     */
    get supplierRequestFilter(): MatSupplierFilter_V2 | undefined {
        const filterLevel = this.selectedLevel + (this.canSelectLevelDeeper ? 1 : 0);
        const fixLevels = this.selectedLevel;
        const selectedCats = catsToDict(this.selectedCategory, fixLevels);
        let search: MatSearch | undefined = undefined;
        if (this.search.activeSearchString) {
            search = {
                supplier: this.search.activeSearchString,
            }
        }
        return {
            databag: this.bagStore.bagId,
            // business_unit: this.bagStore.bu.selectedBusinessUnitId,
            business_unit: undefined,
            search,
            filterLevel,
            fixLevels,
            ...selectedCats,
        }
    }

    unsetSelectedCat() {
        this.setSelectedCategory([])
    }

    selectOneCategoryUp() {
        this.selectedCategory = this.selectedCategory.slice(0, -1);
    }

    selectNextCategoryDown(selectedCategory: string) {
        this.setSelectedCategory([...this.selectedCategory, selectedCategory])
    }

    selectCategorySibling(selectedCategory: string) {
        this.setSelectedCategory([...this.selectedCategory.slice(0, -1), selectedCategory])
    }

    navigateToLevel(level: number) {
        this.setSelectedCategory(this.selectedCategory.slice(0, level));
    }

    get canSelectLevelDeeper(): boolean {
        if (this.selectedLevel >= this.maxTaxonomySize) {
            return false
        }
        // noinspection RedundantIfStatementJS
        if (this.selectedLevel >= 1 && !this.selectedCategoryLabel) {
            // Do not allow to go deeper in uncategorized
            return false;
        }
        return true;
    }

    get selectedLevel(): number {
        return this.selectedCategory.length;
    }

    get selectedCategoryLabel(): string | undefined {
        if (this.selectedCategory.length > 0) {
            return this.selectedCategory[this.selectedCategory.length - 1]
        }
        return undefined;
    }

    get selectedCategoryKey(): string | undefined {
        if (this.selectedCategory.length > 0) {
            return this.selectedCategory.join('|')
        }
        return undefined;
    }

    setSelectedSupplier(supplierId: number, label: string, totalSpend: number) {
        this.selectedSupplierSpec = {supplierId, label, totalSpend};
    }

    get groupedData(): BarDataPoint[] | undefined {
        if (!this._spendConcentrationRequestManager.result) return undefined;
        const currencySymbol = this.profileStore.currencySymbol;
        const level = this.selectedLevel + (this.canSelectLevelDeeper ? 1 : 0);
        return this._spendConcentrationRequestManager.result[1].data
            .filter(d => {
                if (d.level !== level) return false;
                // Check if the parent levels match the current selection
                for (let i = 0; i < level - 1; i++) {
                    const category = d[`l${i + 1}`] as string;
                    if (this.selectedCategory[i] !== category) {
                        return false;
                    }
                }
                return true;
            })
            .map<BarDataPoint>(d => {
                const category = d[`l${level}`] as string;
                const categoryLabel = category === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : category;
                const valueLabel = `${Math.round(d.top_n_spend_percentage * 100) + '%'}`
                    + `  |  ${toCurrency(currencySymbol, d.total_spend)}`
                    + `  |  ${d.distinct_suppliers} suppliers`;
                return {value: d.top_n_spend_percentage, valueLabel, category, categoryLabel,};
            })
    }

    get cumSuppliers(): ParetoProcessedDataType | undefined {
        type Supplier = number;

        if (!this._spendConcentrationSupplierRequestManager.result) return undefined;
        console.time('AnimatedCombinedParetoChart.extractProcessedData')

        const supplierStats: MatSupplierCategoryConcentrationStatistics[][] = [];
        const supplierStatsMap = new Map<Supplier, MatSupplierCategoryConcentrationStatistics[]>();

        this._spendConcentrationSupplierRequestManager.result.data
            .forEach(d => {
                // console.assert(d.level === this.selectedLevel + 1, 'assert l2, l3, l4 is empty as we\'re only looking at L1', d.level, this.selectedLevel)
                let val = supplierStatsMap.get(d.supplier_id);
                if (val) {
                    val.push(d);
                } else {
                    val = [d];
                    supplierStatsMap.set(d.supplier_id, val)
                    supplierStats.push(val)
                }
            });
        const allSuppliers = supplierStats.map(allStats => ({allStats}));

        console.timeEnd('AnimatedCombinedParetoChart.extractProcessedData')
        return {allSuppliers};
    }

    get topValue(): number | undefined {
        if (!this._spendConcentrationRequestManager.result) return undefined;
        return this._spendConcentrationRequestManager.result[0]?.top_n_spend_percentage || undefined;
    }

    get otherValue(): number | undefined {
        if (!this._spendConcentrationRequestManager.result) return undefined;
        let topNSpendPercentage = this._spendConcentrationRequestManager.result[0]?.top_n_spend_percentage;
        if (!topNSpendPercentage) return undefined;
        return 1 - topNSpendPercentage;
    }
}

class SearchDelegate {
    searchString = ''
    activeSearchString = ''

    // noinspection JSUnusedLocalSymbols
    constructor(
        private spendConcentrationStore: SpendConcentrationStore,
    ) {
        makeAutoObservable(this)
    }

    setSearch(search: string) {
        this.searchString = search;
    }

    clearSearch() {
        this.setSearch('')
        const changed = this.activeSearchString !== ''
        this.activeSearchString = '';
        if (changed && this.spendConcentrationStore.supplierRequestFilter)
            this.spendConcentrationStore._spendConcentrationSupplierRequestManager.request(
                this.spendConcentrationStore.supplierRequestFilter
            );
    }

    doSearch() {
        this.activeSearchString = this.searchString.toLowerCase();
        if (this.spendConcentrationStore.supplierRequestFilter)
            this.spendConcentrationStore._spendConcentrationSupplierRequestManager.request(
                this.spendConcentrationStore.supplierRequestFilter
            );
    }
}
