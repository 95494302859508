import {Bag, JobInputIndex} from "../services/classes/Bag";
import {environment} from "../env";

export type JobRouteMatch = { id: string };
export type TaxonomyRouteMatch = { id: string, taxonomyId: string };
export type CmpDataBagRouteMatch = { id: string, id2: string };
export type ApprovalRouteMatch = { approvalId: string };
export type MithraHistoryState = undefined | {
    bag: Bag,
    bag2?: Bag,
    selected_job_input: JobInputIndex, // Not used yet
};

export const routes = {
    home: '/',
    login: '/login',

    nestle_dashboard: '/nestle-home',
    nestle_supplier_normalization: '/supplier-normalization',
    nestle_supplier_master_normalization: '/master/supplier-normalization',
    nestle_common_suppliers: '/common-suppliers',
    nestle_common_categories: '/common-categories',
    nestle_common_categories_review: '/common-categories/review',
    nestle_taxonomy_builder: '/taxonomy-builder',
    nestle_taxonomy_builder_id: '/taxonomy-builder/:taxonomyId',
    nestle_categorization_review: '/categorization',
    nestle_categorization_review_result: '/categorization/review',

    job_koi_sc: '/job/:id/spend-concentration',
    job_koi_cfo: '/job/:id/cash-flow-optimization',
    job_koi_ss: '/job/:id/supplier-segmentation',
    job_koi_ppv: '/job/:id/price-variance',
    job_koi_kdf: '/job/:id/categories',
    job_koi_sh: '/job/:id/supplier-hierarchy',

    job_ai: '/job/:id/automatic-classification',

    job_dashboard: '/job/:id/dashboard',
    job_koi_bu: '/job/:id/koi-3',
    job_manage: '/job/:id/manage',

    job_synergy_v1_0: '/synergy-v1',
    job_synergy_v1_1: '/synergy-v1/:id/data',
    job_synergy_v1_2: '/synergy-v1/:id/:id2',
    job_synergy_v1_opportunities: '/synergy-v1/:id/:id2/opportunities',
    job_synergy_v1_dashboard: '/synergy-v1/:id/:id2/dashboard',
    job_synergy_v1_suppliers: '/synergy-v1/:id/:id2/suppliers',
    job_synergy_v1_categories: '/synergy-v1/:id/:id2/categories',

    synergy_v2: '/synergy-v2',
    synergy_v2_data: '/synergy-v2/:id/data',
    synergy_v2_dashboard: '/synergy-v2/:id/dashboard',
    synergy_v2_taxonomy_mapper: '/synergy-v2/:id/mapper',
    synergy_v2_taxonomy_review: '/synergy-v2/:id/mapper/review',
    synergy_v2_taxonomy_builder_id: '/synergy-v2/:id/taxonomy-builder/:taxonomyId',
    synergy_v2_taxonomy_viewer: '/synergy-v2/:id/taxonomy-viewer',
    synergy_v2_taxonomy_viewer_id: '/synergy-v2/:id/taxonomy-viewer/:taxonomyId',
    synergy_v2_review: '/synergy-v2/:id/categorization',
    synergy_v2_review_result: '/synergy-v2/:id/categorization/review',
    synergy_v2_opportunities: '/synergy-v2/:id/opportunity',

    job_opportunities: '/job/:id/opportunity',

    job_ai_cat_welcome: '/job/:id/categorization',
    job_ai_cat_busy: '/job/:id/categorization/wait',
    job_ai_cat_review: '/job/:id/categorization/review',
    job_ai_cat_result: '/job/:id/categorization/result',

    job_taxonomy_builder: '/job/:id/taxonomy-builder',
    job_taxonomy_builder_id: '/job/:id/taxonomy-builder/:taxonomyId',

    job_report: '/job/:id/report',

    approval: '/approval',
    approval_cat_detail: '/approval/category/:approvalId',
    approval_tax_detail: '/approval/taxonomy/:approvalId',

    //////////////////////////
    // Single dataset views //
    //////////////////////////
    single_data_home2: '/new',
    single_data_home: '/cleanup',
    single_data_manage: '/cleanup/:id/data',

    single_cleansing_dashboard: '/cleanup/:id/dashboard',
    single_cleansing_sup_nor: '/cleanup/:id/supplier-normalization',
    single_cleansing_cat_welcome: '/cleanup/:id/categorization',
    single_cleansing_cat_busy: '/cleanup/:id/categorization/wait',
    single_cleansing_cat_review: '/cleanup/:id/categorization/review',
    single_cleansing_cat_result: '/cleanup/:id/categorization/result',
    single_cleansing_taxonomy_builder: '/cleanup/:id/taxonomy-builder',
    single_cleansing_taxonomy_builder_id: '/cleanup/:id/taxonomy-builder/:taxonomyId',

    single_analytics_dashboard: '/analytics/:id/dashboard',
    single_analytics_koi_ss: '/analytics/:id/supplier-segmentation',
    single_analytics_koi_kdf: '/analytics/:id/categories',
    single_analytics_koi_ppv: '/analytics/:id/price-variance',
    single_analytics_koi_sc: '/analytics/:id/spend-concentration',
    // single_analytics_koi_cfo:   '/analytics/:id/cash-flow-optimization',
    // single_analytics_koi_sh:    '/analytics/:id/supplier-hierarchy',

    single_data_opportunities: '/analytics/opportunity',
    single_data_opportunities_id: '/analytics/:id/opportunity',

    single_data_report: '/analytics/:id/report',

    ///////////////////////////
    // Synergy dataset views //
    ///////////////////////////
    synergy_v3: '/synergy',
    synergy_v3_data: '/synergy/:id/data',
    synergy_v3_dashboard: '/synergy/:id/dashboard',
    synergy_v3_suppliers: '/synergy/:id/suppliers',
    synergy_v3_categories: '/synergy/:id/categories',
    synergy_v3_spend: '/synergy/:id/spend',

    synergy_merge_dashboard: '/synergy-merge/:id/dashboard',
    synergy_merge_taxonomy_viewer: '/synergy-merge/:id/taxonomy-viewer',
    synergy_merge_taxonomy_viewer_id: '/synergy-merge/:id/taxonomy-viewer/:taxonomyId',
    synergy_merge_taxonomy_builder: '/synergy-merge/:id/taxonomy-builder', // Not used?
    synergy_merge_taxonomy_builder_id: '/synergy-merge/:id/taxonomy-builder/:taxonomyId',
    synergy_merge_taxonomy_mapper: '/synergy-merge/:id/mapper',
    synergy_merge_taxonomy_mapper_review: '/synergy-merge/:id/mapper/review',
    synergy_merge_review: '/synergy-merge/:id/categorization',
    synergy_merge_review_result: '/synergy-merge/:id/categorization/review',

    synergy_v3_opportunities: '/synergy/opportunity',
    synergy_v3_opportunities_id: '/synergy/:id/opportunity',

    synergy_v3_report: '/synergy/:id/report',

    looker_preview: '/advanced-dashboard',
}

if (environment.customerName === 'nestle') {
    routes.home = '/default-home';
    routes.nestle_dashboard = '/';
}

if (environment.customerName === 'liberty-global') {
    routes.home = '/default-home';
    routes.nestle_dashboard = '/';
}

