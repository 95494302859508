import {ApiService} from "./Http";
import {MithraLoginResponse} from "./ApiTypes";
import axios from "axios";
import {environment} from "../env";
import {GoogleAuthorizationResponse} from "../stores/managers/AuthManager";

export default class AuthApi extends ApiService {

    loginToMithraAsGoogleUser(googleResponse: GoogleAuthorizationResponse) {
        const data = {code: googleResponse.code};
        return axios.post<MithraLoginResponse>(`${environment.authUrl}/login/google/`, data);
    }
}
