import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../constants";
import {toCurrency} from "../../components/currency-component/CurrencyComponent";
import {MatReviewLevelStatisticsTreeSerializer} from "./MaterializedClasses";

export type AiChangeChart = {
    values: ({ dataCls: string; label: string; value: number })[];
    category: string
    label: string
};

export function findUnclassified(root: MatReviewLevelStatisticsTreeSerializer): MatReviewLevelStatisticsTreeSerializer | null {
    const candidate = root.children.find(n => n.label === UNCATEGORIZED_VALUE)
    if (!candidate) {
        console.warn('Could not find statistics for UNCATEGORIZED')
        return null;
    }
    // We reached the uncat leaf
    if (candidate.children.length === 0) return candidate;
    return findUnclassified(candidate);
}

export function convertSingleToChart(node: MatReviewLevelStatisticsTreeSerializer, currencySymbol: string, includeOld: boolean): AiChangeChart {
    const category = node.label;
    const label = category === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : category
    const values = node.values;
    const chartValues: Array<AiChangeChart['values'][number]> = [];
    if (includeOld) {
        chartValues.push({
            dataCls: 'old',
            label: `${toCurrency(currencySymbol, values.pre_spend)} `
                + `| ${values.pre_parts} parts | ${values.pre_suppliers} suppliers`,
            value: Math.max(0, values.pre_spend),
        })
    } else {
        // TODO: Hacky way to show reporting page in Vion
        chartValues.push({
            dataCls: 'old',
            label: `${toCurrency(currencySymbol, values.post_spend)} `
                + `| ${values.post_parts} parts | ${values.post_suppliers} suppliers`,
            value: Math.max(0, values.post_spend),
        })
    }
    chartValues.push({
        dataCls: 'new',
        label: `${toCurrency(currencySymbol, values.post_spend)} `
            + `| ${values.post_parts} parts | ${values.post_suppliers} suppliers`,
        value: Math.max(0, values.post_spend),
    })
    return ({
        category,
        label,
        values: chartValues,
    });
}

export function convertToChart(node: MatReviewLevelStatisticsTreeSerializer[], currencySymbol: string): AiChangeChart[] {
    const result = node.map(n => convertSingleToChart(n, currencySymbol, true));
    result.sort((a, b) => b.values[1].value - a.values[1].value)
    return result
}

export function convertToChartOnlyNew(node: MatReviewLevelStatisticsTreeSerializer[], currencySymbol: string): AiChangeChart[] {
    let result = node.map(n => convertSingleToChart(n, currencySymbol, false));
    // TODO: Hacky way to show reporting page in Vion
    result = result.filter(n => n.values[0].value > 0);
    result.sort((a, b) => b.values[1].value - a.values[1].value)
    return result
}
