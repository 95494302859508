import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import './DashBoard.scss'
import {useStores} from "../../stores";
import {Alert, AlertTitle, Button, Grid, Typography} from "@mui/material";
import {DashBoardCard} from "./DashBoardCard";
import {generatePath, useHistory} from "react-router";
import {routes} from "../../routing/routes";
import {Bag} from "../../services/classes/Bag";
import {CurrencyComponent} from "../../components/currency-component/CurrencyComponent";
import {forkJoin} from "rxjs";
import {ArrowCollapsedIcon} from "../../components/icons/ArrowCollapsedIcon";
import {MoveIcon} from "../../components/icons/MoveIcon";
import {ClassificationHierarchyIcon} from "../../components/icons/ClassificationHierarchyIcon";
import {ClassificationIcon} from "../../components/icons/ClassificationIcon";
import {SupplierSegmentationIcon} from "../../components/icons/SupplierSegmentationIcon";
import {useRouteMatch} from "react-router-dom";
import {environment} from "../../env";
import {VionDashBoard} from "./VionDashboard";

const SUBTITLE_LOADING = ''
const VALUE_LOADING = '-'
// const HARDCODED_PAYMENT_TERMS: number | undefined = undefined;
const OPPORTUNITY_THRESHOLD = 1000;
const OPPORTUNITY_THRESHOLD_PARETO = 80;

function drawOpportunityValue(opp: number | undefined) {
    if (opp === undefined) {
        return VALUE_LOADING
    }
    if (opp === 0) {
        return <CurrencyComponent v={0} nDigits={3}/>
    }
    return <CurrencyComponent v={opp} nDigits={3}/>
}

export const DashBoard: React.FC = observer(() => {
    const {bagStore, categorizationStore} = useStores();
    const history = useHistory();
    const routeMatch = useRouteMatch();
    const bag = bagStore.bag as Bag;

    // Metric from Spend Concentration
    const [topXSpend, setTopXSpend] = React.useState<number | undefined>(undefined);
    // const [topXSpend, setTopXSpend] = [undefined, (a) => {}];
    // Metric from PVV
    const [totalPPVOpportunity, setTotalPPVOpportunity] = React.useState<number | undefined>(undefined);
    // const [paymentTermsOpp, setPaymentTermsOpp] = React.useState<number | undefined>(undefined);

    useEffect(() => {
        setTotalPPVOpportunity(undefined)
        setTopXSpend(undefined)
        // if (HARDCODED_PAYMENT_TERMS === undefined)
        //     setPaymentTermsOpp(undefined)
        // else
        //     setPaymentTermsOpp(HARDCODED_PAYMENT_TERMS)
        const sub = forkJoin([
            // from(jobStore.getTotalPriceVarianceGroups()).pipe(
            //     tap(ppvO => setTotalPPVOpportunity(ppvO)),
            // ),
            // from(jobStore.getJobKoiCumSuppliers(1)).pipe(
            //     tap(r => setTopXSpend(r.data.stats.top_n_spend_percentage * 100)),
            // ),
            // from(jobStore.getCashFlowOpportunity()).pipe(
            //     tap(r => {
            //         if (HARDCODED_PAYMENT_TERMS !== undefined)
            //             setPaymentTermsOpp(r.data.summary.total_opportunity);
            //     }),
            // ),
        ]).subscribe();
        // jobStore.getTotalPriceVarianceGroups().then(setTotalPPVOpportunity);
        //         jobStore.getJobKoiCumSuppliers(1).then(r => setTopXSpend(r.data.stats.top_n_spend_percentage * 100));
        //         jobStore.getJobStats().then(resp => setCommonKpi(resp.data))
        return () => sub.unsubscribe();
    }, [bagStore, bag.id]);

    const newDatasetId = bag.latest_version !== bag.id ? bag.latest_version : undefined;

    const unclassifiedSpend = categorizationStore.data.summaryResultKeyValues?.after.unclassified_spend;

    if (environment.customerName === 'vion') {
        return <VionDashBoard/>;
    }

    return <>
        {/*{bag.latest_version + ''}*/}
        {/*,{bag.id + ''},*/}
        {
            newDatasetId && bagStore.allBags !== undefined && <div className="dashboard-grid-alert">
                <Grid container justifyContent="center">
                    <Grid item xs={4}>
                        <Alert variant="standard" severity="warning" action={
                            <Button
                                color="inherit" size="small"
                                onClick={() => bagStore.changeBag(newDatasetId, history, routeMatch)}>
                                Open the new dataset
                            </Button>
                        }>
                            <AlertTitle>This is an old version</AlertTitle>
                            This dataset is reviewed and all the changes are applied in a newer version of the dataset
                        </Alert>
                    </Grid>
                </Grid>
            </div>
        }
        <Grid container
              className="dashboard dashboard-v2-wrapper"
              justifyContent="space-evenly"
              spacing={0}>
            <DashBoardRow
                title="Categories"
                className="categories-row"
                subtitle={bagStore.kpi.common ? String(bagStore.kpi.common.n_categories) : SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="explore-cats"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Explore Categories"
                    link={generatePath(routes.job_koi_kdf, {id: bag.id})}
                />
                <DashBoardCard
                    cardKey="improve-cats"
                    avatar={<ClassificationIcon/>}
                    title="Improve Categorization"
                    value={drawOpportunityValue(unclassifiedSpend)}
                    valueColor={unclassifiedSpend
                        ? (unclassifiedSpend >= OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="uncategorized"
                    link={generatePath(routes.job_ai_cat_welcome, {id: bag.id})}
                    badge="Ai"
                />
                <DashBoardCard
                    cardKey="taxonomy-builder"
                    avatar={<ClassificationHierarchyIcon/>}
                    title="Taxonomy Builder"
                    link={generatePath(routes.job_taxonomy_builder, {id: bag.id})}
                />
            </DashBoardRow>
            <DashBoardRow
                title="Spend"
                className="spend-row"
                subtitle={bagStore.kpi.common ?
                    <CurrencyComponent v={bagStore.kpi.common.total_spend} nDigits={3}/> : SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-ppv"
                    avatar={<MoveIcon/>}
                    title="Analyze Price Variance"
                    value={drawOpportunityValue(totalPPVOpportunity)}
                    valueColor={totalPPVOpportunity ? (totalPPVOpportunity >= OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}
                    subtitle="total price variance"
                    link={generatePath(routes.job_koi_ppv, {id: bag.id})}
                />
                {/*<DashBoardCard*/}
                {/*    avatar={<ContractIcon/>}*/}
                {/*    title="Analyze Payment Terms"*/}
                {/*    value={drawOpportunityValue(paymentTermsOpp)}*/}
                {/*    valueColor={paymentTermsOpp ? (paymentTermsOpp >= OPPORTUNITY_THRESHOLD) ? 'red' : 'green' : 'gray'}*/}
                {/*    link={generatePath(routes.job_koi_cfo, {id: bag.id})}*/}
                {/*    badge="Ex"*/}
                {/*    isTrial={DISABLE_FRANKE_DASHBOARD_BUTTONS}*/}
                {/*/>*/}
            </DashBoardRow>
            <DashBoardRow
                title="Suppliers"
                className="suppliers-row"
                subtitle={bagStore.kpi.common ? String(bagStore.kpi.common.n_suppliers) : SUBTITLE_LOADING}>
                <DashBoardCard
                    cardKey="koi-spend-concentration"
                    avatar={<ArrowCollapsedIcon/>}
                    title="Spend Concentration"
                    value={topXSpend ? `${Math.round(topXSpend)}%` : VALUE_LOADING}
                    valueColor={topXSpend ? (topXSpend < OPPORTUNITY_THRESHOLD_PARETO) ? 'red' : 'green' : 'gray'}
                    subtitle="of total spend with top 20% suppliers"
                    link={generatePath(routes.job_koi_sc, {id: bag.id})}
                />
                {/*<DashBoardCard*/}
                {/*    avatar={<PersonHierarchyIcon/>}*/}
                {/*    title="Improve Supplier Hierarchy"*/}
                {/*    link={generatePath(routes.job_koi_sh, {id: bag.id})}*/}
                {/*    badge="Ai"*/}
                {/*    isTrial={DISABLE_FRANKE_DASHBOARD_BUTTONS}*/}
                {/*/>*/}
                <DashBoardCard
                    cardKey="koi-spend-segmentation"
                    avatar={<SupplierSegmentationIcon/>}
                    title="Supplier Segmentation"
                    link={generatePath(routes.job_koi_ss, {id: bag.id})}
                />
            </DashBoardRow>
        </Grid>
    </>
});

type RowProps = {
    className: string
    title: string
    subtitle: React.ReactNode
}
const DashBoardRow: React.FC<RowProps> = ({className, title, subtitle, children}) =>
    <Grid item lg={3} xs={4} className={`dashboard-row ${className}`}>
        <Grid container direction="column" alignItems="center">
            <Grid item className="dashboard-row-title">
                <Typography variant="h2" component="h2">
                    {title}
                </Typography>
            </Grid>
            <Grid item className="dashboard-row-subtitle">
                <Typography variant="subtitle2" component="h2">
                    {subtitle}
                </Typography>
            </Grid>
        </Grid>
        <Grid container justifyContent="center">
            <Grid item sm={11} xs={12} className="dashboard-row-content">
                {children}
            </Grid>
        </Grid>
    </Grid>
