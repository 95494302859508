import {RequestManager} from "../../stores/managers/RequestManager";
import {AxiosResponse} from "axios";
import {from} from "rxjs";
import {MatCommonKpiSerializer} from "../../services/ApiTypes";
import MithraMaterializedApi from "../../services/MithraMaterializedApi";
import {BagStore} from "../../stores/BagStore";
import ProfileStore from "../../stores/ProfileStore";
import {makeAutoObservable, reaction} from "mobx";
import {ParentSupplierDelegate} from "./delegates/ParentSupplierDelegate";
import {environment} from "../../env";
import {ParentSupplierReviewDelegate} from "./delegates/ParentSupplierReviewDelegate";
import {ParentSupplierPostReviewStats, ParentSupplierPreReviewStats} from "./pages/SupplierNormalization.classes";

type State = 'start' | 'running' | 'clean_parents' | 'apply' | 'applying' | 'done';

export class SupplierNormalizationStore {
    state: State = environment.isTest ? 'clean_parents' : 'clean_parents';

    databag: number | undefined;
    selectedCategory: string[] = [];
    maxTaxonomySize = 2; // Do not allow to go deeper than L2 initially

    readonly _rootStatRequestManager = new RequestManager<{ databag: number }, AxiosResponse<MatCommonKpiSerializer>>(
        ({databag}) => from(this.api.getCommonKpi(databag))
    );
    readonly _preStatsRequestManager = new RequestManager<{ databag: number }, AxiosResponse<ParentSupplierPreReviewStats>>(
        ({databag}) => from(this.api.getParentSupplierReviewPreStats(databag))
    );
    readonly _postStatsRequestManager = new RequestManager<{ databag: number }, AxiosResponse<ParentSupplierPostReviewStats>>(
        ({databag}) => from(this.api.getParentSupplierReviewPostStats(databag))
    );

    readonly ps = new ParentSupplierDelegate(this.api, this);
    readonly review = new ParentSupplierReviewDelegate(this.api, this);

    // noinspection JSUnusedLocalSymbols
    constructor(
        private api: MithraMaterializedApi,
        private bagStore: BagStore,
        private profileStore: ProfileStore,
    ) {
        makeAutoObservable(this)

        reaction(() => ([this.databag, this.ps._parentSupplierManager.result] as const), ([databag, result]) => {
            console.log('SupplierNormalizationStore.reaction', databag, result?.data.length)
            if (databag && result) {
                // Only when the _parentSupplierManager is done re-trigger the startRowRequest
                this.review.filterDelegate.startRowsRequest();
            }
        })
    }

    init(databagId: number, taxonomySize: number) {
        console.log('SupplierNormalizationStore.init', databagId, taxonomySize)
        this.databag = databagId;
        this.maxTaxonomySize = taxonomySize;
        this.selectedCategory = [];

        this._rootStatRequestManager.request({databag: databagId})
        this._preStatsRequestManager.request({databag: databagId})
        this._postStatsRequestManager.request({databag: databagId})

        // Cleanup old results
        this.review.filterDelegate.reset();
        this.review._parentSupplierRowsRequest.reset(); // Ensures that the subRow request is stopped
        this.ps._parentSupplierManager.cleanup();

        // Request new result, and trigger the reaction in the constructor
        this.ps._parentSupplierManager.request({databag: databagId})
    }

    refreshReview() {
        this.review._parentSupplierRowsRequest.reset(); // Ensures that the subRow request is stopped
        if (!this.databag) return;
        this.ps._parentSupplierManager.request({databag: this.databag})
        this._postStatsRequestManager.request({databag: this.databag})
    }

    goToState(state: State) {
        this.state = state;
    }

    get stepNumber(): number {
        switch (this.state) {
            default:
            case 'start':
            case 'running':
                return 0;
            case 'clean_parents':
                return 1;
            case 'apply':
            case 'applying':
                return 2;
            case 'done':
                return 3;
        }
    }

    get preKeyValues(): ParentSupplierPreReviewStats | undefined {
        return this._preStatsRequestManager.result?.data;
    }

    get postKeyValues(): ParentSupplierPostReviewStats | undefined {
        return this._postStatsRequestManager.result?.data;
    }

    gotoRunModelState() {
        this.state = 'running';
    }

    gotoApplyingState() {
        this.state = 'applying';
    }

    // get activeFilter(): AbsTreeNodeFilter | undefined {
    //     if (this.databag_id === undefined) return undefined;
    //     const level = this.selectedCategory.length
    //     const selectedCats = catsToDict(this.selectedCategory, level);
    //     return {
    //         type: 'children',
    //         databag: this.databag_id,
    //         level: level,
    //         ...selectedCats,
    //     }
    // }
}
