import {makeAutoObservable} from "mobx";

export class CategorizationReviewFilterDelegate {
    searchString = ''
    activeSearchString = ''

    // noinspection JSUnusedLocalSymbols
    constructor(
    ) {
        makeAutoObservable(this)
    }

    setSearch(search: string) {
        this.searchString = search;
    }

    _clearSearch() {
        this.setSearch('')
        const changed = this.activeSearchString !== ''
        this.activeSearchString = '';
        return changed;
    }

    _doSearch() {
        this.activeSearchString = this.searchString.toLowerCase();
    }
}
