import {observer} from "mobx-react-lite";
import {NavigateNext} from "@mui/icons-material";
import {Breadcrumbs, Divider, Link, Menu, MenuItem, Typography} from "@mui/material";
import React, {MouseEventHandler} from "react";
import {useStores} from "../../../stores";
import {UNCATEGORIZED_LABEL, UNCATEGORIZED_VALUE} from "../../../constants";
import {BusinessUnitManager} from "../../../stores/managers/BusinessUnitManager";


export const ReportBreadcrumbs = observer(() => {
    const {bagStore, categorizationStore} = useStores();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSelect = (event: React.MouseEvent, businessUnitId: number) => {
        event.preventDefault();
        categorizationStore.navigateToStart();
        bagStore.bu.setSelectedBusinessUnitId(businessUnitId);
        setAnchorEl(null);
    }
    const buEl = bagStore.bu.selectedBusinessUnitOption && (
        <Link onClick={handleClick as any} href="">
            {bagStore.bu.selectedBusinessUnitOption.bu_name}
        </Link>
    )
    return <>
        <Menu
            id="report-breadcrumbs-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                // 'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={e => handleSelect(e, BusinessUnitManager.ALL_BU.id)}>
                {BusinessUnitManager.ALL_BU.bu_name}
            </MenuItem>
            <Divider/>
            {bagStore.bu.realBusinessUnitOptions?.map(b =>
                <MenuItem key={b.id} onClick={e => handleSelect(e, b.id)}>
                    {b.bu_name}
                </MenuItem>
            )}
            <Divider/>
            <MenuItem onClick={e => handleSelect(e, BusinessUnitManager.NO_BU.id)}>
                {BusinessUnitManager.NO_BU.bu_name}
            </MenuItem>
        </Menu>
        <Breadcrumbs separator={<NavigateNext fontSize="small"/>} aria-label="breadcrumb">
            {buEl}
            {categorizationStore.data.selectedCategory.map((category, index) => {
                const level = index + 1;
                const categoryLabel = category === UNCATEGORIZED_VALUE ? UNCATEGORIZED_LABEL : category;
                // const isLast = index === array.length - 1;
                // if (isLast) {
                //     return <Typography key={category} color="textPrimary" className="">{categoryLabel}</Typography>
                // }
                const onClick: MouseEventHandler = e => {
                    e.preventDefault();
                    categorizationStore.data.navigateToLevel(level - 1);
                }
                return <Link onClick={onClick} key={category} href="">{categoryLabel}</Link>
            })}
            {categorizationStore.data.hasRemainingSelectionLevels &&
                <Typography color="textPrimary" className="">Select a
                    L{categorizationStore.data.selectedLevel + 1}</Typography>
            }
        </Breadcrumbs>
    </>
})
