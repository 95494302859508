import {D3SvgSelection} from "../../../utils/global";
import {TaxonomyEditorOptions} from "./TaxonomyEditorOptions";
import TaxonomyEditorStore, {Category, TaxonomyNodeValueMode} from "../../../stores/TaxonomyEditorStore";
import {m_taxonomy} from "../../../services/classes/TaxonomyClasses";
import {TaxonomyEditorBuilder} from "./TaxonomyEditorBuilder";
import {taxonomy_editor} from "./TaxonomyEditorTypes";
import {reaction} from "mobx";
import * as d3 from "d3";
import {UNCATEGORIZED_LABEL} from "../../../constants";
import {HierarchyNode} from "d3-hierarchy";
import {Writeable} from "../../../utils/ts-utils";
import {sum} from "../../../utils/js-utils";

export const DEBUG_LIMIT_DATA = false

type Node = taxonomy_editor.GraphNode;

/**
 * For controlling the editing operations of the taxonomy
 */
export class TaxonomyEditorController {
    private builder: TaxonomyEditorBuilder

    public root: Node

    constructor(
        svg: D3SvgSelection,
        data: m_taxonomy.FullSerializer,
        options: TaxonomyEditorOptions,
        private taxonomyEditorStore: TaxonomyEditorStore,
    ) {
        this.builder = new TaxonomyEditorBuilder(svg, options, this)

        this.root = this.builder.init(this.preProcessData(data))

        reaction(
            () => taxonomyEditorStore.isEditMode,
            isEditMode => this.builder.setEditMode(isEditMode)
        )
        taxonomyEditorStore.registerController(this)
    }

    public reDrawNewData(data: m_taxonomy.FullSerializer) {
        // Transfer focus
        const oldFocusId = this.taxonomyEditorStore.focus?.data.id
        const oldSelectionIds = this.taxonomyEditorStore.selection.map(node => node.data.id)

        const {root, focus} = this.builder.drawNewHierarchy(this.preProcessData(data), oldFocusId);
        this.root = root
        this.taxonomyEditorStore.setFocus(focus)

        const newSelection = oldSelectionIds
            .map(id => root.find(node => node.data.id === id))
            .filter(n => n !== undefined) as Node[]
        this.taxonomyEditorStore.setSelection(newSelection)
        this.builder.drawSelection()
    }

    private preProcessData(data: taxonomy_editor.InputData): HierarchyNode<taxonomy_editor.NodeData> {
        // TODO: Add sorting of the tree
        const tree = data.tree
        const rootData: taxonomy_editor.NodeData = TaxonomyEditorController.extractNodeData(tree);
        const rootHierarchy: HierarchyNode<taxonomy_editor.NodeData> = d3.hierarchy(rootData)
        TaxonomyEditorController.applyValueMode(rootHierarchy, this.taxonomyEditorStore.valueMode)
        return rootHierarchy
    }

    private static extractNodeData(inNode: m_taxonomy.Tree, depth = 1): taxonomy_editor.NodeData {
        let inChildren = inNode.children;
        if (DEBUG_LIMIT_DATA) {
            if (depth >= 3) {
                inChildren = []
            } else {
                inChildren = inChildren.filter((_, i) => i < 3)
            }
        }
        // Remove uncategorized
        inChildren = inChildren.filter(c => c.label !== UNCATEGORIZED_LABEL)

        const outNode: taxonomy_editor.NodeData = TaxonomyEditorController.extractSingleNodeData(inNode);
        outNode.children.push(...inChildren.map<taxonomy_editor.NodeData>(c =>
            TaxonomyEditorController.extractNodeData(c, depth + 1)
        ))
        return outNode
    }

    private static extractSingleNodeData(inputNode: m_taxonomy.Tree): taxonomy_editor.NodeData {
        let values: taxonomy_editor.Values
        const isLeaf = inputNode.children.length === 0
        if (isLeaf) {
            values = {...inputNode.values}
        } else {
            values = taxonomy_editor.extractIntermediateValues(inputNode.values)
        }
        return {
            id: inputNode.id,
            sources: inputNode.sources,
            dataLabel: inputNode.label,
            showLabel: inputNode.label,
            values,
            viz: {...taxonomy_editor.NO_VIZ_DATA},
            children: []
        };
    }

    private static createNewNode(data: taxonomy_editor.AddNodeData): taxonomy_editor.NodeData {
        return {
            id: data.newId,
            sources: [],
            dataLabel: data.label,
            showLabel: data.label,
            values: taxonomy_editor.NO_VALUES(data.description),
            viz: {...taxonomy_editor.NO_VIZ_DATA},
            children: []
        };
    }

    private static createMergedNode(data: taxonomy_editor.MergeNodeData): taxonomy_editor.NodeData {
        return {
            id: data.destination.newId,
            sources: data.sources.flatMap(n => n.data.sources),
            dataLabel: data.destination.label,
            showLabel: data.destination.label,
            values: taxonomy_editor.mergeValues(
                data.sources.map(n => n.data.values),
                '',
            ),
            viz: {...taxonomy_editor.NO_VIZ_DATA},
            children: []
        };
    }

    get isEditMode() {
        return this.taxonomyEditorStore.isEditMode
    }

    get focusLevel() {
        return this.taxonomyEditorStore.focusLevel
    }

    backgroundClicked() {
        this.taxonomyEditorStore.setSelection([]);
    }

    onClick(d: Node) {
        if (this.taxonomyEditorStore.isEditMode) {
            this.taxonomyEditorStore.toggleSelection(d)
            this.builder.drawSelection()
        } else {
            const oldFocus = this.taxonomyEditorStore.focus
            this.taxonomyEditorStore.setFocusAndClearSelection(d)
            this.builder.drawSelection()
            this.builder.moveFocus(this.root, d, oldFocus)
        }
    }

    onClickNav(n: Node) {
        console.log('onClickNav', n)
        this.triggerFocusChange(n)
    }

    triggerFocusChange(d: Node) {
        const oldFocus = this.taxonomyEditorStore.focus
        this.taxonomyEditorStore.setFocusAndClearSelection(d)
        this.builder.drawSelection()
        this.builder.moveFocus(this.root, d, oldFocus)
    }

    isSelected(d: Node) {
        return this.taxonomyEditorStore.selection.findIndex(s => s.data.id === d.data.id) !== -1
    }

    focusIsNested(d: Node) {
        return this.taxonomyEditorStore.focusLevel >= d.depth + 1
    }

    isNavigateable(d: Node) {
        return TaxonomyEditorController.isFocusClickable(d, this.taxonomyEditorStore.focus)
    }

    static isFocusClickable(node: Node, prevFocus?: Node): boolean {
        return TaxonomyEditorController.changeFocus(node, prevFocus) !== false;
    }

    moveFocusUp() {
        const focus = this.taxonomyEditorStore.focus;
        console.log('Go up one level', focus?.depth)
        if (focus && focus.parent) {
            const newFocus = focus.parent
            this.builder.moveFocus(this.root, newFocus, focus)
            this.taxonomyEditorStore.setFocus(newFocus)
        }
    }

    private static changeFocus(newFocus?: Node, prevFocus?: Node): Node | undefined | false {
        if (!newFocus) {
            // Remove focus
            return undefined;
        }

        // // Option: Do not allow the selection of leaf nodes
        // if (newFocus.height === 0) {
        //     // It's a leaf, do not allow to go to the deepest level
        //     // Do nothing
        //     return false
        // }

        if (prevFocus === newFocus) {
            // // Option1: When the currently selected node is clicked, go up 1 level
            // if (newFocus.parent) {
            //     return newFocus.parent
            // } else {
            //     // We cannot go higher than the root
            //     return false
            // }

            // // Option2: Ignore it
            // return

            // Option3: When the current node is selected, only go up when we are almost at the root
            if (newFocus.depth === 1 && newFocus.parent) {
                // Go to the root
                return newFocus.parent
            } else {
                // Do not update it
                return false
            }
        } else {
            // A simple change of focus
            return newFocus
        }
    }

    updateValueMode() {
        TaxonomyEditorController.applyValueMode(this.root, this.taxonomyEditorStore.valueMode)
        this.root = this.builder.reDraw(this.root, this.taxonomyEditorStore.focus)
    }

    addNode(data: taxonomy_editor.AddNodeData) {
        // Build a new node

        let parent: Node, index: number;
        const insert = this.getNewInsertPosition()
        console.log('Adding node', data, 'at', insert)
        if (!insert) return;
        [parent, index] = insert;
        const parentValue = parent.value || 0

        const newNodeData = TaxonomyEditorController.createNewNode(data)

        let newValue: number = -1
        if (!parent.children) {
            parent.children = []
        }
        const siblings = parent.children;

        switch (this.taxonomyEditorStore.valueMode.calc) {
            case 'leaf=1':
                newValue = 1

                const parentIsLeaf = parent.height === 0;
                if (!parentIsLeaf) {
                    parent.ancestors().forEach(node => {
                        const _node = node as Writeable<typeof node>
                        _node.value = (_node.value || 0) + 1
                    })
                }
                // console.log('ancestors updated', newNode.ancestors().map(n => n.value))
                break
            // case 'equal':
            //     // Make the value pool bigger
            //     const nChildren = siblings.length
            //     const childValue = parentValue / nChildren
            //
            //     newValue = childValue;
            //     _parent.value = parentValue + childValue;
            //     break
            case 'avg_dist':
                const nChildren = siblings.length
                console.log('Adding value, nChildren=' + nChildren + ', parent=' + parentValue)
                if (nChildren === 0) {
                    newValue = parentValue
                } else {
                    const removeFraction = 1 / (nChildren + 1)
                    console.log('removeFraction=' + removeFraction)
                    newValue = parentValue * removeFraction
                    siblings.forEach(c => {
                        c.descendants().forEach(node => {
                            const _node = node as Writeable<typeof node>
                            const oldValue = (node.value || 0)
                            _node.value = oldValue * (1 - removeFraction)
                        })
                    })
                }
                // Parent remains untouched
                break
            default:
                throw Error()
        }

        TaxonomyEditorController.insertNewNode(newNodeData, newValue, parent, index);

        this.root = this.builder.reDraw(this.root, this.taxonomyEditorStore.focus)
    }

    private static insertNewNode(data: taxonomy_editor._NodeData, value: number, parent: Node, index: number): Node {
        if (!parent.children) throw Error()
        const newHierarchy = d3.hierarchy(data)

        // The position is missing, but it will be set when the builder re calculated the layout
        const newNode = newHierarchy as Node

        const _newNode = newNode as Writeable<typeof newNode>
        _newNode.depth = parent.depth + 1
        _newNode.height = 0
        _newNode.parent = parent
        _newNode.value = value
        if (index === -1) {
            // Just append
            parent.children.push(newNode)
        } else {
            parent.children.splice(index, 0, newNode)
        }
        return newNode;
    }

    private getNewInsertPosition(): [Node, number] | undefined {
        const focus = this.taxonomyEditorStore.focus;
        if (focus && focus.depth !== 0) {
            // // Option1: Try to insert next to the focus
            // const parent = focus.parent as Node
            // if(!parent.children) {
            //     console.warn('No children')
            //     return
            // }
            // const i = parent.children.indexOf(focus)
            // return [parent, i]

            // Try to insert below to the focus
            return [focus, -1]
        } else {
            // Try to insert at the highest level
            const parent = this.root
            return [parent, -1]
        }
    }

    mergeNodes(data: taxonomy_editor.MergeNodeData) {
        // Option1: Merge by creating a new node and moving everything to that node

        // // Optional: Change the order beforehand
        // const selection: taxonomy_editor.Node[] = r
        // const parentChildren = selection[0].parent?.children as Node[];
        // const i_s = selection.map(s => [
        //     s,
        //     parentChildren.findIndex(c => s.id === c.id)
        // ] as const)
        // i_s.sort(([_, a], [__, b]) => a - b)
        // const orderedSelection = i_s.map(([s,]) => s)

        // Note: destination.value is ignored for now...
        const destination = data.destination;
        const sources = data.sources;
        console.debug('Ignoring destination.value=', destination.value)

        const mainSubject = sources[0]
        const parent = mainSubject.parent as Node
        console.log('Merging into', mainSubject)
        // const otherSubjects = sources.slice(1)
        const parentChildren = parent.children;
        if (!parentChildren) return // should not happen
        const mainSubjectI = parentChildren.indexOf(mainSubject)

        let value = 0
        switch (this.taxonomyEditorStore.valueMode.calc) {
            // Note: for leaf=1, When recalculating the values the value is different from the merged values
            // So when the page is refreshed the visualization will be different
            case 'leaf=1':
            case 'avg_dist':
                value = sum(sources.map(s => s.value || 0))
                break
            // case 'equal':
            //     const oldChildren = parentChildren.length || 0
            //     const newChildren = oldChildren - (sources.length - 1)
            //     const factor = newChildren / oldChildren
            //     console.log('Merging -equal old=' + oldChildren + ' new=' + newChildren, factor)
            //
            //     const siblings = parentChildren.filter(c => !sources.some(s => s.id === c.id))
            //     siblings?.forEach(c => {
            //         c.descendants().forEach(d => {
            //             const oldValue = (d.value || 0)
            //             const newValue = oldValue * factor;
            //             (d as any).value = newValue
            //         })
            //     })
            //     value = (parent.value || 0) * (1 - factor)
            //     // Parent remains untouched
            //     break
            default:
                throw Error()
        }

        console.log('Merging with value=', value, sources.map(n => n.data.id), ' --- ', parent.data.id)

        const newNodeData = TaxonomyEditorController.createMergedNode(data)
        let newValue = sum(sources.map(n => n.value || 0))
        const newNode = TaxonomyEditorController.insertNewNode(newNodeData, newValue, parent, mainSubjectI);
        newNode.children = []

        for (let source of sources) {
            if (source.children) {
                // Move the children of this source to the new node
                newNode.data.children.push(...source.data.children)
                newNode.children.push(...source.children)
                source.children.forEach(c => c.parent = newNode)
                // FIXME: depth and height are not updated for now, seems to cause no problems thus far... (1/2 h)
            }

            const sPc = source.parent?.children
            const sPd = source.parent?.data
            if (!sPc || !sPd) {
                // Should not happen
                continue
            }
            // Remove this source from its own parent
            const sourceI = sPc.indexOf(source)
            console.assert(sourceI >= 0)
            sPc.splice(sourceI, 1)
            sPd.children.splice(sourceI, 1)
        }

        this.root = this.builder.reDraw(this.root, this.taxonomyEditorStore.focus)
    }

    moveNodes(sources: Node[], parentDestinationSpec: Category): Node | undefined {
        const newParent = this.root.find(n => n.data.id === parentDestinationSpec.node_id)
        if (!newParent) {
            console.warn('Cannot move, destination not found',
                `destination=${parentDestinationSpec}`
            )
            return undefined
        }
        if (!newParent.children) {
            newParent.children = []
        }

        for (let source of sources) {
            const _source = source as Writeable<typeof source>
            const oldAncestors = source.ancestors();

            const sPc = source.parent?.children
            const sPd = source.parent?.data
            if (!sPc || !sPd) {
                // Should not happen
                continue
            }
            // Remove this source from its own parent
            const sourceI = sPc.indexOf(source)
            console.assert(sourceI >= 0)
            sPc.splice(sourceI, 1)
            sPd.children.splice(sourceI, 1)

            // Add the source to the new parent
            newParent.children.push(source)
            newParent.data.children.push(source.data)
            source.parent = newParent
            const newAncestors = source.ancestors()

            // FIXME: depth and height are not updated for now, seems to cause no problems thus far...
            // _source.id = parentDestination.id + '///' + source.data.label
            _source.depth = newParent.depth + 1

            // Update the values
            let value = source.value || 0
            switch (this.taxonomyEditorStore.valueMode.calc) {
                case 'leaf=1':
                case 'avg_dist':
                    // Remove the value from the tree
                    oldAncestors.forEach((node, i) => {
                        if (i === 0) return
                        const _node = node as Writeable<typeof node>
                        _node.value = Math.max((_node.value || 0) - value, 0)
                    })

                    // Add the value to the new tree
                    newAncestors.forEach((node, i) => {
                        if (i === 0) return
                        const _node = node as Writeable<typeof node>
                        _node.value = Math.max((_node.value || 0) + value, 0)
                    })
                    break
                // case 'equal':
                //     // throw new Error('Not implemented yet...')
                //     break
                default:
                    throw Error()
            }
        }

        // Update the parent height
        TaxonomyEditorController.updateHeightFromLeaf(newParent)

        this.root = this.builder.reDraw(this.root, this.taxonomyEditorStore.focus)

        return newParent
    }

    deleteNodes(selection: Node[]) {
        for (let source of selection) {
            const oldAncestors = source.ancestors();

            const sPc = source.parent?.children
            const sPd = source.parent?.data
            if (!sPc || !sPd) {
                // Should not happen
                continue
            }
            // Remove this source from its own parent
            const sourceI = sPc.indexOf(source)
            console.assert(sourceI >= 0)
            sPc.splice(sourceI, 1)
            sPd.children.splice(sourceI, 1)

            // Update the values
            let value = source.value || 0
            switch (this.taxonomyEditorStore.valueMode.calc) {
                case 'leaf=1':
                case 'avg_dist':
                    // Remove the value from the tree
                    oldAncestors.forEach((node, i) => {
                        if (i === 0) return
                        const _node = node as Writeable<typeof node>
                        _node.value = Math.max((_node.value || 0) - value, 0)
                    })
                    break
                // case 'equal':
                //     // throw new Error('Not implemented yet...')
                //     break
                default:
                    throw Error()
            }

            // Update the parent height
            TaxonomyEditorController.updateHeightFromLeaf(source.parent)
        }

        this.root = this.builder.reDraw(this.root, this.taxonomyEditorStore.focus)
    }

    updateNodeFields(node: Node, name: string, description: string) {
        node.data.dataLabel = name
        node.data.showLabel = name
        node.data.values.description = description

        this.root = this.builder.reDraw(this.root, this.taxonomyEditorStore.focus)
    }

    public static getLabels(node: Node): string[] {
        return node.ancestors().reverse().map(a => a.data.showLabel)
        // .slice(1)
    }

    exportTree(): m_taxonomy.Tree {
        return TaxonomyEditorController.exportTree(this.root)
    }

    private static exportTree(node: Node): m_taxonomy.Tree {
        return {
            id: node.data.id,
            label: node.data.dataLabel,
            children: (node.children || []).map(c => TaxonomyEditorController.exportTree(c)),
            values: node.data.values,
            sources: node.data.sources,
        }
    }

    private static updateHeightFromLeaf(node: Node | null) {
        if (!node) return
        if (!node.children) return;
        let newHeight: number;
        if (node.children.length === 0) {
            newHeight = 1
        } else {
            newHeight = Math.max(...node.children.map(c => c.height));
        }
        if (newHeight !== node.height) {
            const _node = node as Writeable<typeof node>
            _node.height = newHeight
            TaxonomyEditorController.updateHeightFromLeaf(node.parent)
        }
    }

    private static applyValueMode(
        rootHierarchy: HierarchyNode<taxonomy_editor.NodeData>,
        valueMode: TaxonomyNodeValueMode,
    ) {
        if (valueMode.initCalcFromLeaf) {
            const calc = valueMode.calc
            const key = valueMode.key
            rootHierarchy.sum(
                d => {
                    if (calc === 'leaf=1') {
                        return d.children.length === 0 ? 1 : 0
                    } else if (calc === 'avg_dist') {
                        if (key in d.values) {
                            return d.values[key] || 0
                        } else {
                            console.warn(`Could not find ${key} in values`,
                                '' + Object.keys(d.values))
                            return 0
                        }
                    }
                }
            )
                .sort((a, b) =>
                        b.height - a.height || (
                            a.value && b.value ? b.value - a.value : 0
                        )
                )
        } else {
            throw Error('Not implemented yet...')
        }
    }
}
