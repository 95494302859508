import {ApiService} from "./Http";
import {AxiosResponse} from "axios";
import {
    ApplyTaxonomyMappingRequestData,
    BusinessUnit,
    MatCategoryConcentrationStatistics,
    MatConcentrationStatistics,
    MaterializeRequest,
    MatPartReviewRow,
    MatReviewLevelStatisticsTreeSerializer,
    MatReviewStatisticsSerializer,
    MatSupplierCategoryConcentrationStatistics,
    PartData,
    PpvGroup,
    PpvStatistic,
    ReviewChoice
} from "./classes/MaterializedClasses";
import {PagePromise, setParamsOrEmpty, setParamsOrNull} from "./ApiHelpers";
import {Bag} from "./classes/Bag";
import {environment} from "../env";
import {m_taxonomy} from "./classes/TaxonomyClasses";
import {
    ApprovalRequest,
    ApprovalStatusEnum,
    CategorizationApprovalRequest,
    CategorizationApprovalStats,
    TaxonomyApprovalRequest,
    TaxonomyCategoryResp
} from "./classes/AiClasses";
import {
    GraphResponse,
    HierarchyMultiValueResponse,
    MatCommonKpiSerializer,
    MatKpiTreeData,
    MatKpiTreeValues,
    PageResponse,
    SupplierSegmentationEnum
} from "./ApiTypes";
import {
    AbsTreeNodeFilter,
    DownloadTableRequest,
    MatSupplierFilter,
    MatSupplierFilter_V2,
    MatSupplierFilterType,
    setLevelFilterParams,
    SomeMatSupplierReviewRow,
    StorePartFeedbackManySerializer,
    StorePartFeedbackSerializer,
    StorePartReviewManySerializer,
    StorePartReviewSerializer
} from "./classes/MatReviewClasses";
import {
    ParentSupplierPostReviewStats,
    ParentSupplierPreReviewStats,
    ParentSupplierReviewRowSerializer,
    SuggestedParentSupplierSerializer,
    SupplierReviewRowSerializer,
} from "../pages/supplier-normalization/pages/SupplierNormalization.classes";
import {ParentSupplierQueryFilter} from "../pages/supplier-normalization/delegates/ParentSupplierReviewDelegate";

type Ordering = { field: string, desc: boolean };

export default class MithraMaterializedApi extends ApiService {

    listSupplierReview(f: MatSupplierFilter, page: number, pageSize: number, ordering?: Ordering): PagePromise<SomeMatSupplierReviewRow> {
        console.log('listSupplierReview', f);
        const isBu = f.business_unit !== undefined;
        const bu_ = isBu ? 'bu_' : '';
        const l = `l${f.level}`;

        // Only for very crude demo purposes
        // f.l1 = '';
        // f.l2 = '';
        // f.l3 = '';

        let params = {
            databag: f.databag,
            page_size: pageSize,
            page
        };
        params = setParamsOrNull(params, {
            'business_unit': f.business_unit
        });
        if (ordering) {
            // BE: By default it's on the spend given the filter, other options are: s_total_spend or s_total_parts
            params['ordering'] = (ordering.desc ? '-' : '') + ordering.field;
        }
        if (f.search) {
            if (f.search.supplier) {
                params['search'] = f.search.supplier;
            } else {
                console.warn('Search not supported for', f.search);
            }
        }
        if (f.approval) {
            params['approval'] = f.approval;
        }
        let endpoint;
        switch (f.level) {
            case 0:
                endpoint = isBu ? 'bu' : 'all';
                break;
            case 1:
                endpoint = `${bu_}${l}`;
                params = setParamsOrEmpty(params, {
                    'l1': f.l1
                });
                break;
            case 2:
                endpoint = `${bu_}${l}`;
                params = setParamsOrEmpty(params, {
                    'l1': f.l1, 'l2': f.l2,
                });
                break;
            case 3:
                endpoint = `${bu_}${l}`;
                params = setParamsOrEmpty(params, {
                    'l1': f.l1, 'l2': f.l2, 'l3': f.l3,
                });
                break;
            case 4:
                endpoint = `${bu_}${l}`;
                params = setParamsOrEmpty(params, {
                    'l1': f.l1, 'l2': f.l2, 'l3': f.l3, 'l4': f.l4,
                });
                break;
            case 5:
                endpoint = `${bu_}${l}`;
                params = setParamsOrEmpty(params, {
                    'l1': f.l1, 'l2': f.l2, 'l3': f.l3, 'l4': f.l4, 'l5': f.l5,
                });
                break;
            case 6:
                endpoint = `${bu_}${l}`;
                params = setParamsOrEmpty(params, {
                    'l1': f.l1, 'l2': f.l2, 'l3': f.l3, 'l4': f.l4, 'l5': f.l5, 'l6': f.l6,
                });
                break;
            case 7:
                endpoint = `${bu_}${l}`;
                params = setParamsOrEmpty(params, {
                    'l1': f.l1, 'l2': f.l2, 'l3': f.l3, 'l4': f.l4, 'l5': f.l5, 'l6': f.l6, 'l7': f.l7,
                });
                break;
            case 8:
                endpoint = `${bu_}${l}`;
                params = setParamsOrEmpty(params, {
                    'l1': f.l1, 'l2': f.l2, 'l3': f.l3, 'l4': f.l4, 'l5': f.l5, 'l6': f.l6, 'l7': f.l7, 'l8': f.l8,
                });
                break;
            default:
                throw new Error(`Filter ${JSON.stringify(f)} is not supported!`);
        }
        return this.http.get(`/m_supplier_review/${endpoint}/`, {params})
    }

    listPartsInReview(filterType: MatSupplierFilterType, approval: number | undefined, srId: number[]): Promise<AxiosResponse<MatPartReviewRow[]>> {
        const params = {no_page: true,}
        const lookupField = MithraMaterializedApi.getMatPartReviewLookupField(filterType)
        params[lookupField + '__in'] = srId.join(',');

        if (approval) {
            params['approval'] = approval;
        }

        // TODO[integration] Context classifications should be switched at profile store
        // // For ATK: Retrieve the parts with context
        // return this.http.get('/m_part_review/with_context/', {params})

        return this.http.get('/m_part_review/', {params})
    }

    getServerPing() {
        return this.http.get<boolean>('/ping/');
    }

    static getMatPartReviewLookupField(filterType: MatSupplierFilterType) {
        return filterType === 'supplier'
            ? `mat_supplier_review_row`
            : `mat_supplier_${filterType}_review_row`;
    }

    listPartsInApproval(approval_id: number, page: number, page_size: number): PagePromise<MatPartReviewRow> {
        // TODO[integration] Context classifications should be switched at profile store
        // For ATK: Retrieve the parts with context
        // return this.http.get('/m_part_review/with_context/', {
        //     params: {
        //         approval: approval_id,
        //         page, page_size
        //     }
        // })

        return this.http.get('/m_part_review/', {
            params: {
                approval: approval_id,
                page, page_size
            }
        })
    }

    storePartReview(partReviewId: number, data: StorePartReviewSerializer): Promise<AxiosResponse<unknown>> {
        return this.http.patch(`/m_part_review/${partReviewId}/store_review/`, data)
    }

    storePartReviewMany(data: StorePartReviewManySerializer): Promise<AxiosResponse<unknown>> {
        return this.http.patch(`/m_part_review/store_review_many/`, data)
    }

    storePartFeedback(partFeedbackId: number, data: StorePartFeedbackSerializer): Promise<AxiosResponse<unknown>> {
        return this.http.patch(`/m_part_review/${partFeedbackId}/store_feedback/`, data)
    }

    storePartFeedbackMany(data: StorePartFeedbackManySerializer): Promise<AxiosResponse<unknown>> {
        return this.http.patch(`/m_part_review/store_feedback_many/`, data)
    }

    storePartFeedbackAll(approval: number, feedback_choice: ReviewChoice): Promise<AxiosResponse<unknown>> {
        return this.http.patch(`/m_part_review/store_feedback_all/`, {approval, feedback_choice})
    }

    listReviewStatistics(bagId: number): Promise<AxiosResponse<MatReviewStatisticsSerializer[]>> {
        return this.http.get(`/m_review_stats/`, {params: {databag: bagId}})
    }

    listReviewLevelStatistics(bagId: number, businessUnitId: undefined | null | number): Promise<AxiosResponse<[MatReviewLevelStatisticsTreeSerializer]>> {
        if (businessUnitId && Number.isInteger(businessUnitId) && businessUnitId < 0) {
            throw new Error(`Unexpected business unit id ${businessUnitId}`)
        }
        const params = setParamsOrNull({
            all_business_units: businessUnitId === undefined,
            databag: bagId,
        }, {'business_unit': businessUnitId})
        return this.http.get(`/m_review_l_stats/as_tree/`, {params})
    }

    getBag(id: number): Promise<AxiosResponse<Bag>> {
        return this.http.get<Bag>(`/bag/${id}/`);
    }

    getAllBags(): Promise<Bag[]> {
        return this.http.get<Bag[]>(`/bag/`).then(r => {
            let d = r.data;
            if (environment.jobOrderingNewToOld) {
                d = d.reverse()
            }
            return d;
        });
    }

    // Approval

    createTaxonomyApprovalRequest(taxonomy: number, notes: string): Promise<AxiosResponse<TaxonomyApprovalRequest>> {
        return this.http.post<TaxonomyApprovalRequest>(`/approve/taxonomy/`, {taxonomy, notes})
    }

    createCategorizationApprovalRequestForAll(notes: string, databag: number): Promise<AxiosResponse<CategorizationApprovalRequest>> {
        return this.http.post<CategorizationApprovalRequest>(`/approve/category/create_for_all/`, {
            databag,
            notes
        })
    }

    listApprovalRequests(): Promise<AxiosResponse<ApprovalRequest[]>> {
        return this.http.get<ApprovalRequest[]>('/approve/')
    }

    storeApprovalNotes(approvalId: number, feedback_notes: string): Promise<AxiosResponse<ApprovalRequest[]>> {
        return this.http.patch<ApprovalRequest[]>(`/approve/${approvalId}/`, {feedback_notes})
    }

    overrideApprovalStatus(approvalId: number, status: ApprovalStatusEnum) {
        return this.http.patch<ApprovalRequest[]>(`/approve/${approvalId}/override/`, {status})
    }

    getTaxonomyApprovalRequest(approvalId: number): Promise<AxiosResponse<TaxonomyApprovalRequest>> {
        return this.http.get<TaxonomyApprovalRequest>(`/approve/taxonomy/${approvalId}/`)
    }

    applyTaxonomyApproval(approvalId: number, status: ApprovalStatusEnum, feedback_notes: string): Promise<AxiosResponse<TaxonomyApprovalRequest>> {
        return this.http.post<TaxonomyApprovalRequest>(`/approve/taxonomy/${approvalId}/apply/`, {
            status,
            feedback_notes
        })
    }

    getCategorizationApprovalRequest(approvalId: number): Promise<AxiosResponse<CategorizationApprovalRequest>> {
        return this.http.get<CategorizationApprovalRequest>(`/approve/category/${approvalId}/`)
    }

    getCategorizationApprovalStatsRequest(approvalId: number): Promise<AxiosResponse<CategorizationApprovalStats>> {
        return this.http.get(`/approve/category/${approvalId}/stats/`)
    }

    applyCategorizationApproval(approvalId: number, status: ApprovalStatusEnum): Promise<AxiosResponse<CategorizationApprovalRequest>> {
        // Do we want to add feedback_notes here as well?
        return this.http.post<CategorizationApprovalRequest>(`/approve/category/${approvalId}/apply/`, {status})
    }

    // Taxonomy

    getTaxonomyCategories(taxonomyId: number): Promise<AxiosResponse<TaxonomyCategoryResp>> {
        // Extracted directly from the dataset
        return this.http.get(`/taxonomy/${taxonomyId}/category_list/`)
    }

    listMTaxonomyForBag(bagId: number): Promise<AxiosResponse<m_taxonomy.SimpleSerializer[]>> {
        return this.http.get(`/taxonomy/`, {
            params: {
                databag: bagId,
                hidden: false,
            }
        })
    }

    getMTaxonomy(taxonomyId: number): Promise<AxiosResponse<m_taxonomy.FullSerializer>> {
        return this.http.get(`/taxonomy/${taxonomyId}/`)
    }

    listMTaxonomyHistory(taxonomyId: number): Promise<AxiosResponse<m_taxonomy.SimpleTaxonomyOperationSerializer[]>> {
        return this.http.get(`/taxonomy/${taxonomyId}/history/`)
    }

    gotoMTaxonomyHistory(taxonomyId: number, d: m_taxonomy.GotoHistorySerializer): Promise<AxiosResponse<m_taxonomy.FullSerializer>> {
        return this.http.patch(`/taxonomy/${taxonomyId}/goto_history/`, d)
    }

    createMTaxonomyState(taxonomyId: number, d: m_taxonomy.CreateTaxonomyOperationSerializer): Promise<AxiosResponse<m_taxonomy.FullSerializer>> {
        // pre: operation_number == taxonomy.operation_number + 1
        // Note: This method is allowed to overwrite history
        return this.http.post(`/taxonomy/${taxonomyId}/store_state/`, d)
    }

    // KPI

    getCommonKpi(bagId: number): Promise<AxiosResponse<MatCommonKpiSerializer>> {
        return this.http.get(`/bag_kpi/${bagId}/common_kpi/`);
    }

    getTaxonomyKpiFullDepth(databag: number): Promise<AxiosResponse<HierarchyMultiValueResponse<MatKpiTreeData>>> {
        return this.http.get(`/m_kpi/tree/`, {params: {databag}});
    }

    getTaxonomyKpi(databag: number, taxonomySize: number): Promise<AxiosResponse<HierarchyMultiValueResponse<MatKpiTreeData>>> {
        return this.http.get(`/m_kpi/tree/`, {params: {databag, level__lte: taxonomySize}});
    }

    /**
     * Returns the sankey graph for a single value (spend/parts/etc)
     */
    getTaxonomyGraphKpi(databag: number, value: MatKpiTreeValues): Promise<AxiosResponse<GraphResponse>> {
        // Possibly add level__lte to limit tree size
        return this.http.get(`/m_kpi/tree/as_graph/`, {params: {databag, value}});
    }

    // KOI's

    listSupplierSegmentationResult(bagId: number): Promise<AxiosResponse<Array<{
        // PageResponse<{}>
        id: number
        s_id: string
        p_spend: number
        cum_p_spend_perc: number
        cum_s_n_parts_perc: number
        s_n_parts: number
        s_n_cats: number
        s_seg: SupplierSegmentationEnum
        databag: number
    }>>> {
        return this.http.get(`/m_koi/ss/`, {params: {databag: bagId, no_page: 1}}).then(r => {
            r.data.forEach(r => r.s_seg = SupplierSegmentationEnum[r.s_seg])
            return r;
        })
    }

    listSpendConcentration(bagId: number): Promise<MatConcentrationStatistics | null> {
        return this.http.get<MatConcentrationStatistics[]>(`/m_koi/sc/`, {params: {databag: bagId, no_page: 1}})
            .then(r => {
                r.data.forEach(r => {
                    r.total_spend = Number(r.total_spend)
                    r.top_n_spend = Number(r.top_n_spend)
                })
                return r.data[0] || null;
            })
    }

    listSpendConcentrationCategoryL1(bagId: number): Promise<AxiosResponse<MatCategoryConcentrationStatistics[]>> {
        return this.http.get<MatCategoryConcentrationStatistics[]>(`/m_koi/sc/category/`, {
            params: {
                databag: bagId,
                no_page: 1,
                level: 1,
            }
        }).then(r => {
            r.data.forEach(r => {
                r.total_spend = Number(r.total_spend)
                r.top_n_spend = Number(r.top_n_spend)
            })
            return r;
        })
    }

    listSpendConcentrationCategory(bagId: number, level: number): Promise<AxiosResponse<MatCategoryConcentrationStatistics[]>> {
        return this.http.get<MatCategoryConcentrationStatistics[]>(`/m_koi/sc/category/`, {
            params: {
                databag: bagId,
                no_page: 1,
                level__lte: level,
            }
        }).then(r => {
            r.data.forEach(r => {
                r.total_spend = Number(r.total_spend)
                r.top_n_spend = Number(r.top_n_spend)
            })
            return r;
        })
    }

    listSpendConcentrationSupplierL1(bagId: number): Promise<AxiosResponse<MatSupplierCategoryConcentrationStatistics[]>> {
        // Note: For the first iteration this view is not paginated
        return this.http.get<MatSupplierCategoryConcentrationStatistics[]>(`/m_koi/sc/supplier/`, {
            params: {
                databag: bagId,
                no_page: 1,
                level: 1,
                // level__lte: 1,
            }
        }).then(r => {
            r.data.forEach(r => {
                r.s_c_spend = Number(r.s_c_spend)
                r.s_total_spend = Number(r.s_total_spend)
            })
            return r;
        })
    }

    listSpendConcentrationSupplier(filter: MatSupplierFilter_V2, ordering?: Ordering): Promise<AxiosResponse<MatSupplierCategoryConcentrationStatistics[]>> {
        let params = {
            databag: filter.databag,
            level: filter.filterLevel,
            no_page: 1,
        };
        if (ordering) {
            // BE: By default it's on the spend given the filter, other options are: s_total_spend or s_total_parts
            params['ordering'] = (ordering.desc ? '-' : '') + ordering.field;
        }
        if (filter.search) {
            if (filter.search.supplier) {
                params['search'] = filter.search.supplier;
            } else {
                console.warn('Search not supported for', filter.search);
            }
        }
        let fixLevels = filter.fixLevels === undefined ? filter.filterLevel : filter.fixLevels;
        params = setLevelFilterParams(fixLevels, filter, params);
        // console.log('SUPPLIER_RET:listSpendConcentrationSupplier supplierRequestFilter.params=', params);
        return this.http.get<MatSupplierCategoryConcentrationStatistics[]>(`/m_koi/sc/supplier/`, {params}).then(r => {
            r.data.forEach(r => {
                r.s_c_spend = Number(r.s_c_spend)
                r.s_total_spend = Number(r.s_total_spend)
            })
            return r;
        })
    }

    listPartData(bagId: number, supplierId: number, page: number, page_size: number): PagePromise<PartData> {
        return this.http.get<PageResponse<PartData>>(`/data/part/`, {
            params: {
                databag_id: bagId, // This is not referenced in backend as it's the source data
                supplier_id: supplierId,
                page,
                page_size,
            }
        })
            .then(r => {
                r.data.results.forEach(r => {
                    r.p_spend = Number(r.p_spend)
                })
                return r;
            })
    }

    listBusinessUnitData(bagId: number): Promise<AxiosResponse<BusinessUnit[]>> {
        return this.http.get(`/data/business_unit/`, {
            params: {
                databag_id: bagId,
                no_page: true,
            }
        })
    }

    listMatTaxonomy(src_taxonomy: number): Promise<AxiosResponse<m_taxonomy.MaterializedTaxonomy[]>> {
        return this.http.get<m_taxonomy.MaterializedTaxonomy[]>(`/m_taxonomy/list_full/`, {params: {src_taxonomy}})
    }

    listMatTaxonomyMapping(src_mat_taxonomy: number, dst_mat_taxonomy: number): Promise<AxiosResponse<m_taxonomy.MaterializedCategoryMap[]>> {
        return this.http.get<m_taxonomy.MaterializedCategoryMap[]>(`/m_taxonomy/category_map/`, {
            params: {src_mat_taxonomy, dst_mat_taxonomy}
        })
    }

    createMatTaxonomyMapping(create: m_taxonomy.CreateMaterializedCategoryMap): Promise<AxiosResponse<m_taxonomy.MaterializedCategoryMap[]>> {
        return this.http.post(`/m_taxonomy/category_map/`, create);
    }

    createMatTaxonomyMapping2(create: m_taxonomy.CreateMaterializedCategoryMap2): Promise<AxiosResponse<m_taxonomy.MaterializedCategoryMap[]>> {
        return this.http.post(`/m_taxonomy/category_map/create_raw/`, create);
    }

    deleteMatTaxonomyMapping(id: number): Promise<AxiosResponse<void>> {
        return this.http.delete(`/m_taxonomy/category_map/${id}/`);
    }

    downloadTaxonomyExcel(taxonomyId: number, filename: string) {
        return this.http.post(`/taxonomy/${taxonomyId}/download_as_excel/`, {filename}, {responseType: 'blob'})
    }

    applyTaxonomyMappingAi(data: ApplyTaxonomyMappingRequestData): Promise<AxiosResponse<{ dst_databag: number }>> {
        return this.http.post(`/m_taxonomy/category_map/apply/apply_taxonomy_mapping_ai/`, data)
    }

    materializeReview(data: MaterializeRequest): Promise<AxiosResponse> {
        return this.http.post(`/materialize_manager/create_review_materialization/`, data)
    }

    debugDeleteAiClassification(databag: number): Promise<AxiosResponse> {
        return this.http.post(`/m_taxonomy/category_map/apply/delete_ai_result/`, {databag})
    }

    debugDeleteReviewMaterialization(databag: number): Promise<AxiosResponse> {
        return this.http.post(`/materialize_manager/delete_review_materialization/`, {databag})
    }

    downloadReviewExcel(databag: number, filename: string): Promise<AxiosResponse> {
        return this.http.post(`/m_review/export/download_excel/`, {databag, filename}, {responseType: 'blob'})
    }

    listPpvStatistics(filter: AbsTreeNodeFilter, ordering?: Ordering): Promise<AxiosResponse<PpvStatistic[]>> {
        let params = {
            databag: filter.databag,
            level: filter.type === 'children' ? filter.level + 1 : filter.level,
            no_page: true,
        };
        params = setLevelFilterParams(filter.level, filter, params);
        if (ordering) params['ordering'] = (ordering.desc ? '-' : '') + ordering.field;
        return this.http.get<PpvStatistic[]>(`/m_koi/ppv_statistics/`, {params})
    }

    listPpvGroups(page: number, filter: AbsTreeNodeFilter, ordering?: Ordering): Promise<AxiosResponse<PageResponse<PpvGroup>>> {
        let params = {
            databag: filter.databag,
            level: filter.type === 'children' ? filter.level + 1 : filter.level,
            page,
        };
        params = setLevelFilterParams(filter.level, filter, params);
        if (ordering) params['ordering'] = (ordering.desc ? '-' : '') + ordering.field;
        return this.http.get<PageResponse<PpvGroup>>(`/m_koi/ppv/`, {params})
    }

    downloadPpvTable(data: DownloadTableRequest) {
        return this.http.post(`/m_koi/ppv_download/`, data, {responseType: 'blob'})
    }

    listParentSupplierSuggestions(databag_id: number, search: string): Promise<AxiosResponse<SuggestedParentSupplierSerializer[]>> {
        // Or non paged?: no_page: true
        return this.http.get(`/m_sp_review/suggested_parent_suppliers/`, {
            params: {
                // page,
                no_page: true,
                databag_id,
                search,
            }
        })
    }

    listParentSupplierReviewRows(databag_id: number, page: number, filter: ParentSupplierQueryFilter): Promise<AxiosResponse<PageResponse<ParentSupplierReviewRowSerializer>>> {
        const params = {
            page,
            page_size: 20,
            databag_id,
            search: filter.parent_search || '',
        };
        if (!filter.showStandaloneSuppliers) params['sp_n_suppliers__gt'] = 1;
        return this.http.get(`/m_sp_review/parent_rows/`, {params})
    }

    listSupplierReviewRows(databag_id: number, rowIds: number[]): Promise<AxiosResponse<SupplierReviewRowSerializer[]>> {
        return this.http.get(`/m_sp_review/supplier_rows/`, {
            params: {
                no_page: true,
                databag_id,
                parent_supplier_review_row__in: rowIds.join(','),
            }
        })
    }

    storeSupplierReviewUpdate(
        supplierRowId: number,
        parent_supplier: number | undefined,
        sp_id: number | undefined,
        sp_name: string,
    ): Promise<AxiosResponse<unknown>> {
        const data: any = {sp_name}
        if (sp_id) data['sp_id'] = sp_id
        if (parent_supplier) data['parent_supplier'] = parent_supplier
        return this.http.patch(`/m_sp_review/supplier_rows/${supplierRowId}/store_review/`, data)
    }

    getParentSupplierReviewPreStats(databag: number) {
        return this.http.get<ParentSupplierPreReviewStats>(`/m_sp_review/statistics/${databag}/pre_stats/`)
    }

    getParentSupplierReviewPostStats(databag: number) {
        return this.http.get<ParentSupplierPostReviewStats>(`/m_sp_review/statistics/${databag}/post_stats/`)
    }
}
