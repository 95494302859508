import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Grid, Typography} from "@mui/material";
import {useStores} from "../../../stores";
import {HelpComponent} from "../../../components/help-component/HelpComponent";
import SupplierNormalizationSSHelp from "../SupplierNormalizationSSHelp";
import {ChevronRight} from "@mui/icons-material";
import {useRouteMatch} from "react-router-dom";
import {routes} from "../../../routing/routes";
import {CurrencyComponent} from "../../../components/currency-component/CurrencyComponent";

export const SupplierNormalizationApplyStep: React.FC = observer(() => {
    const {rootAppStore, supplierNormalizationStore, cmpStore} = useStores();
    const env = rootAppStore.app.dashEnv;
    const routeMatch = useRouteMatch();
    const isMaster = env === 'nestle' && routeMatch.path === routes.nestle_supplier_master_normalization;
    const keyValues = supplierNormalizationStore.postKeyValues;
    const numberOfSuppliers = isMaster
        ? cmpStore.masterKpi?.n_suppliers
        : supplierNormalizationStore.postKeyValues?.total_suppliers_count;
    return <section className="page-item step">
        <Grid container justifyContent="space-evenly">
            <Grid item xl={5} lg={8} xs={12}
                  container justifyContent="space-around" style={{alignContent: 'space-around'}}
                  alignItems="center">
                <Grid className="key-value-container text-center font-bigger"
                      item
                      container justifyContent="center">
                    {/* Row 1 */}
                    <Grid item xs={12}
                          container>
                        <Grid item xs={4}>
                            <Typography variant="caption">
                                Reviewed
                                <br/>spend
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {keyValues
                                    ? <CurrencyComponent v={keyValues.total_reviewed_spend}/>
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{display: "inline-grid"}} className="key-value-with-help">
                                <Typography variant="caption">
                                    {isMaster ? <>
                                        Total
                                        <br/>Suppliers
                                    </> : <>
                                        Suppliers
                                        <br/>reviewed
                                    </>}
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {numberOfSuppliers
                                        ? numberOfSuppliers
                                        : <>&nbsp;</>}
                                </Typography>
                            </div>
                            <div style={{display: "inline-grid", marginLeft: "8px"}}>
                                <HelpComponent helpContent={<Typography component="p">
                                    {isMaster
                                        ? 'The number of suppliers in the dataset.'
                                        : 'The number of suppliers that have been shown in the review of the previous step.'
                                    }
                                </Typography>}/>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="caption">
                                Parent
                                <br/>suppliers
                            </Typography>
                            <Typography variant="h5" className="font-weight-bold key-value">
                                {keyValues
                                    ? keyValues.total_parent_suppliers_count
                                    : <>&nbsp;</>}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* Row 2 */}
                    <Grid item xs={8}
                          container>
                        <Grid item xs={6}>
                            <div style={{display: "inline-grid"}} className="key-value-with-help">
                                <Typography variant="caption">
                                    Standalone
                                    <br/>suppliers
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? keyValues.total_standalone_suppliers_count
                                        : <>&nbsp;</>}
                                </Typography>
                            </div>
                            <div style={{display: "inline-grid", marginLeft: "8px"}}>
                                <HelpComponent helpContent={<SupplierNormalizationSSHelp/>}/>
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{display: "inline-grid"}}>
                                <Typography variant="caption">
                                    Standalone
                                    <br/>spend
                                </Typography>
                                <Typography variant="h5" className="font-weight-bold key-value">
                                    {keyValues
                                        ? <CurrencyComponent v={keyValues.total_standalone_suppliers_spend}/>
                                        : <>&nbsp;</>}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <div className="text-center page-item-actions">
            <Button
                variant="outlined"
                color="primary"
                endIcon={<ChevronRight/>}
                onClick={() => supplierNormalizationStore.gotoApplyingState()}>
                Apply to dataset
            </Button>
        </div>
    </section>
})
